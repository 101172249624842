import { useTheme } from "../../contexts/ThemeContext";
import { usePreserve } from "../../contexts/PreserveContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import { routes } from "../../main/routes";

const productTabs = (translate: TranslateFunction) => [
  { name: translate("plugins.products"), route: routes.administration.products() },
  { name: translate("settings.parentProducts"), route: routes.administration.parentProducts() }
];

export const ProductsLayout = () => {
  const { buttonStyle } = useTheme();
  const { safeExecute } = usePreserve();
  const { pathname } = useLocation();
  const { translate } = useLocalization();
  const navigate = useNavigate();

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3>{translate("plugins.manageProducts")}</h3>
      <ul className="nav nav-tabs mb-3">
        {productTabs(translate).map((x) => (
          <li className="nav-item" key={x.name}>
            <button
              className="nav-link cursor-pointer m-0"
              style={pathname === x.route ? buttonStyle : {}}
              onClick={() => safeExecute(() => navigate(x.route))}
            >
              {x.name}
              <span aria-label={translate("common.subview")} />
            </button>
          </li>
        ))}
      </ul>
      <Outlet />
    </>
  );
};
