import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useUser } from "../../../contexts/UserContext";
import { useFetch } from "../../../hooks/useFetch";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { AgreementCreate } from "../models/AgreementCreate";
import { apiRoutes } from "../../../apiRoutes";

export const useAgreement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLocalization();
  const { getUser } = useUser();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();
  const navigate = useNavigate();

  const handleSubmit = async (value: AgreementCreate) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.agreements.createAgreement(),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "POST",
      onSuccess: async () => {
        await getUser();
        navigate("/");
      },
      onFailure: async (response) => {
        const json = await response.json();
        pushNotification({ message: json.message, type: "danger" });
      },
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleSubmit };
};
