import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { Container, Dropdown, Nav, Navbar, Image } from "react-bootstrap";
import { useUser } from "../../contexts/UserContext";
import { ProfileToggleButton } from "../identity/ProfileToggleButton";
import { NotificationsToggler } from "../notifications/NotificationsToggler";
import { NotificationsBox } from "../notifications/NotificationsBox";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useTheme } from "../../contexts/ThemeContext";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

const inheritStyle: React.CSSProperties = { color: "inherit" };
const menuStyle: React.CSSProperties = { width: 420, top: 40, right: -200 };

export const Navigation = () => {
  const [isToggled, setIsToggled] = useState(false);
  const { user } = useAuth0<ExtendedUser>();
  const { logout } = useUser();
  const { theme } = useTheme();
  const { safeExecute } = usePreserve();
  const baseUrl = process.env.PUBLIC_URL;
  const accountName = user?.user_metadata?.selectedAccount?.name;
  const belongsToAppStore = user?.user_metadata?.belongsToAppStoreAccount;
  const route = belongsToAppStore ? `${baseUrl}/administration` : `${baseUrl}/accounts`;
  const { translate } = useLocalization();

  return (
    <Navbar expand="lg">
      <Container>
        {theme.iconUrl && <Image src={theme.iconUrl} width={75} className="me-2" />}
        <h1
          className="m-0 fs-2"
          style={inheritStyle}
          onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/`))}
        >
          {translate("navigation.appStoreManager")}
          <span aria-label={translate("navigation.loggedInAs")} />
          <span className="d-none d-lg-inline">{accountName ? ` - ${accountName}` : ""}</span>
        </h1>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/`))} style={inheritStyle}>
              {translate("navigation.appList")}
            </Nav.Link>
            <Nav.Link
              onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/settings/account`))}
              style={inheritStyle}
            >
              {translate("navigation.settings")}
            </Nav.Link>
            <div className="d-flex">
              <Dropdown className="d-flex align-items-center">
                <Dropdown.Toggle style={inheritStyle} as={ProfileToggleButton}></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => safeExecute(() => (window.location.href = `${baseUrl}/profile`))}>
                    {translate("navigation.profile")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => safeExecute(() => (window.location.href = route))}>
                    {translate("navigation.switchAccount")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => safeExecute(() => logout())}>
                    {translate("account.logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                drop="start"
                autoClose="outside"
                className="d-flex align-items-center ms-2 position-relative"
                onToggle={() => setIsToggled((prev) => !prev)}
              >
                <Dropdown.Toggle style={inheritStyle} as={NotificationsToggler} />
                <Dropdown.Menu className="p-0" style={menuStyle}>
                  {isToggled && <NotificationsBox />}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
