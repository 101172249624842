import { SortType } from "./SortType";

const categoriesParam = "categories";
const productsParam = "products";
const pricingParam = "pricing";
const appStatusParam = "appStatus";
const accountStatusParam = "accountStatus";
const accountNameParam = "accountName";
const accountAliasParam = "accountAlias";
const appNameParam = "appName";
const userParam = "user";
const accountNameSortParam = "accountNameSort";
const accountAliasSortParam = "accountAliasSort";
const createdDateSortParam = "createdDateSort";
const recentActionSortParam = "recentActionSort";

export class AccountsFilter {
  private categories: string[];
  private products: string[];
  private pricing: string[];
  private appStatus: string[];
  private accountStatus: string[];
  private accountName: string;
  private accountAlias: string;
  private appName: string;
  private user: string;
  private accountNameSort?: SortType;
  private accountAliasSort?: SortType;
  private createdDateSort?: SortType;
  private recentActionSort?: SortType;
  private params: URLSearchParams;

  constructor(params: URLSearchParams) {
    this.params = params;
    this.categories = this.params.get(categoriesParam)?.split(",") ?? [];
    this.products = this.params.get(productsParam)?.split(",") ?? [];
    this.pricing = this.params.get(pricingParam)?.split(",") ?? [];
    this.appStatus = this.params.get(appStatusParam)?.split(",") ?? [];
    this.accountStatus = this.params.get(accountStatusParam)?.split(",") ?? [];
    this.accountName = this.params.get(accountNameParam) ?? "";
    this.accountAlias = this.params.get(accountAliasParam) ?? "";
    this.appName = this.params.get(appNameParam) ?? "";
    this.user = this.params.get(userParam) ?? "";
    this.accountNameSort = this.params.get(accountNameSortParam) as SortType;
    this.accountAliasSort = this.params.get(accountAliasSortParam) as SortType;
    this.createdDateSort = this.params.get(createdDateSortParam) as SortType;
    this.recentActionSort = this.params.get(recentActionSortParam) as SortType;
  }

  get Categories(): string[] {
    return this.categories;
  }

  set Categories(value: string[]) {
    this.categories = value;
    this.params.delete(categoriesParam);
    if (this.categories.length) this.params.append(categoriesParam, value.toString());
  }

  public unsetCategory(value: string) {
    this.categories = this.categories.filter((x) => x !== value && x);
    this.params.delete(categoriesParam);
    if (this.categories.length) this.params.append(categoriesParam, this.categories.toString());
  }

  get Products(): string[] {
    return this.products;
  }

  set Products(value: string[]) {
    this.products = value;
    this.params.delete(productsParam);
    if (this.products.length) this.params.append(productsParam, value.toString());
  }

  public unsetProduct(value: string) {
    this.products = this.products.filter((x) => x !== value && x);
    this.params.delete(productsParam);
    if (this.products.length) this.params.append(productsParam, this.products.toString());
  }

  get Pricing(): string[] {
    return this.pricing;
  }

  set Pricing(value: string[]) {
    this.pricing = value;
    this.params.delete(pricingParam);
    if (this.pricing.length) this.params.append(pricingParam, value.toString());
  }

  public unsetPricing(value: string) {
    this.pricing = this.pricing.filter((x) => x !== value);
    this.params.delete(pricingParam);
    if (this.pricing.length) this.params.append(pricingParam, this.pricing.toString());
  }

  get AppStatus(): string[] {
    return this.appStatus;
  }

  set AppStatus(value: string[]) {
    this.appStatus = value;
    this.params.delete(appStatusParam);
    if (this.appStatus.length) this.params.append(appStatusParam, value.toString());
  }

  public unsetAppStatus(value: string) {
    this.appStatus = this.appStatus.filter((x) => x !== value);
    this.params.delete(appStatusParam);
    if (this.appStatus.length) this.params.append(appStatusParam, this.appStatus.toString());
  }

  get AccountStatus(): string[] {
    return this.accountStatus;
  }

  set AccountStatus(value: string[]) {
    this.accountStatus = value;
    this.params.delete(accountStatusParam);
    if (this.accountStatus.length) this.params.append(accountStatusParam, value.toString());
  }

  public unsetAccountStatus(value: string) {
    this.accountStatus = this.accountStatus.filter((x) => x !== value);
    this.params.delete(accountStatusParam);
    if (this.accountStatus.length) this.params.append(accountStatusParam, this.accountStatus.toString());
  }

  get AccountName(): string {
    return this.accountName;
  }

  set AccountName(value: string) {
    this.accountName = value;
    this.params.delete(accountNameParam);
    this.params.append(accountNameParam, value);
  }

  public unsetAccountName() {
    this.params.delete(accountNameParam);
  }

  get AccountAlias(): string {
    return this.accountAlias;
  }

  set AccountAlias(value: string) {
    this.accountAlias = value;
    this.params.delete(accountAliasParam);
    this.params.append(accountAliasParam, value);
  }

  public unsetAccountAlias() {
    this.params.delete(accountAliasParam);
  }

  get AppName(): string {
    return this.appName;
  }

  set AppName(value: string) {
    this.appName = value;
    this.params.delete(appNameParam);
    this.params.append(appNameParam, value);
  }

  public unsetAppName() {
    this.params.delete(appNameParam);
  }

  get User(): string {
    return this.user;
  }

  set User(value: string) {
    this.user = value;
    this.params.delete(userParam);
    this.params.append(userParam, value);
  }

  public unsetUser() {
    this.params.delete(userParam);
  }

  get AccountNameSort(): SortType | undefined {
    return this.accountNameSort;
  }

  set AccountNameSort(value: SortType | undefined) {
    this.accountNameSort = value;
    this.params.delete(accountNameSortParam);
    this.params.append(accountNameSortParam, value ?? "");
  }

  public unsetAccountNameSort() {
    this.params.delete(accountNameSortParam);
  }

  get AccountAliasSort(): SortType | undefined {
    return this.accountAliasSort;
  }

  set AccountAliasSort(value: SortType | undefined) {
    this.accountAliasSort = value;
    this.params.delete(accountAliasSortParam);
    this.params.append(accountAliasSortParam, value ?? "");
  }

  public unsetAccountAliasSort() {
    this.params.delete(accountAliasSortParam);
  }

  get CreatedDateSort(): SortType | undefined {
    return this.createdDateSort;
  }

  set CreatedDateSort(value: SortType | undefined) {
    this.createdDateSort = value;
    this.params.delete(createdDateSortParam);
    this.params.append(createdDateSortParam, value ?? "");
  }

  public unsetCreatedDateSort() {
    this.params.delete(createdDateSortParam);
  }

  get RecentActionSort(): SortType | undefined {
    return this.recentActionSort;
  }

  set RecentActionSort(value: SortType | undefined) {
    this.recentActionSort = value;
    this.params.delete(recentActionSortParam);
    this.params.append(recentActionSortParam, value ?? "");
  }

  public unsetRecentActionSort() {
    this.params.delete(recentActionSortParam);
  }

  get Params(): URLSearchParams {
    return this.params;
  }

  public toString(): string {
    return this.params.toString();
  }
}
