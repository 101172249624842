import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { ExtendedUser } from "../models/ExtendedUser";
import { UserSettings } from "../models/UserSettings";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";

export const useSettings = () => {
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState<UserSettings>({} as UserSettings);
  const userId = user?.user_metadata?.id as string;

  useEffect(() => {
    const getUserProfileSettings = async () => {
      await request({
        route: apiRoutes.userProfiles.getUserSettings(),
        method: "GET",
        onSuccess: async (response) => setSettings(await response.json()),
        onAny: () => setIsLoading(false)
      });
    };

    getUserProfileSettings();
  }, []);

  const handleSubmit = async (value: UserSettings) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.userProfiles.updateUserSettings(userId),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "PUT",
      onSuccess: () => {
        setSettings(value);
        pushNotification({ message: translate("request.profiles.settingsSuccess"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("request.profiles.settingsFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, settings, handleSubmit };
};
