import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const parentProductValidationSchema = (translate : TranslateFunction) => Yup.object().shape({
  id: Yup.string(),
  productName: Yup.string().required(translate("validation.fieldRequired")),
  supportedFileExtensionsIds: Yup.array()
    .required(translate("validation.fieldRequired"))
    .min(1, translate("validation.fileExtensionRequired"))
});
