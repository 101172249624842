import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { useCategories } from "../contexts/CategoriesContext";
import { CategoryDetails } from "../models/CategoryDetails";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";

export const useCategoriesActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { getCategories, onEditModeIdChange } = useCategories();
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { translate } = useLocalization();

  const onFailure = async (response: Response) => {
    const json = await response.json();
    pushNotification({ ...json, type: "danger" });
  };

  const handleDelete = (categoryId: number) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: apiRoutes.categories.deleteCategory(categoryId),
        method: "DELETE",
        onSuccess: async () => {
          await getCategories();
          pushNotification({ message: translate("plugins.categoryDeletedSuccessfully"), type: "success" });
        },
        onFailure,
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeThisCategory"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  const handleUpdate = async (categoryId: number, value: CategoryDetails) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.categories.updateCategory(categoryId),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getCategories();
        pushNotification({ message: translate("plugins.categoryUpdated"), type: "success" });
      },
      onFailure,
      onAny: () => setIsLoading(false)
    });
  };

  const handleCreate = async (value: CategoryDetails) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.categories.createCategory(),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "POST",
      onSuccess: async () => {
        onEditModeIdChange(-1);
        await getCategories();
        pushNotification({ message: translate("plugins.categoryCreated"), type: "success" });
      },
      onFailure,
      onAny: () => setIsLoading(false)
    });
  };

  return {
    isLoading,
    handleUpdate,
    handleDelete,
    handleCreate
  };
};
