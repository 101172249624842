import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Checkbox } from "../common/inputs/Checkbox";
import { Select } from "../common/inputs/Select";
import { useProducts } from "./contexts/ProductsContext";
import { ProductDetails } from "./models/ProductDetails";
import { usePreserve } from "../../contexts/PreserveContext";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocalization } from "../../contexts/LocalizationContext";

type ProductsTableEditableRowProps = {
  index: number;
  handleSave: () => void;
};

export const ProductsTableEditableRow = ({ index, handleSave }: ProductsTableEditableRowProps) => {
  const formikContext = useFormikContext<ProductDetails>();
  const { theme } = useTheme();
  const { parentProducts, onEditModeIdChange } = useProducts();
  const { values, touched, errors, handleChange } = formikContext;
  const { safeExecute } = usePreserve();
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border">
        <FormControl type="text" name="productName" size="sm" value={values.productName} onChange={handleChange} />
        {touched.productName && !!errors.productName && (
          <span className="invalid-feedback d-inline">{errors.productName}</span>
        )}
      </td>
      <td className="border">
        <FormControl
          type="text"
          name="minimumStudioVersion"
          size="sm"
          value={values.minimumStudioVersion}
          onChange={handleChange}
        />
        {touched.minimumStudioVersion && !!errors.minimumStudioVersion && (
          <span className="invalid-feedback d-inline">{errors.minimumStudioVersion}</span>
        )}
      </td>
      <td className="border">
        <Select
          name="parentProductID"
          size="sm"
          value={values.parentProductID.toString()}
          items={parentProducts.map((x) => ({ value: x.id.toString(), text: x.productName }))}
          onChange={handleChange}
        />
        {touched.parentProductID && !!errors.parentProductID && (
          <span className="invalid-feedback d-inline">{errors.parentProductID}</span>
        )}
      </td>
      <td className="border text-center">
        <Checkbox name="isLegacy" checked={values.isLegacy} onChange={handleChange} />
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.save")}</Tooltip>}>
          <Button variant="transparent" className="p-0" aria-label={translate("common.save")} onClick={handleSave}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.backgroundColor }} />
          </Button>
        </OverlayTrigger>
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.discard")}</Tooltip>}>
          <Button
            variant="transparent"
            className="p-0"
            aria-label={translate("common.discard")}
            onClick={() => safeExecute(() => onEditModeIdChange(-1))}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
