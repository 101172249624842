import { AccountsFilter } from "../model/AccountsFilter";
import { AccountsFilterActionType } from "../model/AccountsFilterActionType";
import { SortType } from "../model/SortType";

export const updateFilters = (filters: AccountsFilter, type: AccountsFilterActionType, value?: string | string[]) => {
  switch (type) {
    case AccountsFilterActionType.SetCategory:
      return setCategories(filters, value as string[] | undefined);
    case AccountsFilterActionType.UnsetCategory:
      return unsetCategory(filters, value as string | undefined);
    case AccountsFilterActionType.SetProduct:
      return setProducts(filters, value as string[] | undefined);
    case AccountsFilterActionType.UnsetProduct:
      return unsetProduct(filters, value as string | undefined);
    case AccountsFilterActionType.SetPricing:
      return setPricing(filters, value as string[] | undefined);
    case AccountsFilterActionType.UnsetPricing:
      return unsetPricing(filters, value as string | undefined);
    case AccountsFilterActionType.SetAppStatus:
      return setAppStatus(filters, value as string[] | undefined);
    case AccountsFilterActionType.UnsetAppStatus:
      return unsetAppStatus(filters, value as string | undefined);
    case AccountsFilterActionType.SetAccountStatus:
      return setAccountStatus(filters, value as string[] | undefined);
    case AccountsFilterActionType.UnsetAccountStatus:
      return unsetAccountStatus(filters, value as string | undefined);
    case AccountsFilterActionType.SetAccountName:
      return setAccountName(filters, value as string | undefined);
    case AccountsFilterActionType.UnsetAccountName:
      return unsetAccountName(filters);
    case AccountsFilterActionType.SetAccountAlias:
      return setAccountAlias(filters, value as string | undefined);
    case AccountsFilterActionType.UnsetAccountAlias:
      return unsetAccountAlias(filters);
    case AccountsFilterActionType.SetAppName:
      return setAppName(filters, value as string | undefined);
    case AccountsFilterActionType.UnsetAppName:
      return unsetAppName(filters);
    case AccountsFilterActionType.SetUser:
      return setUser(filters, value as string | undefined);
    case AccountsFilterActionType.UnsetUser:
      return unsetUser(filters);
    case AccountsFilterActionType.SetAccountNameSort:
      return setAccountNameSort(filters, value as SortType | undefined);
    case AccountsFilterActionType.UnsetAccountNameSort:
      return unsetAccountNameSort(filters);
    case AccountsFilterActionType.SetAccountAliasSort:
      return setAccountAliasSort(filters, value as SortType | undefined);
    case AccountsFilterActionType.UnsetAccountAliasSort:
      return unsetAccountAliasSort(filters);
    case AccountsFilterActionType.SetCreatedDateSort:
      return setCreatedDateSort(filters, value as SortType | undefined);
    case AccountsFilterActionType.UnsetCreatedDateSort:
      return unsetCreatedDateSort(filters);
    case AccountsFilterActionType.SetRecentActionSort:
      return setRecentActionSort(filters, value as SortType | undefined);
    case AccountsFilterActionType.UnsetRecentActionSort:
      return unsetRecentActionSort(filters);
    case AccountsFilterActionType.ClearAll:
      return new AccountsFilter(new URLSearchParams());
    default:
      return filters;
  }
};

const setCategories = (filters: AccountsFilter, value?: string[]) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.Categories = value ?? [];
  return updatedFilters;
};

const unsetCategory = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetCategory(value ?? "");
  return updatedFilters;
};

const setProducts = (filters: AccountsFilter, value?: string[]) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.Products = value ?? [];
  return updatedFilters;
};

const unsetProduct = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetProduct(value ?? "");
  return updatedFilters;
};

const setPricing = (filters: AccountsFilter, value?: string[]) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.Pricing = value ?? [];
  return updatedFilters;
};

const unsetPricing = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetPricing(value ?? "");
  return updatedFilters;
};

const setAppStatus = (filters: AccountsFilter, value?: string[]) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AppStatus = value ?? [];
  return updatedFilters;
};

const unsetAppStatus = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAppStatus(value ?? "");
  return updatedFilters;
};

const setAccountStatus = (filters: AccountsFilter, value?: string[]) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AccountStatus = value ?? [];
  return updatedFilters;
};

const unsetAccountStatus = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAccountStatus(value ?? "");
  return updatedFilters;
};

const setAccountName = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AccountName = value ?? "";
  return updatedFilters;
};

const unsetAccountName = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAccountName();
  return updatedFilters;
};

const setAccountAlias = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AccountAlias = value ?? "";
  return updatedFilters;
};

const unsetAccountAlias = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAccountAlias();
  return updatedFilters;
};

const setAppName = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AppName = value ?? "";
  return updatedFilters;
};

const unsetAppName = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAppName();
  return updatedFilters;
};

const setUser = (filters: AccountsFilter, value?: string) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.User = value ?? "";
  return updatedFilters;
};

const unsetUser = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetUser();
  return updatedFilters;
};

const setAccountNameSort = (filters: AccountsFilter, value?: SortType) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AccountNameSort = value;
  return updatedFilters;
};

const unsetAccountNameSort = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAccountNameSort();
  return updatedFilters;
};

const setAccountAliasSort = (filters: AccountsFilter, value?: SortType) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.AccountAliasSort = value;
  return updatedFilters;
};

const unsetAccountAliasSort = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetAccountAliasSort();
  return updatedFilters;
};

const setCreatedDateSort = (filters: AccountsFilter, value?: SortType) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.CreatedDateSort = value;
  return updatedFilters;
};

const unsetCreatedDateSort = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetCreatedDateSort();
  return updatedFilters;
};

const setRecentActionSort = (filters: AccountsFilter, value?: SortType) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.RecentActionSort = value;
  return updatedFilters;
};

const unsetRecentActionSort = (filters: AccountsFilter) => {
  const updatedFilters = new AccountsFilter(filters.Params);
  updatedFilters.unsetRecentActionSort();
  return updatedFilters;
};
