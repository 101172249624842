import { PluginLog } from "./models/PluginLog";
import { Container, Row } from "react-bootstrap";
import { LogsToolbar } from "./LogsToolbar";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useSearchParams } from "react-router-dom";
import { PluginLogsFilters } from "./models/PluginLogsFilters";
import { applyPluginLogsFilter } from "./actions/applyPluginLogsFilters";
import { PluginLogsFilterActionType } from "./models/PluginLogsFilterActionType";
import { updatePluginLogsFilters } from "./actions/updatePluginLogsFilters";
import { AppliedPluginLogFilters } from "./AppliedPluginLogFilters";

type PluginLogsProps = { logs: PluginLog[] };

export const Logs = ({ logs }: PluginLogsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { translate } = useLocalization();
  const filters = new PluginLogsFilters(searchParams);
  const filtered = applyPluginLogsFilter(filters, logs);

  const handleFiltersChange = (action: PluginLogsFilterActionType, value?: string) => {
    setSearchParams(updatePluginLogsFilters(filters, action, value).toString());
  };

  return (
    <div className="max-vh-100 overflow-y">
      <Container className="p-3 border-bottom">
        <Row>
          <LogsToolbar filters={filters} handleFiltersChange={handleFiltersChange} />
        </Row>
        <Row className="mt-2">
          <AppliedPluginLogFilters filters={filters} handleFiltersChange={handleFiltersChange} />
        </Row>
      </Container>
      <Container>
        <Row style={{ maxHeight: "80vh", overflowY: "auto" }}>
          {filtered?.length === 0 ? (
            <div className="py-3">{translate("plugins.noLogs")}</div>
          ) : (
            filtered
              ?.reverse()
              .map((x) => (
                <div key={x.id} className="p-3 border-bottom" dangerouslySetInnerHTML={{ __html: x.content }} />
              ))
          )}
        </Row>
      </Container>
    </div>
  );
};
