import { useState } from "react";

export const useCopy = () => {
  const [showMessage, setShowMessage] = useState(false);

  const copy = (text: string) => {
    setShowMessage(true);
    navigator.clipboard.writeText(text);

    const timeoutId = setTimeout(() => {
      setShowMessage(false);
      clearTimeout(timeoutId);
    }, 2000);
  };

  return { showMessage, copy };
};
