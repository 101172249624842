import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";
import { markdownContentValidator, resourceTypeAndSizeValidator } from "./CommonSchema";

export const pluginValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    name: Yup.string().required(translate("validation.fieldRequired")),
    iconUpload: Yup.mixed().when(["publishToRWSAppStore", "mediaUrl"], {
      is: (publishToRWSAppStore: boolean, mediaUrl: string) => {
        return publishToRWSAppStore && !mediaUrl;
      },
      then: () => resourceTypeAndSizeValidator(true, translate),
      otherwise: () => resourceTypeAndSizeValidator(false, translate)
    }),
    supportUrl: Yup.string().url(translate("validation.invalidURLFormat")),
    supportEmail: Yup.string().email(translate("validation.invalidEmail")),
    markdownDescription: markdownContentValidator(translate),
    markdownDocumentation: markdownContentValidator(translate, true),
    categories: Yup.array().min(1, translate("validation.selectCategory")),
    parentProductDetailsId: Yup.number().min(1, translate("validation.fieldRequired")),
    publishToRWSAppStore: Yup.boolean(),
    mediaUrl: Yup.string()
  });
