export class NotificationFilters {
  private status: string;
  private query: string;

  constructor() {
    this.status = "";
    this.query = "";
  }

  get Query(): string {
    return this.query;
  }

  set Query(value: string) {
    this.query = value;
  }

  get Status(): string {
    return this.status;
  }

  set Status(value: string) {
    this.status = value;
  }
}
