import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormikContext } from "formik";

type EditorProps = { name: string; value: string };

export const Editor = ({ name, value }: EditorProps) => {
  const { setFieldValue } = useFormikContext();

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={(value) => setFieldValue(name, value)}
      modules={modules}
      formats={formats}
      className="position-relative"
    />
  );
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],

    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
    ["link", "image", "video"]
  ]
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "background",
  "script",
  "sub",
  "super",
  "align",
  "direction",
  "code-block",
  "clean"
];
