import { CSSProperties, forwardRef } from "react";
import { Form } from "react-bootstrap";
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";
import { useTheme } from "../../../contexts/ThemeContext";

type SwitchProps = FormCheckInputProps & { label?: string };

const Component = ({ checked, name, label, ...props }: SwitchProps, ref: React.Ref<HTMLInputElement>) => {
  const { theme } = useTheme();
  const style: CSSProperties = {
    backgroundColor: checked ? theme?.backgroundColor : "white",
    borderColor: checked ? theme?.backgroundColor : "lightgray",
    color: theme?.foregroundColor
  };

  return (
    <Form.Check type="switch">
      <Form.Check.Input {...props} name={name} id={name} ref={ref} style={style} checked={checked} />
      {label && <Form.Check.Label htmlFor={name}>{label}</Form.Check.Label>}
    </Form.Check>
  );
};

export const Switch = forwardRef(Component);
