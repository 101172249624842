import { partial_ratio } from "fuzzball";
import { removeHTMLTags } from "../../../utils";
import { NotificationFilters } from "../models/NotificationFilters";
import { PushNotification } from "../models/PushNotification";
import { NotificationStatus } from "../models/NotificationStatus";

export const applyFilters = (filters: NotificationFilters, notifications: PushNotification[]) => {
  let filteredNotifications = [...notifications];

  if (filters.Query) filteredNotifications = filterByQuery(filteredNotifications, filters.Query);
  if (filters.Status) filteredNotifications = filterByStatus(filteredNotifications, filters.Status);

  return filteredNotifications;
};

const filterByQuery = (notifications: PushNotification[], query: string) => {
  const score = 85;

  return notifications.filter((x) => {
    const description = removeHTMLTags(x.content);
    if (partial_ratio(description, query) >= score) return true;
  });
};

const filterByStatus = (plugins: PushNotification[], status: string) => {
  return plugins.filter((x) => NotificationStatus[x.status].includes(status));
};
