import { getIn, useFormikContext } from "formik";
import { Multiselect } from "../common/inputs/Multiselect";
import { Select } from "../common/inputs/Select";
import { EditableFieldButton } from "./EditableFieldButton";
import { Switch } from "../common/inputs/Switch";
import { usePlugin } from "./contexts/PluginContext";
import { Col, Form, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { publicStatusMap } from "./models/StatusHelper";
import { usePluginDetailsPartial } from "./hooks/usePluginDetailsPartial";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { MarkdownEditor } from "../common/inputs/MarkdownEditor";
import { PluginDetails } from "./models/PluginDetails";
import { useDownload } from "../../hooks/useDownload";
import { apiRoutes } from "../../apiRoutes";
import { useLocation, useParams } from "react-router-dom";
import { ResourceType } from "./models/ResourceType";
import { routes } from "../../main/routes";

type PluginDetailsPartialProps = { hasLockedFields?: boolean };

export const PluginDetailsPartial = ({ hasLockedFields }: PluginDetailsPartialProps) => {
  const pluginPartial = usePluginDetailsPartial(!!hasLockedFields);
  const { manifestResult, pluginDetails: plugin, categories, parentProducts, setManifestResultField } = usePlugin();
  const { errors, touched, values, handleChange, setFieldValue } = useFormikContext<PluginDetails>();
  const { disabledFields, handleFieldDisable, handleResourceDownload } = pluginPartial;
  const { handleFileChange, handleStatusChange } = pluginPartial;
  const { savePluginResource, deletePluginResource } = pluginPartial;
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const { pluginId } = useParams();
  const { handleDownload } = useDownload();
  const sharedProducts = parentProducts.filter((x) => x.value !== `${values.parentProductDetailsId}`);
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const isNewPluginRoute = useLocation().pathname === routes.plugins.create();

  return (
    <>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="name" className="m-0">
            {translate("plugins.appName")}
          </Form.Label>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="name"
            type="text"
            name="name"
            onChange={handleChange}
            onBlur={() => setManifestResultField("IsPluginNameMatch", true)}
            value={values?.name}
            className="w-100"
            disabled={disabledFields.name}
          />
          {touched.name && !!errors.name && <span className="invalid-feedback d-inline">{errors.name}</span>}
          {manifestResult["IsPluginNameMatch"] === false && (
            <span className="invalid-feedback d-inline text-center">{translate("plugins.invalidAppName")}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="name"
            isDisabled={disabledFields.name}
            onClick={() => handleFieldDisable("name")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="iconUpload" className="m-0">
            {translate("plugins.iconFile")}
          </Form.Label>
          <span className="text-danger ms-1">
            *
            <span aria-label={translate("validation.requiredWhenPublished")} />
          </span>
        </Col>
        <Col xs={8}>
          <Form.Control
            accept=".avif, .gif, .jpg, .jfif, .pjpeg, .pfp, .png"
            id="iconUpload"
            type="file"
            name="iconUpload"
            onChange={handleFileChange}
            className="w-100"
          />
          {touched.iconUpload && !!errors.iconUpload && (
            <span className="invalid-feedback d-inline">{errors.iconUpload}</span>
          )}
        </Col>
        {getIn(values, "mediaUrl") && (
          <OverlayTrigger
            placement="left"
            aria-hidden
            overlay={
              <Popover style={{ zIndex: 1000, width: 330, maxWidth: 330 }}>
                <Popover.Header as="h3">{translate("plugins.previewImage")}</Popover.Header>
                <Popover.Body>
                  <img src={getIn(values, "mediaUrl")} width={300} />
                </Popover.Body>
              </Popover>
            }
          >
            <FontAwesomeIcon style={{ right: -50 }} className="position-absolute text-warning" icon={faFileAlt} />
          </OverlayTrigger>
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="supportUrl" className="m-0">
            {translate("plugins.supportURL")}
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="supportUrl"
            type="url"
            name="supportUrl"
            onChange={handleChange}
            value={values?.supportUrl}
            className="w-100"
          />
          {touched.supportUrl && !!errors.supportUrl && (
            <span className="invalid-feedback d-inline">{errors.supportUrl}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="supportEmail" className="m-0">
            {translate("plugins.supportEmail")}
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Form.Control
            id="supportEmail"
            type="email"
            name="supportEmail"
            onChange={handleChange}
            value={values?.supportEmail}
            className="w-100"
          />
          {touched.supportEmail && !!errors.supportEmail && (
            <span className="invalid-feedback d-inline">{errors.supportEmail}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label className="m-0">{translate("plugins.description")}</Form.Label>
        </Col>
        <Col xs={8}>
          <MarkdownEditor
            name="markdownDescription"
            onUpload={(value) => savePluginResource(value, ResourceType.Description)}
            onDelete={(value) => deletePluginResource(value, ResourceType.Description)}
            onPreview={(value) => setFieldValue("htmlDescription", value)}
            onChange={(value) => setFieldValue("markdownDescription", value)}
            downloadResource={
              pluginId ? () => handleResourceDownload(ResourceType.Description, parseInt(pluginId)) : undefined
            }
            html={values.htmlDescription}
            markdown={values.markdownDescription}
            resources={values.descriptionResources ?? []}
            disableUploadTab={isNewPluginRoute}
          />
          {touched.markdownDescription && !!errors.markdownDescription && (
            <span className="invalid-feedback d-inline">{errors.markdownDescription}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label className="m-0">{translate("plugins.documentation")}</Form.Label>
        </Col>
        <Col xs={8}>
          <MarkdownEditor
            name="markdownDocumentation"
            onDelete={(value) => deletePluginResource(value, ResourceType.Documentation)}
            onUpload={(value) => savePluginResource(value, ResourceType.Documentation)}
            onPreview={(value) => setFieldValue("htmlDocumentation", value)}
            onChange={(value) => setFieldValue("markdownDocumentation", value)}
            downloadBasicTemplate={() => handleDownload(apiRoutes.resources.getBasicMarkdownTemplate())}
            downloadAdvancedTemplate={() => handleDownload(apiRoutes.resources.getAdvancedMarkdownTemplate())}
            downloadResource={
              pluginId ? () => handleResourceDownload(ResourceType.Documentation, parseInt(pluginId)) : undefined
            }
            html={values.htmlDocumentation}
            markdown={values.markdownDocumentation}
            resources={values.documentationResources ?? []}
            disableUploadTab={isNewPluginRoute}
          />
          {touched.markdownDocumentation && !!errors.markdownDocumentation && (
            <span className="invalid-feedback d-inline">{errors.markdownDocumentation}</span>
          )}
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4} className="d-flex">
          <Form.Label htmlFor="categories" className="m-0">
            {translate("plugins.categories")}
            <span className="text-danger ms-1">*</span>
          </Form.Label>
        </Col>
        <Col xs={8}>
          <Multiselect
            name="categories"
            items={categories}
            values={values.categories.map((x) => x.toString())}
            size="lg"
            disabled={disabledFields.categories}
            onChange={(values) => setFieldValue("categories", values)}
          />
          {touched.categories && !!errors.categories && (
            <span className="invalid-feedback d-inline">{errors.categories}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="categories"
            isDisabled={disabledFields.categories}
            onClick={() => handleFieldDisable("categories")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <Form.Label htmlFor="parentProductDetailsId" className="m-0">
            {translate("plugins.product")}
          </Form.Label>
          <span className="text-danger ms-1">*</span>
        </Col>
        <Col xs={8}>
          <Select
            id="parentProductDetailsId"
            name="parentProductDetailsId"
            items={parentProducts}
            value={values.parentProductDetailsId.toString()}
            onChange={handleChange}
            disabled={disabledFields.parentProductDetailsId}
          />
          {touched.parentProductDetailsId && !!errors.parentProductDetailsId && (
            <span className="invalid-feedback d-inline">{errors.parentProductDetailsId}</span>
          )}
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="parentProductDetailsId"
            isDisabled={disabledFields.parentProductDetailsId}
            onClick={() => handleFieldDisable("parentProductDetailsId")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center position-relative">
        <Col xs={4}>
          <p className="m-0">{translate("plugins.sharedProducts")}</p>
        </Col>
        <Col xs={8}>
          <Multiselect
            name="sharedParentProductIds"
            items={sharedProducts}
            values={values?.sharedParentProductIds.map((x) => x.toString())}
            size="lg"
            disabled={disabledFields.sharedParentProductIds}
            onChange={(values) => setFieldValue("sharedParentProductIds", values)}
          />
        </Col>
        {hasLockedFields && isSystemAdmin && (
          <EditableFieldButton
            name="sharedParentProductIds"
            isDisabled={disabledFields.sharedParentProductIds}
            onClick={() => handleFieldDisable("sharedParentProductIds")}
          />
        )}
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="paidFor">{translate("plugins.chargeable")}</Form.Check.Label>
        </Col>
        <Switch
          checked={values?.paidFor}
          name="paidFor"
          id="paidFor"
          onChange={(e) => setFieldValue("paidFor", e.target.checked)}
        />
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Check.Label htmlFor="publishToRWSAppStore">
            {translate("plugins.publishToRWSAppStore")}
          </Form.Check.Label>
        </Col>
        {plugin?.pluginVersions?.some((x) => publicStatusMap.get(x.status)) ? (
          <Switch
            checked={values?.publishToRWSAppStore}
            id="publishToRWSAppStore"
            name="publishToRWSAppStore"
            onChange={handleStatusChange}
          />
        ) : (
          <OverlayTrigger
            overlay={<Tooltip>{translate("plugins.oneVersionShouldBePublished")}</Tooltip>}
            placement="right"
          >
            <span>
              <Switch id="publishToRWSAppStore" name="publishToRWSAppStore" disabled />
            </span>
          </OverlayTrigger>
        )}
      </Form.Group>
    </>
  );
};
