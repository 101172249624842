import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePlugin } from "./contexts/PluginContext";
import { useVersion } from "./contexts/VersionContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useUploadFileArea } from "./hooks/useUploadFileArea";
import { faFileAlt, faFileDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Form, FormGroup, InputGroup, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useLocalization } from "../../contexts/LocalizationContext";
import { routes } from "../../main/routes";
import { PluginVersion } from "./models/PluginVersion";

type UploadFileAreaProps = { setIsLoading: (value: boolean) => void };

export const UploadFileArea = ({ setIsLoading }: UploadFileAreaProps) => {
  const formik = useFormikContext<PluginVersion>();
  const uploadFile = useUploadFileArea(setIsLoading);
  const { pathname } = useLocation();
  const { user } = useAuth0<ExtendedUser>();
  const { version } = useVersion();
  const { isSigned, requiresSignature } = version;
  const { pluginDetails: plugin } = usePlugin();
  const { buttonStyle } = useTheme();
  const { errors, touched, values, handleChange } = formik;
  const { dragOver, fileName } = uploadFile;
  const { handleDrop, handleDragLeave, handleRemoveFile } = uploadFile;
  const { handleNavigationLink, handleFileChange, handleDragOver } = uploadFile;
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const signedClass = isSigned ? "text-success" : "text-danger";
  const isNewVersion = pathname === routes.versions.create(plugin.id);
  const { translate } = useLocalization();

  const uploadAreaStyle: React.CSSProperties = {
    padding: dragOver ? "20px" : "10px",
    transition: "all .1s ease-in-out",
    backgroundColor: dragOver ? "#e9ecef" : "#e6e6e6"
  };

  return (
    <div
      className="mb-2"
      style={uploadAreaStyle}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      <div
        style={{ border: "2px dashed gray" }}
        className="d-flex flex-column justify-content-center align-items-center p-5 border-dashed"
      >
        <ButtonGroup>
          {!plugin?.isLanguageCloudPlugin && !version?.isNavigationLink && (
            <Button
              style={values.isNavigationLink ? {} : buttonStyle}
              variant={"light"}
              onClick={() => handleNavigationLink(false)}
            >
              {translate("file.fileUpload")}
            </Button>
          )}
          {(isNewVersion || version?.isNavigationLink) && (
            <Button
              variant={"light"}
              style={values.isNavigationLink ? buttonStyle : {}}
              onClick={() => handleNavigationLink(true)}
            >
              {translate("file.downloadPath")}
            </Button>
          )}
        </ButtonGroup>

        {values.isNavigationLink ? (
          <div className="mt-3 w-100">
            <InputGroup>
              <InputGroup.Text>{translate("file.downloadURL")}</InputGroup.Text>
              <Form.Control type="text" name="downloadUrl" onChange={handleChange} value={values?.downloadUrl} />
            </InputGroup>
            {touched.downloadUrl && !!errors.downloadUrl && (
              <span className="invalid-feedback d-inline">{errors.downloadUrl}</span>
            )}
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center mt-3 text-center">
            <FontAwesomeIcon icon={faFileAlt} />
            <FormGroup>
              <Form.Label htmlFor="versionUpload" className="fw-bold me-1 cursor-pointer">
                {translate("file.chooseFile")}
              </Form.Label>
              <Form.Control
                type="file"
                id="versionUpload"
                name="versionUpload"
                onChange={handleFileChange}
                className="w-100"
                accept=".sdlplugin, .zip"
                hidden
              />
              {translate("file.dropItHere")}
            </FormGroup>
            {fileName && (
              <div className="d-flex align-items-center">
                <p className="m-0 me-2">{fileName}</p>
                <OverlayTrigger overlay={<Tooltip>{translate("file.removeFile")}</Tooltip>}>
                  <FontAwesomeIcon icon={faTimes} className="text-danger cursor-pointer" onClick={handleRemoveFile} />
                </OverlayTrigger>
                {!isSigned && !values.versionUpload && requiresSignature && (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={
                      isSystemAdmin ? (
                        <Popover>
                          <Popover.Header as="h3">{translate("file.unsignedFile")}</Popover.Header>
                          <Popover.Body>
                            {translate("file.unsignedFileProceed")}
                            <ul>
                              <li>{translate("file.downloadFile")}</li>
                              <li>{translate("file.signWithTool")}</li>
                              <li>{translate("file.uploadAndSubmit")}</li>
                            </ul>
                          </Popover.Body>
                        </Popover>
                      ) : (
                        <Tooltip>{translate("file.digitalSignature")}</Tooltip>
                      )
                    }
                  >
                    <FontAwesomeIcon className={`cursor-pointer ${signedClass} ms-1`} icon={faFileDownload} />
                  </OverlayTrigger>
                )}
              </div>
            )}
            {touched.versionUpload && !!errors.versionUpload && (
              <span className="invalid-feedback d-inline">{errors.versionUpload}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
