import { NotificationStatus } from "./NotificationStatus";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBan, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const notificationStatusClassMap = new Map<NotificationStatus, string>([
  [NotificationStatus.Published, "text-success"],
  [NotificationStatus.InReview, "text-warning"],
  [NotificationStatus.InComplete, "text-danger"],
  [NotificationStatus.None, "text-success"],
  [NotificationStatus.All, "text-success"]
]);

export const notificationStatusIconMap = new Map<NotificationStatus, IconProp>([
  [NotificationStatus.Published, faCheckCircle],
  [NotificationStatus.InReview, faExclamationTriangle],
  [NotificationStatus.InComplete, faBan],
  [NotificationStatus.None, faCheckCircle],
  [NotificationStatus.All, faCheckCircle]
]);
