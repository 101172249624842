import { useLocalization } from "../../contexts/LocalizationContext";
import { FilterItem } from "./FilterItem";
import { AccountsFilter } from "./model/AccountsFilter";
import { AccountsFilterActionType } from "./model/AccountsFilterActionType";

type SideFiltersProps = {
  categories: string[];
  products: string[];
  filter: AccountsFilter;
  handleFiltersChange: (action: AccountsFilterActionType, value?: string | string[]) => void;
};

export const SideFilters = ({ categories, products, filter, handleFiltersChange }: SideFiltersProps) => {
  const { translate } = useLocalization();

  return (
    <>
      <FilterItem
        name={translate("plugins.categories")}
        borderTop
        borderBottom
        applied={filter.Categories ?? []}
        items={categories}
        onChange={(value) => handleFiltersChange(AccountsFilterActionType.SetCategory, value)}
      />
      <FilterItem
        name={translate("plugins.products")}
        borderBottom
        applied={filter.Products ?? []}
        items={products}
        onChange={(value) => handleFiltersChange(AccountsFilterActionType.SetProduct, value)}
      />
      <FilterItem
        name={translate("plugins.pricing")}
        borderBottom
        applied={filter.Pricing ?? []}
        items={[translate("plugins.free"), translate("plugins.paid")]}
        onChange={(value) => handleFiltersChange(AccountsFilterActionType.SetPricing, value)}
      />
      <FilterItem
        name={translate("plugins.appStatus")}
        borderBottom
        applied={filter.AppStatus ?? []}
        items={[
          translate("plugins.published"),
          translate("plugins.inReview"),
          translate("plugins.inComplete"),
          translate("plugins.private")
        ]}
        onChange={(value) => handleFiltersChange(AccountsFilterActionType.SetAppStatus, value)}
      />
      <FilterItem
        name={translate("plugins.accountStatus")}
        borderBottom
        applied={filter.AccountStatus ?? []}
        items={[translate("plugins.public"), translate("plugins.private")]}
        onChange={(value) => handleFiltersChange(AccountsFilterActionType.SetAccountStatus, value)}
      />
    </>
  );
};
