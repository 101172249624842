import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type RejectionReasonModalProps = {
  onClose: () => void;
  onContinue: (rejectionReason: string) => void;
};

export const RejectionReasonModal = ({ onClose, onContinue }: RejectionReasonModalProps) => {
  const [reason, setReason] = useState("");
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();

  return (
    <Modal show onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("register.rejectionReason")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FloatingLabel label={translate("plugins.rejectionReason")}>
          <Form.Control
            as="textarea"
            onChange={(e) => setReason(e.target.value)}
            placeholder={translate("plugins.leaveAComment")}
            style={{ height: "100px" }}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer>
        <Button style={buttonStyle} onClick={() => onContinue(reason)}>
          {translate("common.continue")}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {translate("common.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
