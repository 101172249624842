import { useEffect } from "react";
import logo from "../../../resources/rws-logo.svg";
import { Spinner } from "react-bootstrap";
import { useLocalization } from "../../../contexts/LocalizationContext";

const imageContainerStyle: React.CSSProperties = {
  position: "absolute",
  display: "flex",
  alignItems: "center"
};

const spinnerStyle = { color: "#00A89F", width: "45px", height: "45px" };

const boxStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  alignItems: "center",
  backgroundColor: "white",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2
};

export const Loader = () => {
  const { translate } = useLocalization();

  useEffect(() => {
    const body = document.body;
    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "auto";
    };
  }, []);

  return (
    <div style={boxStyle}>
      <Spinner animation="border" style={spinnerStyle} />
      <div style={imageContainerStyle}>
        <img src={logo} width={15} height={15} alt={translate("plugins.loading")} />
      </div>
    </div>
  );
};
