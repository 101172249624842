export enum PluginsFilterActionType {
  None = 0,
  SetQuery,
  UnsetQuery,
  SetPage,
  UnsetPage,
  SetCapacity,
  UnsetCapacity,
  SetStatus,
  UnsetStatus,
  SetProduct,
  UnsetProduct,
  ClearAll
}
