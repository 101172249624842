import { Form, useFormikContext } from "formik";
import { Table } from "react-bootstrap";
import { UserRole } from "../identity/models/UserRole";
import { UsersTableEditableRow } from "./UsersTableEditableRow";
import { UsersTableReadonlyRow } from "./UsersTableReadonlyRow";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect, useState } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { UserProfileMini } from "../identity/models/UserProfileMini";
import { TableFooter } from "../common/TableFooter";
import { UserProfileRoleUpdate } from "./models/UserProfileRoleUpdate";

type UsersTableProps = {
  users: UserProfileMini[];
  roles: UserRole[];
  editModeId: string;
  onDiscard: () => void;
  onEdit: (id: string) => void;
  onDismiss: (id: string) => void;
};

const defaultCapacity = 10;

export const UsersTable = ({ editModeId, users, roles, onEdit, onDismiss, onDiscard }: UsersTableProps) => {
  const [capacity, setCapacity] = useState(defaultCapacity);
  const { values, initialValues } = useFormikContext<UserProfileRoleUpdate>();
  const { setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();
  const newUsers = users.slice(0, capacity);

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th></th>
            <th>{translate("common.email")}</th>
            <th>{translate("account.username")}</th>
            <th>{translate("account.status")}</th>
            <th>{translate("account.assignedRole")}</th>
            <th colSpan={3}></th>
          </tr>
        </thead>
        <tbody>
          {newUsers.map((x, i) =>
            editModeId === x.id ? (
              <UsersTableEditableRow index={i + 1} key={x.id} user={x} roles={roles} onDiscard={onDiscard} />
            ) : (
              <UsersTableReadonlyRow
                user={x}
                key={x.id}
                users={users}
                index={i + 1}
                onEdit={onEdit}
                onDismiss={onDismiss}
              />
            )
          )}
          <TableFooter
            expand={() => setCapacity(defaultCapacity)}
            expandAll={() => setCapacity(users.length)}
            capacity={defaultCapacity}
            showMenu={users.length > defaultCapacity}
            showEmpty={!newUsers.length}
            colSpan={8}
            emptyMessage={translate("account.noUsersFound")}
          />
        </tbody>
      </Table>
    </Form>
  );
};
