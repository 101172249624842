import { Spinner } from "react-bootstrap";
import { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { useLocalization } from "../../contexts/LocalizationContext";
import { apiRoutes } from "../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { PushNotificationMini } from "./models/PushNotificationMini";

type NotificationProps = { notification: PushNotificationMini };

export const AdminNotification = ({ notification }: NotificationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { request } = useFetch();
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const userId = user?.user_metadata?.id as string;

  const handleView = () => {
    setIsLoading(true);
    request({
      route: apiRoutes.userProfiles.changeSelectedAccount(userId),
      body: JSON.stringify({ selectedAccountId: notification.accountId }),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => {
        window.location.href = `${process.env.PUBLIC_URL}/`;
      }
    });
  };

  return (
    <div className="p-3">
      <span className="d-flex">
        <p className="m-0 fw-bold me-2">{notification.accountName}:</p>
        <p className="m-0">
          {notification.activeNotificationsCount} {translate("common.active")}, {notification.notificationsCount}{" "}
          {translate("notification.complete")}
        </p>
      </span>
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <p className="m-0 text-decoration-underline text-primary cursor-pointer" onClick={handleView}>
          {translate("notification.takeAction")}
        </p>
      )}
    </div>
  );
};
