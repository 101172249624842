import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { PluginDetailsExtended } from "./models/PluginDetailsExtended";
import { iconStatusMap, inactiveStatusMap } from "./models/StatusHelper";
import { Status } from "./models/Status";
import { Col, Card, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { DeletePluginButtonGroup } from "./DeletePluginButtonGroup";
import { DeleteAction } from "./models/DeleteAction";
import { useLocalization } from "../../contexts/LocalizationContext";
import { dateFormat } from "../../utils";
import { routes } from "../../main/routes";

const cardStyle: React.CSSProperties = {
  minHeight: "200px",
  width: "100%"
};

type PluginCardProps = { plugin: PluginDetailsExtended };

export const PluginCard = ({ plugin }: PluginCardProps) => {
  const navigate = useNavigate();
  const baseUrl = process.env.PUBLIC_URL;
  const status = iconStatusMap.get(plugin.status);
  const { translate } = useLocalization();

  return (
    <Col xs={12} md={6} xl={3} className="p-3 d-flex justify-content-center">
      <Card
        className="plugin-card"
        style={inactiveStatusMap.get(plugin.status) ? { ...cardStyle, backgroundColor: "#F2F2F2" } : cardStyle}
      >
        <img
          className="d-block mt-2 m-auto"
          width={60}
          alt={plugin.name}
          src={plugin?.iconDetails?.mediaUrl ?? `${baseUrl}/plugin.png`}
        />
        <Card.Body className="d-flex flex-column justify-content-center py-0">
          <h3
            className="text-wrap text-center cursor-pointer plugin-name m-1 fs-6"
            onClick={() => navigate(routes.plugins.update(plugin.id, plugin.status))}
          >
            {plugin.name}
          </h3>
          <div className="d-flex flex-column justify-content-between px-3">
            {plugin.updatedAt && (
              <p style={{ margin: 0, fontSize: 13 }}>
                {translate("plugins.lastModifiedDate", {
                  date: dateFormat(translate, plugin.updatedAt)
                })}
              </p>
            )}

            <a href={routes.versions.index(plugin.id)} className="m-0" style={{ fontSize: "13px" }}>
              {translate("plugins.managedVersions")}[{plugin.pluginVersions?.length}]
            </a>
          </div>
          <Row className="p-3">
            <Col xs={8}>
              {status && (
                <>
                  <FontAwesomeIcon className="me-2" icon={status} />
                  {Status[plugin?.status]}
                </>
              )}
            </Col>
            <Col xs={4} className="d-flex justify-content-end align-items-center">
              {plugin?.lastModifiedVersion?.updatedAt && (
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      {translate("plugins.lastModifiedDate", {
                        date: plugin.lastModifiedVersion.versionNumber
                      })}
                    </Tooltip>
                  }
                >
                  <Button
                    variant="transparent"
                    className="p-0 me-2"
                    aria-label={translate("plugins.viewLastModifiedVersion")}
                    onClick={() =>
                      navigate(
                        routes.versions.update(
                          plugin.id,
                          plugin.lastModifiedVersion.versionId,
                          plugin.lastModifiedVersion.status
                        )
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faHistory} className="text-primary cursor-pointer" />
                  </Button>
                </OverlayTrigger>
              )}
              <DeletePluginButtonGroup
                hasIcon
                showOverlay
                id={plugin.id}
                deleteInstructions={plugin.deleteInstructions}
                futureInstructions={DeleteAction.All}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
