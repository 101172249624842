import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "../../contexts/ThemeContext";
import { SortType } from "./model/SortType";
import { Button } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

type SortableTableHeadProps = {
  sortType?: SortType;
  setSortType: (type: SortType) => void;
};

export const SortableTableHead = ({ sortType, setSortType }: SortableTableHeadProps) => {
  const { theme } = useTheme();
  const { translate } = useLocalization();
  const style: React.CSSProperties = { color: theme.backgroundColor };

  return (
    <div className="d-flex flex-column ms-3">
      <Button variant="transparent" className="p-0 border-0" aria-label={translate("plugins.ascendentSort")}>
        <FontAwesomeIcon
          style={sortType === "asc" ? style : {}}
          onClick={() => setSortType("asc")}
          className="cursor-pointer"
          icon={faCaretUp}
        />
      </Button>
      <Button variant="transparent" aria-label={translate("plugins.descendentSort")} className="p-0 border-0">
        <FontAwesomeIcon
          style={sortType === "desc" ? style : {}}
          onClick={() => setSortType("desc")}
          className="cursor-pointer"
          icon={faCaretDown}
        />
      </Button>
    </div>
  );
};
