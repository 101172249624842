import { faPenAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParentProducts } from "./contexts/ParentProductsContext";
import { ParentProductDetails } from "./models/ParentProductDetails";
import { useResize } from "../../hooks/useResize";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type ParentProductsTableReadonlyRow = {
  item: ParentProductDetails;
  index: number;
  onDelete: () => void;
};

export const ParentProductsTableReadonlyRow = ({ item, index, onDelete }: ParentProductsTableReadonlyRow) => {
  const { onEditModeIdChange, fileExtensions } = useParentProducts();
  const { isExpanded, onToggle } = useResize();
  const { safeExecute } = usePreserve();
  const { translate } = useLocalization();

  const extensions = fileExtensions
    .filter((x) => item.supportedFileExtensionsIds.some((y) => y === x.id))
    .map((x) => ` ${x.extension}`);

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="fw-bold fw-xl-normal border" onClick={onToggle}>
        {item.productName}
      </td>
      {isExpanded && (
        <>
          <td className="border">{extensions.toString()}</td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.edit")}</Tooltip>}>
              <Button
                variant="transparent"
                className="p-0"
                aria-label={translate("common.edit")}
                onClick={() => safeExecute(() => onEditModeIdChange(item.id))}
              >
                <FontAwesomeIcon icon={faPenAlt} className="text-secondary" />
              </Button>
            </OverlayTrigger>
          </td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.delete")}</Tooltip>}>
              <Button variant="transparent" className="p-0" aria-label={translate("common.delete")} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
              </Button>
            </OverlayTrigger>
          </td>
        </>
      )}
    </tr>
  );
};
