import { faCompress, faDownload, faExpand, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalization } from "../../../contexts/LocalizationContext";

type DropdownDownloadProps = {
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  downloadResource?: () => void;
  downloadBasicTemplate?: () => void;
  downloadAdvancedTemplate?: () => void;
};

export const MarkdownEditorMenu = ({
  isExpanded,
  setIsExpanded,
  downloadResource,
  downloadBasicTemplate,
  downloadAdvancedTemplate
}: DropdownDownloadProps) => {
  const { translate } = useLocalization();
  const showMenu = [downloadResource, downloadBasicTemplate, downloadAdvancedTemplate].some(Boolean);

  return (
    <div className="position-absolute d-flex align-items-center end-0">
      {showMenu && (
        <OverlayTrigger overlay={<Tooltip>{translate("plugins.downloadDropdown")}</Tooltip>}>
          <DropdownButton
            variant="transparent"
            title={<FontAwesomeIcon icon={faDownload} />}
            id="dropdown-download-button"
            aria-label="Download options button"
          >
            {downloadBasicTemplate && (
              <Dropdown.Item onClick={downloadBasicTemplate} className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFileAlt} className="me-2" />
                <p className="m-0">{translate("plugins.downloadBasicMarkdownTemplate")}</p>
              </Dropdown.Item>
            )}
            {downloadAdvancedTemplate && (
              <Dropdown.Item onClick={downloadAdvancedTemplate} className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFileAlt} className="me-2" />
                <p className="m-0">{translate("plugins.downloadAdvancedMarkdownTemplate")}</p>
              </Dropdown.Item>
            )}
            {downloadResource && (
              <Dropdown.Item onClick={downloadResource} className="d-flex align-items-center">
                <FontAwesomeIcon icon={faFileAlt} className="me-2" />
                <p className="m-0">{translate("plugins.downloadCurrentMarkdown")}</p>
              </Dropdown.Item>
            )}
          </DropdownButton>
        </OverlayTrigger>
      )}

      <OverlayTrigger
        overlay={<Tooltip>{isExpanded ? translate("plugins.compress") : translate("plugins.expand")}</Tooltip>}
      >
        <Button variant="transparent" onClick={() => setIsExpanded((prev) => !prev)}>
          <FontAwesomeIcon icon={isExpanded ? faCompress : faExpand} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
