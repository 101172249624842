import { useEffect, useState } from "react";

export const useResize = () => {
  const [isExpanded, setIsExpanded] = useState(window.innerWidth > 1200);
  const [isDisabled, setIsDisabled] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsExpanded(window.innerWidth > 1200);
      setIsDisabled(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onToggle = () => (isDisabled ? undefined : setIsExpanded(!isExpanded));

  return { isExpanded, onToggle };
};
