import { ReactElement } from "react";
import { Row, Form } from "react-bootstrap";
import { SelectItem } from "../common/inputs/models/SelectItem";
import { Select } from "../common/inputs/Select";
import { Switch } from "../common/inputs/Switch";
import { Pagination } from "./Pagination";
import { useLocalization } from "../../contexts/LocalizationContext";

const capacityitems: SelectItem[] = [
  { value: "16", text: "16" },
  { value: "25", text: "25" },
  { value: "50", text: "50" },
  { value: "100", text: "100" }
];

type PaginationWrapperProps = {
  children: ReactElement;
  page: number;
  count: number;
  isOnlyTop?: boolean;
  capacity: number;
  isTableView: boolean;
  setTableView: (value: boolean) => void;
  handleClick: (value: number) => void;
  setCapacity: (value: number) => void;
};

export const PaginationWrapper = ({
  children,
  page,
  count,
  capacity,
  isTableView,
  setTableView,
  handleClick,
  setCapacity
}: PaginationWrapperProps) => {
  const { translate } = useLocalization();
  if (count === 0) return <>{children}</>;

  return (
    <>
      <Row className="px-3">
        <div className="d-flex flex-column flex-lg-row justify-content-between p-0">
          <div className="order-1 order-lg-0">
            {count > 1 && <Pagination currentPage={page} totalPages={count} onClick={handleClick} />}
          </div>
          <div className="d-flex align-items-center order-0 order-lg-1 mb-3 m-lg-0">
            <div className="d-flex align-items-center justify-content-center">
              <Form.Label htmlFor="tableView" className="text-nowrap m-0 me-2">
                {translate("plugins.tableView")}
              </Form.Label>
              <Switch
                name="tableView"
                aria-label={translate("plugins.tableViewSwitch")}
                checked={isTableView}
                onChange={(e) => setTableView(e.target.checked)}
              />
            </div>
            <Select
              size="sm"
              aria-label={translate("plugins.pluginsPageCapacity")}
              value={capacity.toString()}
              items={capacityitems}
              onChange={(e) => setCapacity(parseInt(e.target.value))}
            />
          </div>
        </div>
      </Row>
      {children}
      {count > 1 && (
        <Row className="ps-3 mb-3">
          <Pagination currentPage={page} totalPages={count} onClick={handleClick} />
        </Row>
      )}
    </>
  );
};
