import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Forbidden } from "../../pages/Forbidden";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "./models/ExtendedUser";

type ProtectedProps = {
  children: ReactNode;
  checkAccount?: boolean;
  checkAgreement?: boolean;
};

export const Protected = ({ children, checkAccount, checkAgreement }: ProtectedProps) => {
  const { user, loginWithRedirect } = useAuth0<ExtendedUser>();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isComputing, setIsComputing] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const authorize = async () => {
      if (!user) {
        loginWithRedirect();
        return;
      }

      const profile = user?.user_metadata;

      if (!profile) {
        setIsComputing(false);
        return;
      }

      if (!profile?.selectedAccount?.id || checkAccount !== true) {
        const route = profile.belongsToAppStoreAccount ? "/administration" : "/accounts";

        navigate(route);
        setIsComputing(false);
        setIsAuthorized(true);
        return;
      }

      const hasAgreement = profile.hasAgreement || profile.isSystemAdmin;

      if (!hasAgreement && checkAgreement) {
        navigate("/agreement");
        setIsComputing(false);
        setIsAuthorized(true);
        return;
      }

      setIsAuthorized(true);
      setIsComputing(false);
    };

    authorize();
  }, [user, checkAccount, checkAgreement, loginWithRedirect, navigate]);

  if (isComputing) return <Loader />;
  return isAuthorized ? <>{children}</> : <Forbidden />;
};
