import { Col, Row } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { PluginDetailsForm } from "../../components/plugins/PluginDetailsForm";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { PluginDetailsPartial } from "../../components/plugins/PluginDetailsPartial";
import { PublishedPluginButtonGroup } from "../../components/plugins/PublishedPluginButtonGroup";
import { DeletePluginButtonGroup } from "../../components/plugins/DeletePluginButtonGroup";
import { DeleteAction } from "../../components/plugins/models/DeleteAction";
import { PluginDetailsTabs } from "../../components/plugins/PluginDetailsTabs";
import { useEffect } from "react";
import { publicStatusMap } from "../../components/plugins/models/StatusHelper";
import { useNavigate } from "react-router-dom";
import { NotFound } from "../NotFound";
import { routes } from "../../main/routes";

export const PluginDetails = () => {
  const { isLoading, pluginDetails: plugin } = usePlugin();
  const navigate = useNavigate();

  useEffect(() => {
    if (plugin && !publicStatusMap.get(plugin.status)) {
      navigate(routes.plugins.update(plugin.id, plugin.status));
    }
  }, [plugin]);

  if (isLoading) return <Loader />;
  if (!plugin) return <NotFound />;

  return (
    <PluginDetailsForm plugin={plugin}>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <PluginDetailsTabs />
          <PluginDetailsPartial hasLockedFields />
          <div className="d-flex mb-2">
            <PublishedPluginButtonGroup plugin={plugin} />
            <DeletePluginButtonGroup {...plugin} futureInstructions={DeleteAction.One} />
          </div>
        </Col>
      </Row>
    </PluginDetailsForm>
  );
};
