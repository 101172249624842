import { Container, Row } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PushNotification } from "./models/PushNotification";
import { NotificationDetails } from "./NotificationDetails";

type NotificationsListProps = {
  notifications: PushNotification[];
  deleteNotifications: (notificationId: string) => void;
};

export const NotificationsList = ({ notifications, deleteNotifications }: NotificationsListProps) => {
  const { translate } = useLocalization();

  return (
    <Container>
      <Row style={{ overflowY: "auto", maxHeight: 600 }}>
        <div className="p-0">
          {notifications.length > 0 ? (
            notifications.map((notification, i) => (
              <Container key={notification.id}>
                <NotificationDetails notification={notification} deleteNotifications={deleteNotifications} />
                {i !== notifications.length - 1 && <hr className="m-0" aria-hidden />}
              </Container>
            ))
          ) : (
            <div className="p-2">{translate("notification.noNotifications")}</div>
          )}
        </div>
      </Row>
    </Container>
  );
};
