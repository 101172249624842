import { useFetch } from "../../../hooks/useFetch";
import { NameMappingsContextProps } from "./models/NameMappingContextProps";
import { NameMappingsResponse } from "../models/NameMappingsResponse";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { apiRoutes } from "../../../apiRoutes";

type NameMappingsContextProviderProps = { children: ReactElement };

const NameMappingsContext = createContext<NameMappingsContextProps>({
  isLoading: true,
  nameMappings: [],
  pluginDetails: [],
  editModeId: -1,
  capacity: 10,
  getNames: async () => undefined,
  onEditModeIdChange: () => undefined,
  onCapacityChange: () => undefined
});

export const useNameMappings = () => useContext(NameMappingsContext);

export const NameMappingsContextProvider = ({ children }: NameMappingsContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editModeId, setEditModeId] = useState(-1);
  const [capacity, setCapacity] = useState(10);
  const [data, setData] = useState({} as NameMappingsResponse);
  const { request } = useFetch();

  const getNames = useCallback(async () => {
    setIsLoading(true);

    await request({
      route: apiRoutes.nameMappings.getNameMappings(),
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getNames();
  }, [getNames]);

  const onEditModeIdChange = (id: number) => setEditModeId(id);
  const onCapacityChange = (capacity: number) => setCapacity((prev) => prev + capacity);

  return (
    <NameMappingsContext.Provider
      value={{
        ...data,
        isLoading,
        editModeId,
        capacity,
        onEditModeIdChange,
        onCapacityChange,
        getNames
      }}
    >
      {children}
    </NameMappingsContext.Provider>
  );
};
