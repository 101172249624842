import { useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines, faSave } from "@fortawesome/free-solid-svg-icons";
import { UploadResourcesArea } from "./UploadResourcesArea";
import { processMarkdownToHTML } from "../../../utils";
import { Resource } from "../../plugins/models/Resource";
import { MarkdownEditorMenu } from "./MarkdownEditorMenu";

type MarkdownEditorProsp = {
  html: string;
  markdown: string;
  resources: Resource[];
  name: string;
  disableUploadTab?: boolean;
  onChange: (value: string) => void;
  onPreview: (value: string) => void;
  onUpload: (files: File[]) => Promise<void>;
  onDelete: (resourceId: number) => Promise<void>;
  downloadResource?: () => void;
  downloadBasicTemplate?: () => void;
  downloadAdvancedTemplate?: () => void;
};

export const MarkdownEditor = ({
  markdown,
  html,
  resources,
  name,
  disableUploadTab = false,
  onPreview,
  onChange,
  onUpload,
  onDelete,
  downloadResource,
  downloadBasicTemplate,
  downloadAdvancedTemplate
}: MarkdownEditorProsp) => {
  const { translate } = useLocalization();
  const [activeTab, setActiveTab] = useState("write");
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSelect = async (key: string | null) => {
    if (!key) return;
    if (key === "preview") {
      onPreview(await processMarkdownToHTML(markdown, resources));
    }
    setActiveTab(key);
  };

  return (
    <div className={isExpanded ? "full-screen-editor" : undefined}>
      <div className="border rounded rounded-top-3 bg-white">
        <div className="d-flex justify-content-between align-items-center bg-light rounded-top-3 position-relative">
          <Tabs className="bg-light rounded-top-3 flex-grow-1" activeKey={activeTab} onSelect={handleSelect}>
            <Tab eventKey="write" title={translate("common.write")} />
            <Tab eventKey="preview" title={translate("common.preview")} />
            <Tab eventKey="upload" title={translate("common.upload")} />
          </Tabs>
          <MarkdownEditorMenu
            downloadResource={downloadResource}
            downloadBasicTemplate={downloadBasicTemplate}
            downloadAdvancedTemplate={downloadAdvancedTemplate}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
        <div
          className="p-1 flex-grow-1 d-flex flex-column overflow-auto"
          style={{ height: isExpanded ? undefined : "300px", resize: "vertical" }}
        >
          {activeTab === "write" && (
            <>
              <Form.Control
                id={name}
                name={name}
                as="textarea"
                style={{ resize: "none" }}
                value={markdown}
                className="h-100"
                onChange={(e) => onChange(e.target.value)}
              />
              <div className="mt-1">
                <Button
                  className="p-0 px-1 text-secondary"
                  variant="light"
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFileLines} />
                  <span className="m-0 ms-2">{translate("plugins.markdownGuide")}</span>
                </Button>
                <span className="border-end mx-3" />
                <Button
                  className="p-0 px-1 text-secondary"
                  variant="light"
                  href="https://community.rws.com/product-groups/trados-portfolio/rws-appstore/w/wiki/6941/app-developer-guide"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFileLines} />
                  <span className="m-0 ms-2">{translate("plugins.editorGuide")}</span>
                </Button>
              </div>
            </>
          )}

          {activeTab === "preview" && <div className="p-1" dangerouslySetInnerHTML={{ __html: html }} />}
          {activeTab === "upload" &&
            (disableUploadTab ? (
              <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: "100%" }}>
                <FontAwesomeIcon icon={faSave} className="me-2" />
                <p style={{ textAlign: "center", margin: 0 }}>{translate("plugins.saveBeforeUpload")}</p>
              </div>
            ) : (
              <div className="overflow-auto px-3">
                <div className="d-flex flex-column m-auto">
                  <UploadResourcesArea
                    name={`${name}Resources`}
                    resources={resources}
                    onUpload={onUpload}
                    onDelete={onDelete}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
