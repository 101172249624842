import { Alert } from "../components/notifications/models/Alert";
import { Modal } from "../components/notifications/models/Modal";
import { FeedbackContextProps } from "./models/FeedbackContextProps";
import { createContext, ReactElement, useCallback, useContext, useState } from "react";

type FeedbackContextProviderProps = {
  children: ReactElement[] | ReactElement;
};

export const FeedbackContext = createContext<FeedbackContextProps>({
  alerts: [],
  modal: null,
  showModal: () => undefined,
  pushNotification: () => undefined,
  handleClose: () => undefined
});

export const useFeedback = () => useContext(FeedbackContext);

export const FeedbackContextProvider = ({ children }: FeedbackContextProviderProps) => {
  const [notifications, setNotifications] = useState<Alert[]>([]);
  const [modal, setModal] = useState<Modal | null>(null);

  const handleClose = useCallback(
    (index: number) => {
      const newNotifications = [...notifications];
      newNotifications.splice(index, 1);
      setNotifications(newNotifications);
    },
    [setNotifications, notifications]
  );

  const pushNotification = useCallback((notification: Alert) => {
    const id = Math.random().toString();
    notification.id = id;

    setNotifications((prev) => [...prev, notification]);

    const timeoutId = setTimeout(() => {
      setNotifications((prev) => prev.filter((x) => x.id !== id));
      clearInterval(timeoutId);
    }, 4000);
  }, []);

  const showModal = useCallback((modal: Modal | null) => setModal(modal), [setModal]);

  return (
    <FeedbackContext.Provider
      value={{
        pushNotification,
        handleClose,
        showModal,
        alerts: notifications,
        modal
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
