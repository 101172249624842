import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useUser } from "../../../contexts/UserContext";
import { useFetch } from "../../../hooks/useFetch";
import { ExtendedUser } from "../models/ExtendedUser";
import { UserAccount } from "../models/UserAccount";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";

export const useAccounts = <T>(all: boolean) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<T>({} as T);
  const { translate } = useLocalization();
  const { pushNotification } = useFeedback();
  const { user } = useAuth0<ExtendedUser>();
  const { getUser } = useUser();
  const { request } = useFetch();
  const navigate = useNavigate();
  const userId = user?.user_metadata?.id as string;
  const selectedAccountId = user?.user_metadata?.selectedAccount?.id as string;
  const route = all
    ? apiRoutes.userProfiles.getExtensiveUserAccounts(userId)
    : apiRoutes.userProfiles.getMinimalUserAccounts(userId);

  const getAccounts = useCallback(async () => {
    await request({
      route,
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [userId, all, request]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  const handleSubmit = async (value: UserAccount) => {
    if (value.selectedAccountId === selectedAccountId) {
      navigate("/");
    } else {
      setIsLoading(true);
      await request({
        route: apiRoutes.userProfiles.changeSelectedAccount(userId),
        body: JSON.stringify(value),
        contentType: "application/json",
        method: "PUT",
        onSuccess: async () => {
          await getUser();
          navigate("/");
          pushNotification({ message: translate("request.authentication.selectSuccess"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("request.authentication.selectFail"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    }
  };

  const handlePin = async (isFavourite: boolean, accountId: string) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.userAccounts.setAsFavourite(accountId, userId),
      body: JSON.stringify({ isFavourite }),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => await getUser(),
      onFailure: async (response) => pushNotification({ message: await response.text(), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, data, handleSubmit, handlePin };
};
