import { Col, Container, Row } from "react-bootstrap";
import { SideFilters } from "../components/administration/SideFilters";
import { RegisterForm } from "../components/administration/RegisterForm";
import { useAccounts } from "../components/identity/hooks/useAccounts";
import { ExtensiveAccountsResponse } from "../components/administration/model/ExtensiveAccountsResponse";
import { Loader } from "../components/common/progress/Loader";
import { AppliedFilters } from "../components/administration/AppliedFilters";
import { AccountsTable } from "../components/administration/AccountsTable";
import { AccountsFilter } from "../components/administration/model/AccountsFilter";
import { ExportButton } from "../components/administration/ExportButton";
import { useLocalization } from "../contexts/LocalizationContext";
import { useSearchParams } from "react-router-dom";
import { applyFilters } from "../components/administration/actions/applyFilters";
import { AccountsFilterActionType } from "../components/administration/model/AccountsFilterActionType";
import { updateFilters } from "../components/administration/actions/updateFilters";

export const Administration = () => {
  const accountsHook = useAccounts<ExtensiveAccountsResponse>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, handleSubmit, handlePin } = accountsHook;
  const { translate } = useLocalization();
  const filters = new AccountsFilter(searchParams);

  const handleFiltersChange = (action: AccountsFilterActionType, value?: string | string[]) => {
    setSearchParams(updateFilters(filters, action, value).toString());
  };

  if (isLoading) return <Loader />;

  const products = data.products.map((x) => x.productName);
  const categories = data.categories.map((x) => x.name);
  const accounts = applyFilters(filters, data.accounts, data.products, data.categories);

  return (
    <Container>
      <Row>
        <Col xs={12} md={2} className="d-flex align-items-center">
          {translate("settings.accountResources", {
            accountsLength: accounts.length.toString()
          })}
        </Col>
        <Col xs={12} md={6}>
          <AppliedFilters filters={filters} handleFiltersChange={handleFiltersChange} />
        </Col>
        <Col xs={12} md={4} className="d-flex justify-content-end mt-2 m-md-0">
          <RegisterForm />
        </Col>
      </Row>
      <Row className="my-5">
        <Col xs={12} xl={3} className="border-none border-xl-end mb-3">
          <SideFilters
            filter={filters}
            products={products}
            categories={categories}
            handleFiltersChange={handleFiltersChange}
          />
        </Col>
        <Col xs={12} xl={9}>
          <AccountsTable
            filters={filters}
            accounts={accounts}
            onPin={handlePin}
            onAccountSelect={handleSubmit}
            handleFiltersChange={handleFiltersChange}
          />
          <ExportButton accounts={accounts} />
        </Col>
      </Row>
    </Container>
  );
};
