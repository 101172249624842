import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, useFormikContext } from "formik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { useNameMappings } from "./contexts/NameMappingsContext";
import { useNameMappingsActions } from "./hooks/useNameMappingsActions";
import { NameMapping } from "./models/NameMapping";
import { NameMappingsTableEditableRow } from "./NameMappingsTableEditableRow";
import { NameMappingsTableReadyonlyRow } from "./NameMappingsTableReadyonlyRow";
import { nameMappingValidationSchema } from "./schemas/NameMappingValidationSchema";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { TableFooter } from "../common/TableFooter";

const defaultCapacity = 10;

export const NameMappingsTable = () => {
  const names = useNameMappings();
  const { buttonStyle } = useTheme();
  const { theme } = useTheme();
  const { values, initialValues, setTouched } = useFormikContext<NameMapping>();
  const { isLoading, handleCreate, handleUpdate, handleDelete } = useNameMappingsActions();
  const { nameMappings, editModeId, capacity } = names;
  const { onCapacityChange, onEditModeIdChange } = names;
  const { safeExecute, setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleClick = async () => {
    try {
      await nameMappingValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (editModeId === 0) await handleCreate(values);
      else await handleUpdate(editModeId, values);
    } catch (errors) {
      setTouched({ oldName: true, newName: true });
    }
  };

  const handleAdd = () => safeExecute(() => onEditModeIdChange(0));

  if (isLoading) return <Loader />;

  const newNameMappings = nameMappings.slice(0, capacity);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th>{translate("plugins.id")}</th>
            <th>{translate("plugins.oldName")}</th>
            <th>{translate("plugins.newName")}</th>
            <th colSpan={2} className="text-center">
              <OverlayTrigger overlay={<Tooltip>{translate("common.add")}</Tooltip>}>
                <Button variant="transparent" className="p-0" aria-label={translate("common.add")} onClick={handleAdd}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{ color: theme.backgroundColor }} />
                </Button>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {newNameMappings.map((x, i) =>
            x.id === editModeId ? (
              <NameMappingsTableEditableRow key={x.id} index={i + 1} handleSave={handleClick} />
            ) : (
              <NameMappingsTableReadyonlyRow key={x.id} item={x} index={i + 1} onDelete={() => handleDelete(x.id)} />
            )
          )}
          {editModeId === 0 && (
            <NameMappingsTableEditableRow index={nameMappings.length + 1} handleSave={handleClick} />
          )}
          <TableFooter
            expand={() => onCapacityChange(defaultCapacity)}
            expandAll={() => onCapacityChange(nameMappings.length)}
            capacity={defaultCapacity}
            showMenu={!!newNameMappings.length && nameMappings.length > defaultCapacity}
            showEmpty={!newNameMappings.length && editModeId === -1}
            colSpan={5}
            emptyMessage={translate("plugins.noNameMappingsFound")}
          />
        </tbody>
      </Table>
      <Button variant="success" style={buttonStyle} onClick={handleAdd}>
        {translate("common.addNew")}
      </Button>
    </Form>
  );
};
