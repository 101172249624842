import { Auth0Provider } from "@auth0/auth0-react";
import ReactDOM from "react-dom/client";
import { App } from "./main/App";
import { ConfigContext, getConfiguration } from "./contexts/ConfigContext";
import { FeedbackContextProvider } from "./contexts/FeedbackContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { UserProvider } from "./contexts/UserContext";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./fonts.css";
import { AlertsContainer } from "./components/notifications/AlertsContainer";
import { Loader } from "./components/common/progress/Loader";
import { PreserveProvider } from "./contexts/PreserveContext";
import { LocalizationProvider } from "./contexts/LocalizationContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<Loader />);

getConfiguration().then((configuration) => {
  root.render(
    <ConfigContext.Provider value={{ configuration }}>
      <Auth0Provider {...configuration.authDetails}>
        <LocalizationProvider>
          <UserProvider>
            <ThemeProvider>
              <FeedbackContextProvider>
                <PreserveProvider>
                  <App />
                  <AlertsContainer />
                </PreserveProvider>
              </FeedbackContextProvider>
            </ThemeProvider>
          </UserProvider>
        </LocalizationProvider>
      </Auth0Provider>
    </ConfigContext.Provider>
  );
});

reportWebVitals();
