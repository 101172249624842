import { TranslateFunction } from "../../../contexts/LocalizationContext";
import { PluginLogFilterTag } from "./PluginLogFilterTag";
import { PluginLogsFilterActionType } from "./PluginLogsFilterActionType";
import { PluginLogsFilters } from "./PluginLogsFilters";

export class PluginLogFilterTagsBuilder {
  private filters: PluginLogsFilters;

  constructor(filters: PluginLogsFilters) {
    this.filters = filters;
  }

  public getAll(translate: TranslateFunction): PluginLogFilterTag[] {
    return [
      { value: this.filters.Query, action: PluginLogsFilterActionType.UnsetQuery, type: translate("common.query") },
      {
        value: this.filters.FromDate,
        action: PluginLogsFilterActionType.UnsetFromDate,
        type: translate("common.from")
      },
      { value: this.filters.ToDate, action: PluginLogsFilterActionType.UnsetToDate, type: translate("common.to") }
    ];
  }
}
