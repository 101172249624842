import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { ContentType, RestMethod, useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { DeleteAction } from "../models/DeleteAction";
import { Status } from "../models/Status";
import { sequencesEqual, toFormData } from "../../../utils";
import { useDownload } from "../../../hooks/useDownload";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { Action } from "../models/Action";
import { routes } from "../../../main/routes";
import { PluginVersionBase } from "../models/PluginVersionBase";
import { apiRoutes } from "../../../apiRoutes";
import { ReviewType } from "../models/ReviewType";
import { PluginVersionMismatchMapper } from "../models/PluginVersionMismatchMapper";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { reviewTypeStatusMap } from "../models/StatusHelper";
import { PluginVersionExtended } from "../models/PluginVersionExtended";
import { Select } from "../../common/inputs/Select";

export const useVersionActions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { pluginDetails, getPlugin, onManifestResultChange } = usePlugin();
  const params = useParams();
  const { handleDownload } = useDownload();
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const navigate = useNavigate();
  const pluginId = parseInt(params.pluginId as string);
  const pluginVersions = pluginDetails.pluginVersions;
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const legacyVersionIdToActivate = useRef(-1);

  const onFailure = async (response: Response) => {
    const json = await response.json();
    if (json.details) onManifestResultChange(json.details);
    pushNotification({ ...json, type: "danger" });
  };

  const onAny = () => setIsLoading(false);

  const handleDelete = async (versionId: number, deleteAction: DeleteAction) => {
    await handleDeleteRequest(
      versionId,
      JSON.stringify({ deleteAction }),
      apiRoutes.pluginVersion.deleteVersion(pluginId, versionId),
      "DELETE",
      translate("versions.removedPluginVersion"),
      true,
      showInstructions(deleteAction, versionId)
    );
  };

  const reviewDeletionRequest = async (versionId: number, reviewType: ReviewType) => {
    await handleDeleteRequest(
      versionId,
      JSON.stringify({ reviewType }),
      apiRoutes.pluginVersion.reveiewVersionDeletion(pluginId, versionId),
      "PATCH",
      translate("versions.sentDeletionReview"),
      reviewType === ReviewType.Approved
    );
  };

  const requestDeletion = async (versionId: number, deleteAction: DeleteAction) => {
    await handleDeleteRequest(
      versionId,
      JSON.stringify({ deleteAction }),
      apiRoutes.pluginVersion.requestVersionDeletion(pluginId, versionId),
      "POST",
      translate("versions.sentDeletionRequest"),
      true,
      showInstructions(deleteAction, versionId)
    );
  };

  const changeVersionStatus = async (status: Status, versionId: number) => {
    await handleRequest(
      JSON.stringify({ futureStatus: status, legacyVersionIdToActivate: legacyVersionIdToActivate.current }),
      apiRoutes.pluginVersion.changeVersionStatus(pluginId, versionId),
      "PATCH",
      translate("versions.versionStatusChanged"),
      PluginVersionMismatchMapper.getMismatch(
        pluginVersions,
        pluginDetails.status,
        undefined,
        isSystemAdmin,
        versionId,
        status
      ),
      "application/json",
      PluginVersionMismatchMapper.findUnpublishedMatchingVersions(pluginVersions, versionId, status)
    );
  };

  const handleRequestReview = async (versionId: number, reviewType: ReviewType, rejectionReason?: string) => {
    await handleRequest(
      JSON.stringify({ reviewType, rejectionReason }),
      apiRoutes.pluginVersion.reviewVersionRequest(pluginId, versionId),
      "PATCH",
      translate("versions.pluginVersionUpdated"),
      PluginVersionMismatchMapper.getMismatch(
        pluginVersions,
        pluginDetails.status,
        undefined,
        isSystemAdmin,
        versionId,
        reviewTypeStatusMap.get(reviewType)
      ),
      "application/json"
    );
  };

  const handleVersionDownload = async (versionId: number) => {
    setIsLoading(true);
    await handleDownload(apiRoutes.pluginVersion.downloadVersion(pluginId, versionId));
    setIsLoading(false);
  };

  const handleCreate = async (value: PluginVersionBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Save }),
      apiRoutes.pluginVersion.createVersion(pluginId),
      "POST",
      translate("versions.pluginVersionCreated"),
      PluginVersionMismatchMapper.getMismatch(pluginVersions, pluginDetails.status, value, isSystemAdmin)
    );
  };

  const handleCreateDraft = async (value: PluginVersionBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.SaveAsDraft }),
      apiRoutes.pluginVersion.createVersionDraft(pluginId),
      "POST",
      translate("versions.pluginVersionCreated")
    );
  };

  const handleCreatePending = async (value: PluginVersionBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Submit }),
      apiRoutes.pluginVersion.createVersionInReview(pluginId),
      "POST",
      translate("versions.pluginVersionCreated"),
      PluginVersionMismatchMapper.getMismatch(pluginVersions, pluginDetails.status, value, isSystemAdmin)
    );
  };

  const handleUpdate = async (versionId: number, value: PluginVersionBase, action: Action) => {
    await handleRequest(
      toFormData({ ...value, action }),
      apiRoutes.pluginVersion.updateVersion(pluginId, versionId),
      "PUT",
      translate("versions.pluginVersionUpdated"),
      PluginVersionMismatchMapper.getMismatch(pluginVersions, pluginDetails.status, value, isSystemAdmin, versionId)
    );
  };

  const handleUpdateDraft = async (versionId: number, value: PluginVersionBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.SaveAsDraft }),
      apiRoutes.pluginVersion.updateVersionDraft(pluginId, versionId),
      "PUT",
      translate("versions.pluginVersionUpdated")
    );
  };

  const handleUpdatePending = async (versionId: number, value: PluginVersionBase) => {
    await handleRequest(
      toFormData({ ...value, action: Action.Submit }),
      apiRoutes.pluginVersion.updateVersionInReview(pluginId, versionId),
      "PUT",
      translate("versions.pluginVersionUpdated"),
      PluginVersionMismatchMapper.getMismatch(pluginVersions, pluginDetails.status, value, isSystemAdmin, versionId)
    );
  };

  const handleUndoVersionDeletionRequest = async (versionId: number) => {
    await request({
      route: apiRoutes.pluginVersion.undoPluginDeletion(pluginId, versionId),
      method: "PUT",
      onSuccess: () => {
        getPlugin();
      }
    });
  };

  const handleDeleteRequest = async (
    versionId: number,
    body: string,
    route: string,
    method: RestMethod,
    successMessage: string,
    withConfirmation?: boolean,
    withFurtherInstructions?: boolean
  ) => {
    const onSuccess = async (response: Response) => {
      if (versionId && response.status === 201) {
        const json = await response.json();
        const versionId = json.publishedPluginVersionId ?? json.versionId;
        navigate(routes.versions.update(pluginId, versionId, json.status));
      } else navigate(routes.versions.index(pluginId));

      await getPlugin();
      pushNotification({ message: successMessage, type: "success" });
    };

    const handleContinue = async (deleteAction?: DeleteAction) => {
      showModal(null);
      setIsLoading(true);
      await request({
        route,
        method,
        body: (deleteAction ? JSON.stringify({ deleteAction }) : null) ?? body,
        contentType: "application/json",
        onSuccess,
        onFailure,
        onAny
      });
    };

    if (withConfirmation) {
      showModal({
        isVisible: true,
        message: translate("versions.removePluginVersion"),
        furtherInstructions: withFurtherInstructions ? translate("versions.reactivateLegacy") : undefined,
        handleClose: () => showModal(null),
        handleContinue
      });
    } else await handleContinue();
  };

  const handleRequest = async (
    body: FormData | string,
    route: string,
    method: RestMethod,
    successMessage: string,
    mismatchResult?: { status: Status; isMismatch: boolean },
    contentType?: ContentType,
    matchingLegacyVersions?: { unpublishedVersions: PluginVersionExtended[]; currentVersionNumber: string }
  ) => {
    const onSuccess = async (response: Response) => {
      const json = await response.json();
      const versionId = json.publishedPluginVersionId ?? json.versionId;
      await getPlugin();
      navigate(routes.versions.update(pluginId, versionId, json.status));
      pushNotification({ message: successMessage, type: "success" });
    };

    const createRequest = async (newBody?: FormData | string) => {
      setIsLoading(true);
      await request({
        route,
        method,
        body: newBody ?? body,
        contentType,
        onSuccess,
        onFailure,
        onAny
      });
    };

    if (mismatchResult?.isMismatch) {
      showModal({
        isVisible: true,
        message: translate("versions.statusMismatchWarning", { status: Status[mismatchResult.status] }),
        handleClose: () => showModal(null),
        handleContinue: async () => {
          showModal(null);
          await createRequest();
        }
      });
    } else {
      if (matchingLegacyVersions?.unpublishedVersions?.length) {
        showModal({
          isVisible: true,
          message: React.createElement(Select, {
            formLable: translate("versions.legacyVersionActivation", {
              disabledVersionNumber: matchingLegacyVersions.currentVersionNumber
            }),
            items: [
              {
                value: "-1",
                text: translate("versions.noLegacyVersionActive")
              },
              ...matchingLegacyVersions.unpublishedVersions.map((version) => ({
                value: version.versionId.toString(),
                text: version.versionNumber
              }))
            ],
            onChange: (e) => {
              legacyVersionIdToActivate.current = Number(e.target.value);
            }
          }),
          handleClose: () => showModal(null),
          handleContinue: async () => {
            await createRequest(
              JSON.stringify({
                futureStatus: Status.NotPublished,
                legacyVersionIdToActivate: legacyVersionIdToActivate.current
              })
            );
            legacyVersionIdToActivate.current = -1;
            showModal(null);
          }
        });
      } else {
        await createRequest();
      }
    }
  };

  const showInstructions = (deleteAction: DeleteAction, versionId: number) => {
    const version = pluginVersions.find((x) => x.versionId === versionId);
    const clone = version?.publishedPluginVersion ?? version?.unpublishedPluginVersion;
    const hasInstructions = [DeleteAction.All, DeleteAction.AllWithLegacyReactivate].includes(deleteAction);
    const hasLegacy = pluginVersions
      .filter((x) => x.versionId !== version?.versionId)
      .some((x) => sequencesEqual(x.supportedProducts, version?.supportedProducts ?? []));

    return version?.status === Status.Published && hasLegacy && (hasInstructions || !clone);
  };

  return {
    isLoading,
    versionActions: {
      handleVersionDownload,
      handleCreate,
      handleCreateDraft,
      handleCreatePending,
      handleUpdate,
      handleUpdateDraft,
      handleUpdatePending,
      handleRequestReview,
      handleUndoVersionDeletionRequest,
      changeVersionStatus,
      handleDelete,
      reviewDeletionRequest,
      requestDeletion
    }
  };
};
