import { Col, Row } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { DraftPluginButtonGroup } from "../../components/plugins/DraftPluginButtonGroup";
import { PluginDetailsForm } from "../../components/plugins/PluginDetailsForm";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { PluginDetailsPartial } from "../../components/plugins/PluginDetailsPartial";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";

export const CreatePlugin = () => {
  const { buttonStyle } = useTheme();
  const { isLoading } = usePlugin();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <PluginDetailsForm>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar />
        </Col>
        <Col xs={12} lg={8}>
          <ul className="nav nav-tabs position-relative mb-3">
            <li className="nav-item">
              <p className="nav-link cursor-pointer m-0" style={buttonStyle}>
                {translate("plugins.create")}
                <span aria-label={translate("notification.view")} />
              </p>
            </li>
          </ul>
          <PluginDetailsPartial />
          <div className="mb-2">
            <DraftPluginButtonGroup />
          </div>
        </Col>
      </Row>
    </PluginDetailsForm>
  );
};
