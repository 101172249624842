import { Formik } from "formik";
import { Container } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { categoryValidationSchema } from "./schemas/CategoryValidationSchema";
import { CategoriesTable } from "./CategoriesTable";
import { CategoryDetails } from "./models/CategoryDetails";
import { useCategories } from "./contexts/CategoriesContext";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Categories = () => {
  const { isLoading, categories, editModeId } = useCategories();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const editableItem = categories?.find((item) => item.id === editModeId);
  const initialValues = {
    id: editableItem?.id ?? 0,
    name: editableItem?.name ?? ""
  };

  return (
    <Container className="p-0 mb-2">
      <Formik<CategoryDetails>
        key={editModeId}
        initialValues={initialValues}
        validationSchema={categoryValidationSchema(translate)}
        onSubmit={() => undefined}
        enableReinitialize
      >
        <>
          <hr aria-hidden className="d-block d-xl-none" />
          <h3>{translate("plugins.manageCategories")}</h3>
          <CategoriesTable />
        </>
      </Formik>
    </Container>
  );
};
