export enum AccountsFilterActionType {
  None = 0,
  SetCategory,
  UnsetCategory,
  SetProduct,
  UnsetProduct,
  SetPricing,
  UnsetPricing,
  SetAppStatus,
  UnsetAppStatus,
  SetAccountStatus,
  UnsetAccountStatus,
  SetAccountName,
  UnsetAccountName,
  SetAccountAlias,
  UnsetAccountAlias,
  SetAppName,
  UnsetAppName,
  SetUser,
  UnsetUser,
  SetAccountNameSort,
  UnsetAccountNameSort,
  SetAccountAliasSort,
  UnsetAccountAliasSort,
  SetCreatedDateSort,
  UnsetCreatedDateSort,
  SetRecentActionSort,
  UnsetRecentActionSort,
  ClearAll
}
