const queryParam = "query";
const fromDateParam = "fromDate";
const toDateParam = "toDate";

export class PluginLogsFilters {
  private fromDate: string;
  private toDate: string;
  private query: string;
  private params: URLSearchParams;

  constructor(params: URLSearchParams) {
    this.params = params;
    this.query = this.params.get(queryParam) ?? "";
    this.fromDate = this.params.get(fromDateParam) ?? "";
    this.toDate = this.params.get(toDateParam) ?? "";
  }

  get FromDate(): string {
    return this.fromDate;
  }

  set FromDate(value: string) {
    this.fromDate = value;
    this.params.delete(fromDateParam);
    this.params.append(fromDateParam, value);
  }

  get ToDate(): string {
    return this.toDate;
  }

  set ToDate(value: string) {
    this.toDate = value;
    this.params.delete(toDateParam);
    this.params.append(toDateParam, value);
  }

  get Query(): string {
    return this.query;
  }

  set Query(value: string) {
    this.query = value;
    this.params.delete(queryParam);
    this.params.append(queryParam, value);
  }

  get Params(): URLSearchParams {
    return this.params;
  }

  public unsetQuery = () => {
    this.params.delete(queryParam);
  };

  public unsetFromDate = () => {
    this.params.delete(fromDateParam);
  };

  public unsetToDate = () => {
    this.params.delete(toDateParam);
  };

  public toString(): string {
    return this.params.toString();
  }
}
