import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useUser } from "../../../contexts/UserContext";
import { useFetch } from "../../../hooks/useFetch";
import { AccountSettings } from "../../identity/models/AccountSettings";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";
import { stringifyReplacer } from "../../../utils";

export const useSettings = (initialLoading?: boolean) => {
  const [isLoading, setIsLoading] = useState(!!initialLoading);
  const [settings, setSettings] = useState<AccountSettings>();
  const { user } = useAuth0<ExtendedUser>();
  const { getUser } = useUser();
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { translate } = useLocalization();
  const accountId = user?.user_metadata?.selectedAccount.id as string;
  const defaultExpiryDays = 30;

  const resetSettings = async () => {
    const values: AccountSettings = {
      id: accountId,
      expiryDays: defaultExpiryDays,
      accountPreferences: { foregroundColor: "#fff" }
    } as AccountSettings;

    showModal({
      isVisible: true,
      message: translate("settings.resetAccountSettings"),
      handleClose: () => showModal(null),
      handleContinue: async () => {
        showModal(null);
        await handleSubmit(values);
      }
    });
  };

  const getAccountSettings = async () => {
    setIsLoading(true);
    await request({
      route: apiRoutes.accounts.getAccountSettings(accountId),
      method: "GET",
      onSuccess: async (response) => setSettings(await response.json()),
      onAny: () => setIsLoading(false)
    });
  };

  const handleSubmit = async (value: AccountSettings) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.accounts.updateAccountSettings(accountId),
      body: JSON.stringify(value, stringifyReplacer),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => {
        await getUser();
        pushNotification({ message: translate("request.account.settingsSuccess"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("request.account.settingsFail"), type: "danger" }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, settings, handleSubmit, resetSettings, getAccountSettings };
};
