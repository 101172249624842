import { useCallback, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { apiRoutes } from "../../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../../identity/models/ExtendedUser";
import { PushNotificationMini } from "../models/PushNotificationMini";

export const useAdminNotifications = () => {
  const [notifications, setNotifications] = useState<PushNotificationMini[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { request } = useFetch();
  const { user } = useAuth0<ExtendedUser>();
  const userId = user?.user_metadata?.id;

  const getNotifications = useCallback(async () => {
    if (userId) {
      setIsLoading(true);
      await request({
        route: apiRoutes.notifications.getUserExtensiveNotifications(userId),
        onSuccess: async (response) => setNotifications(await response.json()),
        onAny: () => setIsLoading(false)
      });
    }
  }, [request, userId]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return { notifications, isLoading };
};
