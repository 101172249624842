import { NotificationFilters } from "../models/NotificationFilters";
import { NotificationsFilterActionType } from "../models/NotificationsFilterActionType";

export const updateFilters = (filters: NotificationFilters, type: NotificationsFilterActionType, value?: string) => {
  switch (type) {
    case NotificationsFilterActionType.SetQuery:
      return setQuery(filters, value as string | undefined);
    case NotificationsFilterActionType.UnsetQuery:
      return unsetQuery(filters);
    case NotificationsFilterActionType.SetStatus:
      return setStatus(filters, value as string | undefined);
    case NotificationsFilterActionType.UnsetStatus:
      return unsetStatus(filters);
    case NotificationsFilterActionType.ClearAll:
      return new NotificationFilters();
    default:
      return filters;
  }
};

const setQuery = (filters: NotificationFilters, value?: string) => {
  const newFilters = new NotificationFilters();
  newFilters.Status = filters.Status;
  newFilters.Query = value ?? "";
  return newFilters;
};

const unsetQuery = (filters: NotificationFilters) => {
  const newFilters = new NotificationFilters();
  newFilters.Status = filters.Status;
  newFilters.Query = "";
  return newFilters;
};

const setStatus = (filters: NotificationFilters, value?: string) => {
  const newFilters = new NotificationFilters();
  newFilters.Query = filters.Query;
  newFilters.Status = value ?? "";
  return newFilters;
};

const unsetStatus = (filters: NotificationFilters) => {
  const newFilters = new NotificationFilters();
  newFilters.Query = filters.Query;
  newFilters.Status = "";
  return newFilters;
};
