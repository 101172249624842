import { Button, Image } from "react-bootstrap";
import EmptyResult from "../../resources/empty-result.png";
import { PluginVersionExtended } from "./models/PluginVersionExtended";
import { PluginVersionRow } from "./PluginVersionRow";
import { useTheme } from "../../contexts/ThemeContext";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalization } from "../../contexts/LocalizationContext";
import { routes } from "../../main/routes";

type VersionsProps = { versions: PluginVersionExtended[] };

export const Versions = ({ versions }: VersionsProps) => {
  const { buttonStyle } = useTheme();
  const { pluginId } = useParams() as { pluginId: string };
  const { translate } = useLocalization();
  const navigate = useNavigate();

  return !versions.length ? (
    <div className="d-flex flex-column align-items-center">
      <Image src={EmptyResult} />
      <h6 className="mt-2">{translate("versions.noVersionsFound")}</h6>
      <Button className="mt-2" style={buttonStyle} onClick={() => navigate(routes.versions.create(parseInt(pluginId)))}>
        {translate("common.addNew")}
      </Button>
    </div>
  ) : (
    <>
      {versions.map((x) => (
        <PluginVersionRow
          key={x.versionId}
          version={x}
          route={routes.versions.update(parseInt(pluginId), x.versionId, x.status)}
        />
      ))}
      <Button style={buttonStyle} className="mt-2" onClick={() => navigate(routes.versions.create(parseInt(pluginId)))}>
        {translate("common.addNew")}
      </Button>
    </>
  );
};
