import { PluginsFilterActionType } from "../models/PluginsFilterActionType";
import { PluginsFilters } from "../models/PluginsFilters";

const defaultPage = 1;
const defaultCapacity = 16;

export const updatePluginsFilters = (
  filters: PluginsFilters,
  type: PluginsFilterActionType,
  value?: string | number
) => {
  switch (type) {
    case PluginsFilterActionType.SetQuery:
      return setQuery(filters, value as string | undefined);
    case PluginsFilterActionType.UnsetQuery:
      return unsetQuery(filters);
    case PluginsFilterActionType.SetPage:
      return setPage(filters, value as number | undefined);
    case PluginsFilterActionType.UnsetPage:
      return setPage(filters, defaultPage);
    case PluginsFilterActionType.SetCapacity:
      return setCapacity(filters, value as number | undefined);
    case PluginsFilterActionType.UnsetCapacity:
      return setCapacity(filters, defaultCapacity);
    case PluginsFilterActionType.SetStatus:
      return setStatus(filters, value as string | undefined);
    case PluginsFilterActionType.UnsetStatus:
      return unsetStatus(filters);
    case PluginsFilterActionType.SetProduct:
      return setProduct(filters, value as string | undefined);
    case PluginsFilterActionType.UnsetProduct:
      return unsetProduct(filters);
    case PluginsFilterActionType.ClearAll:
      return new PluginsFilters(new URLSearchParams());
    default:
      return filters;
  }
};

const setQuery = (filters: PluginsFilters, value?: string) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.Query = value ?? "";
  return updatedFilters;
};

const unsetQuery = (filters: PluginsFilters) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.unsetQuery();
  return updatedFilters;
};

const setPage = (filters: PluginsFilters, value?: number) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.Page = value ?? defaultPage;
  return updatedFilters;
};

const setCapacity = (filters: PluginsFilters, value?: number) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.Capacity = value ?? defaultCapacity;
  return updatedFilters;
};

const setStatus = (filters: PluginsFilters, value?: string) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.Status = value ?? "";
  return updatedFilters;
};

const unsetStatus = (filters: PluginsFilters) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.unsetStatus();
  return updatedFilters;
};

const setProduct = (filters: PluginsFilters, value?: string) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.Product = value ?? "";
  return updatedFilters;
};

const unsetProduct = (filters: PluginsFilters) => {
  const updatedFilters = new PluginsFilters(filters.Params);
  updatedFilters.unsetProduct();
  return updatedFilters;
};
