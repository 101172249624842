import { URLSearchParams } from "url";

const pageParam = "page";
const capacityParam = "capacity";
const queryParam = "query";
const statusParam = "status";
const productParam = "product";

export class PluginsFilters {
  private params: URLSearchParams;
  private capacity: number;
  private query: string;
  private status: string;
  private product: string;
  private page: number;

  constructor(params: URLSearchParams) {
    this.params = params;
    this.query = this.params.get(queryParam) ?? "";
    this.status = this.params.get(statusParam) ?? "";
    this.product = this.params.get(productParam) ?? "";
    this.page = parseInt(this.params.get(pageParam) ?? "1");
    this.capacity = parseInt(this.params.get(capacityParam) ?? "16");
  }

  get Page(): number {
    return this.page;
  }

  set Page(value: number) {
    this.page = value;
    this.params.delete(pageParam);
    this.params.append(pageParam, value.toString());
  }

  get Capacity(): number {
    return this.capacity;
  }

  set Capacity(value: number) {
    this.capacity = value;
    this.params.delete(capacityParam);
    this.params.append(capacityParam, value.toString());
  }

  get Query(): string {
    return this.query;
  }

  set Query(value: string) {
    this.query = value;
    this.params.delete(queryParam);
    this.params.append(queryParam, value);
  }

  get Status(): string {
    return this.status;
  }

  set Status(value: string) {
    this.status = value;
    this.params.delete(statusParam);
    this.params.append(statusParam, value);
  }

  get Product(): string {
    return this.product;
  }

  set Product(value: string) {
    this.product = value;
    this.params.delete(productParam);
    this.params.append(productParam, value);
  }

  get Params(): URLSearchParams {
    return this.params;
  }

  public unsetQuery = () => {
    this.params.delete(queryParam);
  };

  public unsetStatus = () => {
    this.params.delete(statusParam);
  };

  public unsetProduct = () => {
    this.params.delete(productParam);
  };

  public toString(): string {
    return this.params.toString();
  }
}
