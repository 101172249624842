import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useVersionActions } from "./hooks/useVersionActions";
import { DeleteAction } from "./models/DeleteAction";
import { DeleteButton } from "./DeleteButton";
import { Placement } from "react-bootstrap/esm/types";
import { PluginVersionExtended } from "./models/PluginVersionExtended";
import { faExclamationCircle, faInfoCircle, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { ReviewType } from "./models/ReviewType";

type DeleteVersionButtonGroupProps = {
  version: PluginVersionExtended;
  hasIcon?: boolean;
  showOverlay?: boolean;
  popoverPlacement?: Placement;
  futureInstructions: DeleteAction;
};

export const DeleteVersionButtonGroup = ({
  version: { versionId, deleteInstructions },
  hasIcon,
  showOverlay,
  popoverPlacement,
  futureInstructions
}: DeleteVersionButtonGroupProps) => {
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { isLoading, versionActions } = useVersionActions();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  if (deleteInstructions === DeleteAction.None) {
    return (
      <div className="d-flex align-items-center">
        <DeleteButton
          showOverlay={showOverlay}
          hasIcon={hasIcon}
          onClick={() =>
            isSystemAdmin
              ? versionActions.handleDelete(versionId, futureInstructions)
              : versionActions.requestDeletion(versionId, futureInstructions)
          }
        />
      </div>
    );
  }

  if (isSystemAdmin) {
    return (
      <div className="d-flex align-items-center">
        <OverlayTrigger
          trigger="click"
          placement={popoverPlacement}
          rootClose
          overlay={
            <Popover style={{ zIndex: 1000 }}>
              <Popover.Header as="h3">{translate("plugins.deletionRequest")}</Popover.Header>
              <Popover.Body>
                <Button
                  style={buttonStyle}
                  type="button"
                  onClick={() => versionActions.reviewDeletionRequest(versionId, ReviewType.Approved)}
                >
                  {translate("plugins.approve")}
                </Button>
                <Button
                  variant="danger ms-2"
                  onClick={() => versionActions.reviewDeletionRequest(versionId, ReviewType.Rejected)}
                >
                  {translate("plugins.reject")}
                </Button>
              </Popover.Body>
            </Popover>
          }
        >
          <DeleteButton hasIcon={hasIcon} />
        </OverlayTrigger>
        <OverlayTrigger placement="right" overlay={<Tooltip>{translate("plugins.deletionApprovalNeeded")}</Tooltip>}>
          <FontAwesomeIcon icon={faExclamationCircle} className="text-warning ms-2 cursor-pointer" />
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center">
      <DeleteButton disabled hasIcon={hasIcon} />
      <OverlayTrigger overlay={<Tooltip>{translate("common.undo")}</Tooltip>}>
        <Button
          variant="transparent"
          className="m-0"
          onClick={() => versionActions.handleUndoVersionDeletionRequest(versionId)}
        >
          <FontAwesomeIcon icon={faUndo} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger placement="right" overlay={<Tooltip>{translate("plugins.deletionRequestPending")}</Tooltip>}>
        <FontAwesomeIcon icon={faInfoCircle} className="text-warning cursor-pointer" />
      </OverlayTrigger>
    </div>
  );
};
