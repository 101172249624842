import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col } from "react-bootstrap";
import { SelectItem } from "./models/SelectItem";
import { HTMLAttributes, SyntheticEvent, forwardRef, useEffect, useState } from "react";
import { faCaretDown, faChevronLeft, faChevronRight, faCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocalization } from "../../../contexts/LocalizationContext";

export type MultiselectToggleProps = HTMLAttributes<HTMLButtonElement> & {
  items: SelectItem[];
  isToggled: boolean;
  name: string;
  values: string[];
  size: "sm" | "lg";
  disabled?: boolean;
  handleScroll: (e: SyntheticEvent, value: number) => void;
  handleRemove: (e: SyntheticEvent, value: string) => void;
};

const Component = (
  { items, isToggled, name, values, size, disabled, handleScroll, handleRemove, onClick }: MultiselectToggleProps,
  ref: React.Ref<HTMLButtonElement>
) => {
  const [showChevrons, setShowChevrons] = useState(false);
  const { translate } = useLocalization();

  useEffect(() => {
    const overflow = document.querySelector(`#${name} .summary`);

    if (overflow) {
      setShowChevrons(overflow?.scrollWidth > (overflow?.parentElement?.offsetWidth as number));
    }
  }, [showChevrons, values, name]);

  const togglerStyle: React.CSSProperties = {
    padding: size === "sm" ? "3px 8px" : "8px",
    backgroundColor: disabled ? "#e9ecef" : "white",
    opacity: 1
  };

  return (
    <Button
      id={name}
      ref={ref}
      style={togglerStyle}
      variant="secondary-outline"
      className="w-100 d-flex align-items-center border"
      aria-label={`${name} multiselect`}
      onClick={onClick}
      aria-expanded={isToggled}
      disabled={disabled}
    >
      <Col xs={11} className="d-flex align-items-center p-0">
        {showChevrons && (
          <Button variant="transparent" className="p-0" style={leftChevronStyle} onClick={(e) => handleScroll(e, 25)}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        )}
        <div className="d-flex summary text-nowrap" style={summaryStyle}>
          {values.length === 0 ? (
            <>{translate("plugins.selectDots")}</>
          ) : (
            items.map(
              (selectItem) =>
                values.some((x) => x === selectItem.value) &&
                (isToggled ? (
                  <span className="border rounded px-2 me-2 d-flex align-items-center" key={selectItem.value}>
                    {selectItem.text}
                    <FontAwesomeIcon
                      className="ms-2 p-1"
                      icon={faTimes}
                      onClick={(e) => handleRemove(e, selectItem.value)}
                    />
                  </span>
                ) : (
                  <span className="px-1 me-2 d-flex align-items-center" key={selectItem.value}>
                    {selectItem.text}
                    <FontAwesomeIcon className="ms-2 p-1" icon={faCircle} style={circleStyle} />
                  </span>
                ))
            )
          )}
        </div>
        {showChevrons && (
          <Button variant="transparent" className="p-0" style={rightChevronStyle} onClick={(e) => handleScroll(e, -25)}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </Col>
      <Col xs={1} className="d-flex align-items-center justify-content-center p-0">
        <FontAwesomeIcon icon={faCaretDown} />
      </Col>
    </Button>
  );
};

export const MultiselectToggle = forwardRef(Component);

const leftChevronStyle: React.CSSProperties = {
  position: "absolute",
  left: "-25px"
};

const rightChevronStyle: React.CSSProperties = {
  position: "absolute",
  right: "-25px"
};

const summaryStyle: React.CSSProperties = { overflowX: "hidden" };
const circleStyle: React.CSSProperties = { fontSize: 8 };
