import { Col, Row } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { PluginDetailsForm } from "../../components/plugins/PluginDetailsForm";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { PluginDetailsTabs } from "../../components/plugins/PluginDetailsTabs";
import { DeleteAction } from "../../components/plugins/models/DeleteAction";
import { DeletePluginButtonGroup } from "../../components/plugins/DeletePluginButtonGroup";
import { PluginDetailsPartial } from "../../components/plugins/PluginDetailsPartial";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { PendingPluginButtonGroup } from "../../components/plugins/PendingPluginButtonGroup";
import { publicStatusMap } from "../../components/plugins/models/StatusHelper";
import { NotFound } from "../NotFound";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Status } from "../../components/plugins/models/Status";
import { routes } from "../../main/routes";

export const PendingPlugin = () => {
  const { isLoading, pluginDetails: plugin } = usePlugin();
  const navigate = useNavigate();
  const versions = plugin?.pluginVersions;
  const pendingPlugin = plugin?.unpublishedPluginDetails ?? plugin;
  const canBeApproved = versions?.some((x) => publicStatusMap.get(x.status));

  useEffect(() => {
    if (pendingPlugin && pendingPlugin.status !== Status.InReview) {
      navigate(routes.plugins.update(plugin.id, plugin.status));
    }
  }, [pendingPlugin]);

  if (isLoading) return <Loader />;
  if (!plugin) return <NotFound />;

  return (
    <PluginDetailsForm plugin={pendingPlugin}>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <PluginDetailsTabs />
          <PluginDetailsPartial />
          <div className="d-flex mb-2">
            <PendingPluginButtonGroup canBeApproved={canBeApproved} />
            <DeletePluginButtonGroup {...pendingPlugin} futureInstructions={DeleteAction.One} />
          </div>
        </Col>
      </Row>
    </PluginDetailsForm>
  );
};
