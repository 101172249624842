import { Formik } from "formik";
import { Container } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { useNameMappings } from "./contexts/NameMappingsContext";
import { NameMapping } from "./models/NameMapping";
import { NameMappingsTable } from "./NameMappingsTable";
import { nameMappingValidationSchema } from "./schemas/NameMappingValidationSchema";
import { useLocalization } from "../../contexts/LocalizationContext";

export const NameMappings = () => {
  const names = useNameMappings();
  const { isLoading, editModeId, nameMappings } = names;
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const editableItem = nameMappings?.find((item) => item.id === editModeId);
  const initialValues: NameMapping = {
    id: editableItem?.id ?? -1,
    oldName: editableItem?.oldName ?? "",
    newName: editableItem?.newName ?? ""
  };

  return (
    <Container className="p-0 mb-2">
      <Formik<NameMapping>
        initialValues={initialValues}
        validationSchema={nameMappingValidationSchema(translate)}
        onSubmit={() => undefined}
        enableReinitialize
      >
        <>
          <hr className="d-block d-xl-none" aria-hidden />
          <h3>{translate("settings.renamedApps")}</h3>
          <NameMappingsTable />
        </>
      </Formik>
    </Container>
  );
};
