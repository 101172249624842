import { partial_ratio } from "fuzzball";
import { PluginDetailsExtended } from "../models/PluginDetailsExtended";
import { PluginsFilters } from "../models/PluginsFilters";
import { Status } from "../models/Status";
import { PluginVersionExtended } from "../models/PluginVersionExtended";
import { ProductDetails } from "../../applicationsettings/models/ProductDetails";

export const applyPluginsFilters = (
  filters: PluginsFilters,
  products: ProductDetails[],
  plugins: PluginDetailsExtended[]
) => {
  let filteredPlugins = [...plugins];

  if (filters.Query) filteredPlugins = filterByQuery(filteredPlugins, filters.Query);
  if (filters.Status) filteredPlugins = filterByStatus(filteredPlugins, filters.Status);
  if (filters.Product) filteredPlugins = filterByProduct(filteredPlugins, products, filters.Product);

  return filteredPlugins;
};

const filterByQuery = (plugins: PluginDetailsExtended[], query: string) => {
  const score = 85;

  return plugins.filter((p) => {
    const developer = p.developerDetails.developerName;

    if (partial_ratio(p.name, query) >= score) return true;
    if (partial_ratio(developer, query) >= score) return true;
    if (partial_ratio(p.markdownDescription, query) >= score) return true;
  });
};

const filterByStatus = (plugins: PluginDetailsExtended[], status: string) => {
  return plugins.filter(
    (x) => Status[x.status].includes(status) || x.pluginVersions.some((y) => Status[y.status].includes(status))
  );
};

const filterByProduct = (plugins: PluginDetailsExtended[], products: ProductDetails[], product: string) => {
  const productId = products.find((x) => x.productName === product)?.id;
  if (!productId) return plugins;
  return plugins.filter((x) => isVersionMatch(x.pluginVersions, productId));
};

const isVersionMatch = (versions: PluginVersionExtended[], productId: number) => {
  return versions.some((x) => isProductMatch(x.supportedProducts, productId));
};

const isProductMatch = (products: number[], productId: number) => {
  return products.some((id) => id === productId);
};
