import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Select } from "../common/inputs/Select";
import { UserRole } from "../identity/models/UserRole";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { UserProfileMini } from "../identity/models/UserProfileMini";
import { UserProfileRoleUpdate } from "./models/UserProfileRoleUpdate";

type UsersTableEditableRowProps = {
  index: number;
  user: UserProfileMini;
  roles: UserRole[];
  onDiscard: () => void;
};

export const UsersTableEditableRow = ({ index, user, roles, onDiscard }: UsersTableEditableRowProps) => {
  const { values, handleChange } = useFormikContext<UserProfileRoleUpdate>();
  const { safeExecute } = usePreserve();
  const selectItems = roles.map((x) => ({ value: x.id, text: x.name }));
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row align-middle text-center text-xl-start">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border fw-bold fw-xl-normal" colSpan={user.isConfirmed ? 1 : 2}>
        {user.email}
      </td>
      {user.isConfirmed && <td className="border">{user.name}</td>}
      <td className="border">{user.isConfirmed ? translate("common.active") : translate("common.invited")}</td>
      <td className="border">
        <Select
          name="selectedRoleId"
          value={values.selectedRoleId}
          items={selectItems}
          size="sm"
          onChange={handleChange}
        />
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.save")}</Tooltip>}>
          <Button type="submit" className="p-0" variant="transparent" aria-label={translate("common.save")}>
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
          </Button>
        </OverlayTrigger>
      </td>
      <td className="border text-center" colSpan={2}>
        <OverlayTrigger overlay={<Tooltip>{translate("common.discard")}</Tooltip>}>
          <Button
            className="p-0"
            type="button"
            variant="transparent"
            aria-label={translate("common.discard")}
            onClick={() => safeExecute(onDiscard)}
          >
            <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
