import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { Comment } from "../models/Comment";
import { useFetch } from "../../../hooks/useFetch";
import { usePlugin } from "../contexts/PluginContext";
import { removeHTMLTags } from "../../../utils";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { CommentUpdate } from "../models/CommentUpdate";
import { apiRoutes } from "../../../apiRoutes";
import { useParams } from "react-router-dom";

export const useComments = (comments: Comment[]) => {
  const [editModeId, setEditModeId] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getPlugin: getPlugins } = usePlugin();
  const { pushNotification, showModal } = useFeedback();
  const { request } = useFetch();
  const { translate } = useLocalization();
  const params = useParams();

  const handleUpdate = async (commentId: string, value: CommentUpdate) => {
    const initial = comments?.find((x) => x.id === value.id);
    setIsLoading(true);
    if (value?.description === initial?.description) setEditModeId(null);
    if (!removeHTMLTags(value.description)) value.description = "";
    await request({
      route: apiRoutes.comments.updateComment(commentId),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "PUT",
      onSuccess: async () => {
        await getPlugins();
        pushNotification({ message: translate("plugins.commentSavedSuccessfully"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("plugins.failedToSaveComment"), type: "danger" }),
      onAny: () => {
        setIsLoading(false);
        setEditModeId(null);
      }
    });
  };

  const handleCreatePluginComment = async (value: CommentUpdate) => {
    const pluginId = params.pluginId as string;
    setIsLoading(true);
    if (!removeHTMLTags(value.description)) value.description = "";
    await request({
      route: apiRoutes.comments.createPluginDetailsComment(parseInt(pluginId)),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "POST",
      onSuccess: async () => {
        await getPlugins();
        pushNotification({ message: translate("plugins.commentSavedSuccessfully"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("plugins.failedToSaveComment"), type: "danger" }),
      onAny: () => {
        setIsLoading(false);
        setEditModeId(null);
      }
    });
  };

  const handleCreateVersionComment = async (value: CommentUpdate) => {
    const pluginId = parseInt(params.pluginId as string);
    const versionId = parseInt(params.versionId as string);
    setIsLoading(true);
    if (!removeHTMLTags(value.description)) value.description = "";
    await request({
      route: apiRoutes.comments.createPluginVersionComment(pluginId, versionId),
      body: JSON.stringify(value),
      contentType: "application/json",
      method: "POST",
      onSuccess: async () => {
        await getPlugins();
        pushNotification({ message: translate("plugins.commentSavedSuccessfully"), type: "success" });
      },
      onFailure: () => pushNotification({ message: translate("plugins.failedToSaveComment"), type: "danger" }),
      onAny: () => {
        setIsLoading(false);
        setEditModeId(null);
      }
    });
  };

  const handleDelete = (commentId: string) => {
    const handleContinue = async () => {
      showModal(null);
      setIsLoading(true);
      await request({
        route: apiRoutes.comments.deleteComment(commentId),
        method: "DELETE",
        onSuccess: async () => {
          await getPlugins();
          pushNotification({ message: translate("plugins.commentDeletedSuccessfully"), type: "success" });
        },
        onFailure: () => pushNotification({ message: translate("plugins.failedToDeleteComment"), type: "danger" }),
        onAny: () => setIsLoading(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeThisComment"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  return {
    editModeId,
    isLoading,
    setEditModeId,
    handleDelete,
    handleCreatePluginComment,
    handleCreateVersionComment,
    handleUpdate
  };
};
