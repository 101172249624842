import { OverlayTrigger, Popover } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

type PopupListProps = { items: string[] };

export const PopupList = ({ items }: PopupListProps) => {
  const { translate } = useLocalization();

  if (items.length === 0) return <p className="m-0">{translate("plugins.noRecords")}</p>;

  return (
    <div className="d-flex justify-content-center">
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="right"
        overlay={
          <Popover>
            <Popover.Body>
              <ul className="m-0" style={{ maxHeight: 300, overflowY: "auto" }}>
                {items.map((x) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        }
      >
        <p onClick={(e) => e.stopPropagation()} className="m-0 text-underline-hover">
          {translate("plugins.records", { items: items.length.toString() })}
        </p>
      </OverlayTrigger>
    </div>
  );
};
