import { useEffect, useState } from "react";
import { DefaultSettings } from "./DefaultSettings";
import { Preferences } from "./Preferences";
import { useTheme } from "../../contexts/ThemeContext";
import { AdvancedSettings } from "./AdvancedSettings";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { usePreserve } from "../../contexts/PreserveContext";
import { useFormikContext } from "formik";
import { AccountSettings } from "../identity/models/AccountSettings";
import { TabItem } from "../common/navigation/models/TabItem";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";

const settingTabs = (translate: TranslateFunction): TabItem[] => [
  { name: translate("settings.default"), component: <DefaultSettings /> },
  { name: translate("settings.advanced"), component: <AdvancedSettings /> },
  { name: translate("settings.preferences"), component: <Preferences /> }
];

export const SettingsContainer = () => {
  const { translate } = useLocalization();
  const [currentTab, setCurrentTab] = useState(settingTabs(translate)[0]);
  const { buttonStyle } = useTheme();
  const { user } = useAuth0<ExtendedUser>();
  const { values, initialValues } = useFormikContext<AccountSettings>();
  const { setCurrentState, setInitialState } = usePreserve();
  const role = user?.user_metadata?.selectedRole.name;

  const filteredItems = settingTabs(translate).filter(
    (x) => !x.grantedRoles || x.grantedRoles?.some((y) => y === role)
  );

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3 className="mb-3">{translate("common.settings")}</h3>
      <ul className="nav nav-tabs mb-3">
        {filteredItems.map((tab) => (
          <li className="nav-item" key={tab.name}>
            <button
              className="nav-link cursor-pointer m-0"
              style={currentTab.name === tab.name ? buttonStyle : {}}
              onClick={() => setCurrentTab(tab)}
            >
              {tab.name}
              <span aria-label={translate("navigation.section")} />
            </button>
          </li>
        ))}
      </ul>
      {currentTab.component}
    </>
  );
};
