export const markdownStyle = `<style>
  :root {
    --table-striped: rgba(0, 0, 0, 0.05);
    --table-border: #dee2e6;
    --lightgray: #e9ecef;
  }
  
  code {
    color: black;
    background-color: var(--lightgray);
    border-radius: 5px;
    padding: 1.5px;
  }
  
  blockquote {
    border-left: 3px solid var(--lightgray);
    padding-left: 10px;
  }
  
  table {
    border: 1px solid var(--table-border);
    border-collapse: collapse;
    width: 100%;
  }
  
  table th,
  table td {
    border: 1px solid var(--table-border);
    padding: 8px;
    text-align: left;
    color: black;
  }
  
  table th {
    background-color: var(--table-striped);
    color: black;
  }
  
  table tr:nth-child(even) {
    background-color: var(--table-striped);
    color: white;
  }
  
  table tr:nth-child(odd) {
    background-color: white;
    color: var(--table-striped);
  }
</style>`;
