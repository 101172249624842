import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { InformationMissingModal } from "./InformationMissingModal";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useDraftVersionButtonGroup } from "./hooks/useDraftVersionButtonGroup";
import { PluginVersion } from "./models/PluginVersion";

export const DraftVersionButtonGroup = () => {
  const { isLoading, showModal, actions } = useDraftVersionButtonGroup();
  const { values } = useFormikContext<PluginVersion>();
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <>
      <Button style={buttonStyle} type="button" onClick={isSystemAdmin ? actions.handleSave : actions.handleSubmit}>
        {values.publishToRWSAppStore
          ? isSystemAdmin
            ? translate("plugins.publish")
            : translate("plugins.submit")
          : translate("common.save")}
      </Button>
      <Button variant="outline-secondary" type="button" className="mx-2" onClick={actions.handleSaveAsDraft}>
        {translate("plugins.saveAsDraft")}
      </Button>
      {showModal && <InformationMissingModal onClose={() => actions.setShowModal(false)} />}
    </>
  );
};
