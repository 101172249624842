import { Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useDownload } from "../../hooks/useDownload";
import { PluginsExportType } from "../plugins/models/PluginsExportType";
import { apiRoutes } from "../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";

type ExportButtonProps = { name: string; exportType: PluginsExportType };

export const ExportButton = ({ name, exportType }: ExportButtonProps) => {
  const { buttonStyle } = useTheme();
  const { isLoading, handleDownload } = useDownload();
  const { user } = useAuth0<ExtendedUser>();
  const accountId = user?.user_metadata?.selectedAccount.id as string;
  const body = JSON.stringify({ exportType });

  return (
    <Button
      disabled={isLoading}
      style={{ ...buttonStyle, width: 150 }}
      onClick={() => handleDownload(apiRoutes.exports.exportPluginsRoute(accountId), body, "POST", "application/json")}
    >
      {isLoading ? <Spinner size="sm" /> : name}
    </Button>
  );
};
