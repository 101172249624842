import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { usePlugin } from "./contexts/PluginContext";
import { PluginDetailsExtended } from "./models/PluginDetailsExtended";
import { Status } from "./models/Status";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { VersionsSidebarList } from "./PluginVersionsSidebarList";
import { useTheme } from "../../contexts/ThemeContext";
import { routes } from "../../main/routes";

type SideNavbarProps = { plugin?: PluginDetailsExtended };

export const SideNavbar = ({ plugin }: SideNavbarProps) => {
  const { manifestResult, setManifestResultField, getPlugin } = usePlugin();
  const { safeExecute } = usePreserve();
  const { pathname } = useLocation();
  const { buttonStyle } = useTheme();
  const navigate = useNavigate();
  const baseUrl = process.env.PUBLIC_URL;
  const versions = plugin?.pluginVersions;
  const { translate } = useLocalization();
  const isVersionsRoute = new RegExp("versions", "i").test(pathname);

  const navigateToPlugin = ({ id, status }: PluginDetailsExtended) => {
    safeExecute((shouldGetPlugin?: boolean) => {
      if (shouldGetPlugin) {
        getPlugin();
      }
      navigate(routes.plugins.update(id, status));
    });
  };

  const navigateToVersions = (id: number) =>
    safeExecute((shouldGetPlugin?: boolean) => {
      if (shouldGetPlugin) {
        getPlugin();
      }
      navigate(routes.versions.index(id));
    });

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-items-center border-bottom">
        <Image
          src={plugin?.iconDetails?.mediaUrl ?? `${baseUrl}/plugin.png`}
          alt={plugin?.name ?? translate("plugins.pluginImage")}
          width={200}
        />
        <p className="m-0 my-3 fs-6 text-center fw-bold">{plugin?.name ?? translate("plugins.appName")}</p>
        {manifestResult["IsPluginNameMatch"] === false && (
          <div>
            <span className="invalid-feedback d-inline text-center">{translate("plugins.invalidAppName")}</span>
            <FontAwesomeIcon
              icon={faTimes}
              className="text-danger ms-3 cursor-pointer"
              onClick={() => setManifestResultField("IsPluginNameMatch", true)}
            />
          </div>
        )}
        <Container fluid className="my-2">
          <Row>
            <Col xs={4} className="d-flex align-items-center p-0">
              <p className="m-0">{translate("account.status")}</p>
            </Col>
            <Col className="d-flex align-items-center p-0">
              <p className="m-0 p-1 border rounded w-100">{Status[plugin?.status ?? 2]}</p>
            </Col>
          </Row>
        </Container>
      </div>
      {plugin && (
        <>
          <ul className="nav nav-pills flex-column mt-3">
            <li>
              <h2
                className="nav-link cursor-pointer m-0 fs-6"
                style={isVersionsRoute ? {} : buttonStyle}
                onClick={() => navigateToPlugin(plugin)}
              >
                {translate("plugins.appDetails")}
              </h2>
              <h2
                className="nav-link cursor-pointer m-0 fs-6"
                style={isVersionsRoute ? buttonStyle : {}}
                onClick={() => navigateToVersions(plugin.id)}
              >
                {translate("versions.appVersions")}
              </h2>
            </li>
          </ul>
          {isVersionsRoute && !!versions?.length && <VersionsSidebarList versions={versions} />}
        </>
      )}
    </>
  );
};
