import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useLogs } from "./hooks/useLogs";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginLogsFilterActionType } from "./models/PluginLogsFilterActionType";
import { PluginLogsFilters } from "./models/PluginLogsFilters";

type LogsToolbarProps = {
  filters: PluginLogsFilters;
  handleFiltersChange: (action: PluginLogsFilterActionType, value?: string) => void;
};

export const LogsToolbar = ({ filters, handleFiltersChange }: LogsToolbarProps) => {
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { handleClearAll } = useLogs();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;
  const { translate } = useLocalization();

  return (
    <Container>
      <Row>
        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
          <p className="m-0 me-2">{translate("common.from")}</p>
          <Form.Control
            type="date"
            value={filters.FromDate}
            aria-label={translate("plugins.searchDateFrom")}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetFromDate, e.target.value)}
          />
        </Col>
        <Col xs={12} md={6} className="d-flex align-items-center mb-3">
          <p className="m-0 me-2">{translate("common.to")}</p>
          <Form.Control
            type="date"
            value={filters.ToDate}
            aria-label={translate("plugins.searchDateTo")}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetToDate, e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          md={isSystemAdmin ? 9 : 12}
          className="d-flex align-items-center justify-content-center flex-grow-1"
        >
          <Form.Label htmlFor="search" className="text-nowrap m-0 me-2">
            {translate("plugins.searchLabel")}
          </Form.Label>
          <Form.Control
            id="search"
            className="rounded-start"
            placeholder={translate("plugins.search")}
            value={filters.Query}
            onChange={(e) => handleFiltersChange(PluginLogsFilterActionType.SetQuery, e.target.value)}
          />
        </Col>
        {isSystemAdmin && (
          <Col xs={12} md={3} className="d-flex justify-content-md-end mt-3 m-md-0">
            <Button variant="success" onClick={handleClearAll} style={buttonStyle}>
              {translate("common.clearLogs")}
            </Button>
          </Col>
        )}
      </Row>
    </Container>
  );
};
