import { Button, Col } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useTheme } from "../../contexts/ThemeContext";
import { NotificationFilters } from "./models/NotificationFilters";
import { NotificationsFilterActionType } from "./models/NotificationsFilterActionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FilterTagsBuilder } from "./models/FilterTagsBuilder";

type AppliedFiltersProps = {
  filters: NotificationFilters;
  handleFiltersChange: (action: NotificationsFilterActionType, value?: string) => void;
};

export const AppliedFilters = ({ filters, handleFiltersChange }: AppliedFiltersProps) => {
  const { theme, buttonStyle } = useTheme();
  const { translate } = useLocalization();
  const tags = new FilterTagsBuilder(filters).getAll(translate);

  return (
    <Col xs={12} className="d-flex">
      {tags.some((x) => x.value !== "") && (
        <Button
          style={buttonStyle}
          className="me-2 py-0 text-white"
          onClick={() => handleFiltersChange(NotificationsFilterActionType.ClearAll)}
        >
          <FontAwesomeIcon className="me-2" icon={faTimesCircle} />
          {translate("common.clearAllFilters")}
        </Button>
      )}
      {tags
        .filter((x) => x.value !== "")
        .map((x, i) => (
          <Button
            key={i}
            style={buttonStyle}
            className="me-2 py-0 text-white"
            onClick={() => handleFiltersChange(NotificationsFilterActionType.ClearAll)}
          >
            <FontAwesomeIcon className="me-2" icon={faTimesCircle} />
            <span aria-label={translate("common.removeFilter")} />
            {`${x.type}: ${x.value}`}
          </Button>
        ))}
    </Col>
  );
};
