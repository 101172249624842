import { Button, Col, Container, Row } from "react-bootstrap";
import { useUser } from "../../contexts/UserContext";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Unassigned = () => {
  const { logout } = useUser();
  const { translate } = useLocalization();

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={11} md={8} lg={6} className="rounded p-5 bg-light">
          <h3 className="text-start">{translate("common.warning")}</h3>
          <hr aria-hidden />
          <p className="fw-500 text-secondary">{translate("account.notAssigned")}</p>
          <Button onClick={() => logout()} variant="outline-secondary">
            {translate("account.logout")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
