import { useContext } from "react";
import { Col } from "react-bootstrap";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { SidebarItem } from "./models/SidebarItem";
import { usePreserve } from "../../../contexts/PreserveContext";
import { useLocation, useNavigate } from "react-router-dom";
import { MatchAlgorithm } from "./models/MatchAlgorithm";
import { useLocalization } from "../../../contexts/LocalizationContext";

type SideNavbarProps = { items: SidebarItem[]; matchAlgorithm: MatchAlgorithm };

export const SideNavbar = ({ items, matchAlgorithm }: SideNavbarProps) => {
  const { buttonStyle } = useContext(ThemeContext);
  const { safeExecute } = usePreserve();
  const { pathname } = useLocation();
  const { translate } = useLocalization();
  const navigate = useNavigate();

  const isCurrentRoute = (route: string) => {
    switch (matchAlgorithm) {
      case MatchAlgorithm.Equals:
        return pathname === route;
      case MatchAlgorithm.Includes:
        return pathname.includes(route);
      default:
        return false;
    }
  };

  return (
    <Col xs={12} xl={3} className="border-none border-xl-end">
      <ul className="nav nav-pills flex-column">
        {items.map((x) => (
          <li key={x.name} className="nav-item">
            <button
              className="nav-link cursor-pointer option m-0 w-100 text-start"
              onClick={() => safeExecute(() => navigate(x.route))}
              style={isCurrentRoute(x.route) ? buttonStyle : {}}
            >
              {x.name}
              <span aria-label={translate("notification.view")} />
            </button>
          </li>
        ))}
      </ul>
    </Col>
  );
};
