import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useCategories } from "./contexts/CategoriesContext";
import { CategoryDetails } from "./models/CategoryDetails";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type CategoriesTableEditableRowProps = {
  index: number;
  handleSave: () => void;
};

export const CategoriesTableEditableRow = ({ index, handleSave }: CategoriesTableEditableRowProps) => {
  const formikContext = useFormikContext<CategoryDetails>();
  const { theme } = useTheme();
  const { onEditModeIdChange } = useCategories();
  const { values, touched, errors, handleChange } = formikContext;
  const { safeExecute } = usePreserve();
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border">
        <FormControl type="text" name="name" size="sm" value={values.name} onChange={handleChange} />
        {touched.name && !!errors.name && <span className="invalid-feedback d-inline">{errors.name}</span>}
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.save")}</Tooltip>}>
          <Button variant="transparent" className="p-0" onClick={handleSave} aria-label={translate("common.save")}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.backgroundColor }} />
          </Button>
        </OverlayTrigger>
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.discard")}</Tooltip>}>
          <Button
            className="p-0"
            variant="transparent"
            aria-label={translate("common.discard")}
            onClick={() => safeExecute(() => onEditModeIdChange(-1))}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
