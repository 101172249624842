import { useLocation, useParams } from "react-router-dom";
import { useVersion } from "./contexts/VersionContext";
import { publishedStatusMap } from "./models/StatusHelper";
import { Status } from "./models/Status";
import { NavigationTab } from "./NavigationTab";
import { routes } from "../../main/routes";

export const PluginVersionTabs = () => {
  const { version } = useVersion();
  const { pathname } = useLocation();
  const { pluginId, versionId } = useParams() as { pluginId: string; versionId: string };
  const { status, publishedPluginVersion, unpublishedPluginVersion } = version;
  const isDetailsRoute = pathname === routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.Published);
  const isPendingRoute = pathname === routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.InReview);
  const isDraftRoute = pathname === routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.InComplete);
  const isCommentsRoute = pathname === routes.versions.comments(parseInt(pluginId), parseInt(versionId));
  const showPublished = publishedStatusMap.get(status) ?? publishedStatusMap.get(publishedPluginVersion?.status);
  const showPending = status === Status.InReview || unpublishedPluginVersion?.status === Status.InReview;
  const showDraft = status === Status.InComplete || unpublishedPluginVersion?.status === Status.InComplete;

  return (
    <ul className="nav nav-tabs position-relative mb-3">
      {showPublished && (
        <NavigationTab
          isSelected={isDetailsRoute}
          name="Details"
          route={routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.Published)}
        />
      )}
      {showPending && (
        <NavigationTab
          isSelected={isPendingRoute}
          name="Pending"
          route={routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.InReview)}
        />
      )}
      {showDraft && (
        <NavigationTab
          isSelected={isDraftRoute}
          name="Draft"
          route={routes.versions.update(parseInt(pluginId), parseInt(versionId), Status.InComplete)}
        />
      )}
      <NavigationTab
        isSelected={isCommentsRoute}
        name="Comments"
        route={routes.versions.comments(parseInt(pluginId), parseInt(versionId))}
      />
    </ul>
  );
};
