import { Container, Row } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PushNotificationMini } from "./models/PushNotificationMini";
import { AdminNotification } from "./AdminNotification";

type NotificationsListProps = { list: PushNotificationMini[] };

export const AdminNotificationsList = ({ list }: NotificationsListProps) => {
  const { translate } = useLocalization();

  return (
    <Container>
      <Row style={{ overflowY: "auto", maxHeight: 600 }}>
        <div className="p-0">
          {list.length > 0 ? (
            list.map((x, i) => (
              <>
                <AdminNotification key={i} notification={x} />
                {i !== list.length - 1 && <hr className="m-0" aria-hidden />}
              </>
            ))
          ) : (
            <div className="p-2">{translate("notification.noNotifications")}</div>
          )}
        </div>
      </Row>
    </Container>
  );
};
