import { Form, FormControlProps } from "react-bootstrap";
import { SelectItem } from "./models/SelectItem";
import { forwardRef } from "react";
import { useLocalization } from "../../../contexts/LocalizationContext";

type DatalistProps = FormControlProps & { items: SelectItem[]; name: string };

const Component = ({ items, value, ...props }: DatalistProps, ref: React.Ref<HTMLInputElement>) => {
  const { translate } = useLocalization();

  return (
    <>
      <Form.Control
        {...props}
        ref={ref}
        size="sm"
        list="datalistOptions"
        placeholder={translate("plugins.typeToSearch")}
        autoComplete="off"
      />
      <datalist id="datalistOptions" defaultValue={value}>
        {items.map((x) => (
          <option key={x.value} value={x.text} />
        ))}
      </datalist>
    </>
  );
};

export const Datalist = forwardRef(Component);
