import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { Button, ButtonProps, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

type DeleteButtonProps = ButtonProps & {
  hasIcon?: boolean;
  showOverlay?: boolean;
};

const Component = ({ hasIcon, showOverlay, ...props }: DeleteButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { translate } = useLocalization();
  const button = (
    <Button {...props} ref={ref} aria-label={translate("common.delete")} variant="transparent" className="p-0 border-0">
      <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
    </Button>
  );

  if (hasIcon) {
    return showOverlay ? (
      <OverlayTrigger overlay={<Tooltip>{translate("common.delete")}</Tooltip>}>{button}</OverlayTrigger>
    ) : (
      button
    );
  }

  return (
    <Button ref={ref} {...props} variant="danger">
      {translate("common.delete")}
    </Button>
  );
};

export const DeleteButton = forwardRef(Component);
