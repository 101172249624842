import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useOutsideClick } from "../../hooks/useOutsideClick";

type SearchableTableHeadProps = {
  name: string;
  value: string;
  setSearch: (value: string) => void;
};

export const SearchableTableHead = ({ name, value, setSearch }: SearchableTableHeadProps) => {
  const [showInput, setShowInput] = useState(false);
  const wrapper = useRef<HTMLInputElement>(null);
  useOutsideClick(wrapper, () => setShowInput(false));

  return showInput ? (
    <Form.Control
      ref={wrapper}
      size="sm"
      value={value}
      placeholder={name}
      onChange={(e) => setSearch(e.target.value)}
    />
  ) : (
    <p onDoubleClick={() => setShowInput((prev) => !prev)} className="m-0">
      {name}
    </p>
  );
};
