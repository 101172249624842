import React, { HTMLProps } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type PaginationProps = Omit<HTMLProps<HTMLUListElement>, "onClick"> & {
  currentPage: number;
  totalPages: number;
  onClick: (value: number) => void;
};

export const Pagination = ({ currentPage, totalPages, onClick }: PaginationProps) => {
  const { theme } = useTheme();
  const { translate } = useLocalization();

  const paginationItemStyleActive: React.CSSProperties = {
    backgroundColor: theme?.backgroundColor,
    borderColor: theme?.backgroundColor,
    color: theme?.foregroundColor,
    cursor: "pointer"
  };

  const paginationItemStyleInactive: React.CSSProperties = {
    color: theme?.backgroundColor,
    cursor: "pointer"
  };

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <BootstrapPagination className="m-0 flex-wrap">
      <BootstrapPagination.First
        linkStyle={paginationItemStyleInactive}
        disabled={currentPage === 1}
        onClick={() => onClick(1)}
        aria-label={translate("plugins.pagination.firstPage")}
      />
      <BootstrapPagination.Prev
        linkStyle={paginationItemStyleInactive}
        disabled={currentPage === 1}
        onClick={() => onClick(currentPage - 1)}
        aria-label={translate("plugins.pagination.previousPage")}
      />
      {pages.map((page) => (
        <BootstrapPagination.Item
          key={page}
          onClick={() => onClick(page)}
          active={currentPage === page}
          linkStyle={currentPage === page ? paginationItemStyleActive : paginationItemStyleInactive}
        >
          <span aria-label={translate("plugins.pagination.pageNumber")} />
          {page}
        </BootstrapPagination.Item>
      ))}
      <BootstrapPagination.Next
        linkStyle={paginationItemStyleInactive}
        disabled={currentPage === totalPages}
        onClick={() => onClick(currentPage + 1)}
        aria-label={translate("plugins.pagination.nextPage")}
      />
      <BootstrapPagination.Last
        linkStyle={paginationItemStyleInactive}
        disabled={currentPage === totalPages}
        onClick={() => onClick(totalPages)}
        aria-label={translate("plugins.pagination.lastPage")}
      />
    </BootstrapPagination>
  );
};
