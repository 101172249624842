import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

const maxVersionPartNumber = 2147483647;

export const versionValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    versionNumber: Yup.string()
      .required(translate("validation.fieldRequired"))
      .matches(/^(\d+\.)(\d+\.)(\d+\.)(\d+)$/, translate("validation.invalidVersionNumber"))
      .test("parts-in-range", translate("validation.invalidVersionNumber"), function (value) {
        const parts = value.split(".").map(Number);
        return parts.every((x) => x <= maxVersionPartNumber);
      }),
    versionUpload: Yup.mixed().when(["validateVersionUpload", "versionUploadRequired"], {
      is: true,
      then: (schema) => schema.required(translate("validation.fieldRequired")),
      otherwise: (schema) => schema.notRequired()
    }),
    downloadUrl: Yup.string().when("validateVersionUpload", {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: () =>
        Yup.string().required(translate("validation.fieldRequired")).url(translate("validation.invalidURL"))
    }),
    minimumRequiredVersionOfStudio: Yup.string().when("validateVersionBoundary", {
      is: true,
      then: () =>
        Yup.string()
          .required(translate("validation.fieldRequired"))
          .matches(/^(\d+\.)(\d+\.)(\d+)$/, translate("validation.invalidVersionNumber"))
          .test("parts-in-range", translate("validation.invalidVersionNumber"), function (value) {
            const parts = value.split(".").map(Number);
            return parts.every((x) => x <= maxVersionPartNumber);
          }),
      otherwise: (schema) => schema.notRequired()
    }),
    maximumRequiredVersionOfStudio: Yup.string().when("validateVersionBoundary", {
      is: true,
      then: () =>
        Yup.string()
          .required(translate("validation.fieldRequired"))
          .matches(/^(\d+\.)(\d+\.)(\d+)$/, translate("validation.invalidVersionNumber"))
          .test("parts-in-range", translate("validation.invalidVersionNumber"), function (value) {
            const parts = value.split(".").map(Number);
            return parts.every((x) => x <= maxVersionPartNumber);
          }),
      otherwise: (schema) => schema.notRequired()
    }),
    fileHash: Yup.string().when("validateVersionUpload", {
      is: true,
      then: () =>
        Yup.string()
          .required(translate("validation.fieldRequired"))
          .matches(/\b(?:[0-9a-z]-?){64}\b/, translate("validation.invalidCheckSum")),
      otherwise: (schema) => schema.notRequired()
    }),
    supportedProducts: Yup.array().min(1, translate("validation.productVersion")),
    validateVersionUpload: Yup.boolean(),
    versionUploadRequired: Yup.boolean(),
    validateVersionBoundary: Yup.boolean(),
    markdownChangelog: Yup.string()
  });
