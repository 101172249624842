import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, HTMLAttributes } from "react";
import { Button } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

const Component = (props: HTMLAttributes<HTMLElement>, ref: React.Ref<HTMLButtonElement>) => {
  const { translate } = useLocalization();

  return (
    <Button
      {...props}
      ref={ref}
      variant="transparent"
      aria-label={translate("profile.profile")}
      className="border-0 p-0 text-white"
    >
      <FontAwesomeIcon icon={faUserCircle} />
    </Button>
  );
};

export const ProfileToggleButton = forwardRef(Component);
