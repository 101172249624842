import { SearchableTableHead } from "./SearchableTableHead";
import { SortType } from "./model/SortType";
import { SortableTableHead } from "./SortableTableHead";

type TableHeadProps = {
  name: string;
  value: string;
  sortType?: SortType;
  setSortType: (type: SortType) => void;
  setSearch: (value: string) => void;
};

export const TableHead = ({ name, value, sortType, setSortType, setSearch }: TableHeadProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <SearchableTableHead value={value} name={name} setSearch={setSearch} />
      <SortableTableHead sortType={sortType} setSortType={setSortType} />
    </div>
  );
};
