import { Switch } from "../common/inputs/Switch";
import { useFormikContext } from "formik";
import { AccountSettings } from "../identity/models/AccountSettings";
import { Row } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Select } from "../common/inputs/Select";

const deleteNotificationTime = () => [
  { value: "30", text: "30 days" },
  { value: "60", text: "60 days" },
  { value: "90", text: "90 days" }
];

export const DefaultSettings = () => {
  const { initialValues, values, handleChange, setFieldValue } = useFormikContext<AccountSettings>();
  const { translate } = useLocalization();

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, opositeField: keyof AccountSettings) => {
    const { checked, name } = e.target;
    setFieldValue(name, checked);
    setFieldValue(opositeField, !checked);
  };

  return (
    <Row>
      <div className="mb-3">
        <Switch
          checked={values?.includeChangelogOnShare}
          name="includeChangelogOnShare"
          onChange={handleChange}
          label={translate("settings.includeChangelog")}
        />
      </div>
      <div className="mb-3">
        <Switch
          checked={values?.publishToRWSAppStore}
          name="publishToRWSAppStore"
          onChange={(e) => handleFieldChange(e, "publishToPrivateAppStore")}
          label={translate("plugins.publishToRWSAppStore")}
        />
      </div>
      <div className="mb-3">
        <Switch
          checked={values?.publishToPrivateAppStore}
          disabled={!values.enablePrivateAppStore}
          name="publishToPrivateAppStore"
          onChange={(e) => handleFieldChange(e, "publishToRWSAppStore")}
          label={translate("plugins.publishToPrivateAppStore")}
        />
      </div>
      <div className="mb-3">
        <p>{translate("notification.currentExpiryDays", { expiryDays: initialValues.expiryDays.toString() })}</p>
        <Select
          style={{ width: "110px" }}
          onChange={(e) => setFieldValue("expiryDays", parseInt(e.target.value))}
          value={values.expiryDays}
          id="notificationsStatus"
          aria-label={translate("notification.selectDays")}
          items={deleteNotificationTime()}
        />
      </div>
    </Row>
  );
};
