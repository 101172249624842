import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginsExportType } from "../plugins/models/PluginsExportType";
import { ExportButton } from "./ExportButton";

export const Export = () => {
  const { translate } = useLocalization();

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3>{translate("settings.exportPlugins")}</h3>
      <div className="d-flex">
        <div className="me-2">
          <ExportButton name={translate("settings.exportToExcel")} exportType={PluginsExportType.Xlsx} />
        </div>
        <div>
          <ExportButton name={translate("settings.exportToJson")} exportType={PluginsExportType.Json} />
        </div>
      </div>
    </>
  );
};
