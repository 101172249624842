import { AccountsFilter } from "./AccountsFilter";
import { AccountsFilterActionType } from "./AccountsFilterActionType";
import { SortType } from "./SortType";
import { FilterTag } from "./FilterTag";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export class FilterTagsBuilder {
  private filters: AccountsFilter;

  constructor(filters: AccountsFilter) {
    this.filters = filters;
  }

  public getAll(translate: TranslateFunction): FilterTag[] {
    return [
      ...this.getArrayTags(
        this.filters.Categories,
        AccountsFilterActionType.UnsetCategory,
        translate("settings.category")
      ),
      ...this.getArrayTags(this.filters.Products, AccountsFilterActionType.UnsetProduct, translate("plugins.product")),
      ...this.getArrayTags(this.filters.Pricing, AccountsFilterActionType.UnsetPricing, translate("plugins.pricing")),
      ...this.getArrayTags(
        this.filters.AppStatus,
        AccountsFilterActionType.UnsetAppStatus,
        translate("plugins.appStatus")
      ),
      ...this.getArrayTags(
        this.filters.AccountStatus,
        AccountsFilterActionType.UnsetAccountStatus,
        translate("plugins.accountStatus")
      ),
      {
        value: this.filters.AccountName,
        action: AccountsFilterActionType.UnsetAccountName,
        type: translate("account.accountName")
      },
      {
        value: this.filters.AccountAlias,
        action: AccountsFilterActionType.UnsetAccountAlias,
        type: translate("account.accountAlias")
      },
      {
        value: this.filters.AppName,
        action: AccountsFilterActionType.UnsetAppName,
        type: translate("plugins.appName")
      },
      { value: this.filters.User, action: AccountsFilterActionType.UnsetUser, type: translate("account.user") },
      {
        value: this.getSortTypeName(this.filters.AccountNameSort),
        action: AccountsFilterActionType.UnsetAccountNameSort,
        type: translate("account.accountNameSort")
      },
      {
        value: this.getSortTypeName(this.filters.AccountAliasSort),
        action: AccountsFilterActionType.UnsetAccountAliasSort,
        type: translate("account.accountAliasSort")
      },
      {
        value: this.getSortTypeName(this.filters.CreatedDateSort),
        action: AccountsFilterActionType.UnsetCreatedDateSort,
        type: translate("account.createdDateSort")
      },
      {
        value: this.getSortTypeName(this.filters.RecentActionSort),
        action: AccountsFilterActionType.UnsetRecentActionSort,
        type: translate("account.recentActionSort")
      }
    ];
  }

  private getArrayTags(items: string[], action: AccountsFilterActionType, type: string): FilterTag[] {
    return items.map((x) => ({ value: x, action, type }));
  }

  private getSortTypeName(sortType?: SortType) {
    return sortType ? (sortType === "asc" ? "Ascending" : "Descending") : "";
  }
}
