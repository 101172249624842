import { PluginsFilterActionType } from "./PluginsFilterActionType";
import { PluginFilterTag } from "./PluginFilterTag";
import { PluginsFilters } from "./PluginsFilters";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export class PluginFilterTagsBuilder {
  private filters: PluginsFilters;

  constructor(filters: PluginsFilters) {
    this.filters = filters;
  }

  public getAll(translate: TranslateFunction): PluginFilterTag[] {
    return [
      { value: this.filters.Status, action: PluginsFilterActionType.UnsetStatus, type: translate("plugins.status") },
      { value: this.filters.Query, action: PluginsFilterActionType.UnsetQuery, type: translate("common.query") },
      { value: this.filters.Product, action: PluginsFilterActionType.UnsetProduct, type: translate("plugins.product") }
    ];
  }
}
