import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useProducts } from "./contexts/ProductsContext";
import { ProductDetails } from "./models/ProductDetails";
import { useResize } from "../../hooks/useResize";
import { faCheckCircle, faPenAlt, faTimesCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type ProductsTableReadonlyRowProps = {
  item: ProductDetails;
  index: number;
  onDelete: () => void;
};

export const ProductsTableReadonlyRow = ({ item, index, onDelete }: ProductsTableReadonlyRowProps) => {
  const { theme } = useTheme();
  const { onEditModeIdChange } = useProducts();
  const { isExpanded, onToggle } = useResize();
  const { safeExecute } = usePreserve();
  const { translate } = useLocalization();

  const handleEdit = (id: number) => safeExecute(() => onEditModeIdChange(id));

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border fw-bold fw-xl-normal" onClick={onToggle}>
        {item.productName}
      </td>
      {isExpanded && (
        <>
          <td className="border">{item.minimumStudioVersion}</td>
          <td className="border">{item.parentProductID}</td>
          <td className="border text-center">
            {item.isLegacy ? (
              <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.backgroundColor }} />
            ) : (
              <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
            )}
          </td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.edit")}</Tooltip>}>
              <Button
                variant="transparent"
                className="p-0"
                aria-label={translate("common.edit")}
                onClick={() => handleEdit(item.id)}
              >
                <FontAwesomeIcon icon={faPenAlt} className="text-secondary" />
              </Button>
            </OverlayTrigger>
          </td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.delete")}</Tooltip>}>
              <Button variant="transparent" className="p-0" aria-label={translate("common.delete")} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
              </Button>
            </OverlayTrigger>
          </td>
        </>
      )}
    </tr>
  );
};
