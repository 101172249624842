import { useLocation } from "react-router-dom";
import { usePlugin } from "./contexts/PluginContext";
import { NavigationTab } from "./NavigationTab";
import { publishedStatusMap } from "./models/StatusHelper";
import { Status } from "./models/Status";
import { routes } from "../../main/routes";

export const PluginDetailsTabs = () => {
  const { pluginDetails: plugin } = usePlugin();
  const { pathname } = useLocation();
  const { status, publishedPluginDetails, unpublishedPluginDetails } = plugin;
  const isDetailsRoute = pathname === routes.plugins.update(plugin.id, Status.Published);
  const isPendingRoute = pathname === routes.plugins.update(plugin.id, Status.InReview);
  const isDraftRoute = pathname === routes.plugins.update(plugin.id, Status.InComplete);
  const isCommentsRoute = pathname === routes.plugins.comments(plugin.id);
  const isLogsRoute = pathname === routes.plugins.logs(plugin.id);
  const showPublished = publishedStatusMap.get(status) || publishedStatusMap.get(publishedPluginDetails?.status);
  const showPending = status === Status.InReview || unpublishedPluginDetails?.status === Status.InReview;
  const showDraft = status === Status.InComplete || unpublishedPluginDetails?.status === Status.InComplete;

  return (
    <ul className="nav nav-tabs position-relative mb-3">
      {showPublished && (
        <NavigationTab
          isSelected={isDetailsRoute}
          name="Details"
          route={routes.plugins.update(plugin.id, Status.Published)}
        />
      )}
      {showPending && (
        <NavigationTab
          isSelected={isPendingRoute}
          name="Pending"
          route={routes.plugins.update(plugin.id, Status.InReview)}
        />
      )}
      {showDraft && (
        <NavigationTab
          isSelected={isDraftRoute}
          name="Draft"
          route={routes.plugins.update(plugin.id, Status.InComplete)}
        />
      )}
      <NavigationTab isSelected={isCommentsRoute} name="Comments" route={routes.plugins.comments(plugin.id)} />
      <NavigationTab isSelected={isLogsRoute} name="Logs" route={routes.plugins.logs(plugin.id)} />
    </ul>
  );
};
