import { Tokens, marked } from "marked";
import { TranslateFunction } from "../../../contexts/LocalizationContext";
import * as Yup from "yup";

const requiredDocumentationHeaders = ["Introduction", "Key Features", "Technical Requirements"];

export const markdownContentValidator = (translate: TranslateFunction, isDocumentation = false) => {
  let validator = Yup.string()
    .required(translate("validation.fieldRequired"))
    .min(20, translate("validation.minimumCharacters"))
    .test("has-below-max-size", translate("validation.markdownFileSizeExceeded"), function (value) {
      return new TextEncoder().encode(value).length <= 200 * 1024 * 1024;
    });

  if (isDocumentation) {
    validator = validator.test("has-required-headers", translate("validation.markdownMissingHeaders"), (value) => {
      if (!value) return false;
      const tokens: Tokens.Generic[] = marked.lexer(value);
      const headers = tokens.filter(({ type }) => type === "heading").map(({ text }) => text.toLowerCase());
      return requiredDocumentationHeaders.every((header) => headers.includes(header.toLowerCase()));
    });
  }

  return validator;
};

export const resourceTypeAndSizeValidator = (isRequired: boolean, translate: TranslateFunction) => {
  const schema = Yup.mixed()
    .test("fileType", translate("validation.invalidFileFormat"), resourceTypeValidation)
    .test("fileSize", translate("validation.imageSizeExceeded"), resourceSizeValidator);

  return isRequired ? schema.required(translate("validation.fieldRequired")) : schema.notRequired();
};

const resourceTypeValidation = (value: unknown) => {
  if (!(value instanceof Blob)) return true;
  const supportedFormats = ["image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp", "image/webp"];
  return supportedFormats.includes(value.type);
};

const resourceSizeValidator = (value: unknown) => {
  if (!(value instanceof Blob)) return true;
  return value.size <= 10 * 1024 * 1024;
};
