import { RouteObject } from "react-router";
import { Layout } from "../components/layout/Layout";
import { NotFound } from "../pages/NotFound";
import { Administration } from "../pages/Administration";
import { Protected } from "../components/identity/Protected";
import { PluginsContextProvider } from "../components/plugins/contexts/PluginsContext";
import { Plugins } from "../pages/Plugins/Plugins";
import { PluginContextProvider } from "../components/plugins/contexts/PluginContext";
import { Layout as AccountLayout } from "../components/accountsettings/Layout";
import { Layout as ProfileLayout } from "../components/identity/Layout";
import { Accounts } from "../pages/Accounts";
import { Agreement } from "../pages/Agreement";
import { CreatePlugin } from "../pages/Plugins/CreatePlugin";
import { VersionContextProvider } from "../components/plugins/contexts/VersionContext";
import { PluginDetails } from "../pages/Plugins/PluginDetails";
import { PluginComments } from "../pages/Plugins/PluginComments";
import { PluginVersions } from "../pages/Versions/PluginVersions";
import { CreateVersion } from "../pages/Versions/CreateVersion";
import { PluginLogs } from "../pages/Plugins/PluginLogs";
import { VersionDetails } from "../pages/Versions/VersionDetails";
import { VersionComments } from "../pages/Versions/VersionComments";
import { DraftPlugin } from "../pages/Plugins/DraftPlugin";
import { DraftVersion } from "../pages/Versions/DraftVersion";
import { PendingPlugin } from "../pages/Plugins/PendingPlugin";
import { PendingVersion } from "../pages/Versions/PendingVersion";
import { Account } from "../components/accountsettings/Account";
import { Users } from "../components/accountsettings/Users";
import { Settings } from "../components/accountsettings/Settings";
import { Export } from "../components/accountsettings/Export";
import { NameMappingsContextProvider } from "../components/accountsettings/contexts/NameMappingsContext";
import { NameMappings } from "../components/accountsettings/NameMappings";
import { CategoriesContextProvider } from "../components/applicationsettings/contexts/CategoriesContext";
import { Categories } from "../components/applicationsettings/Categories";
import { ProductsLayout } from "../components/applicationsettings/ProductsLayout";
import { Layout as ApplicationSettingsLayout } from "../components/applicationsettings/Layout";
import { UserProfile } from "../components/identity/UserProfile";
import { Accounts as ProfileAccounts } from "../components/identity/Accounts";
import { Settings as ProfileSettings } from "../components/identity/Settings";
import { ProductsContextProvider } from "../components/applicationsettings/contexts/ProductsContext";
import { Products } from "../components/applicationsettings/Products";
import { ParentProductsContextProvider } from "../components/applicationsettings/contexts/ParentProductsContext";
import { ParentProducts } from "../components/applicationsettings/ParentProducts";
import { UserProfile as UserProfileModel } from "../components/identity/models/UserProfile";

const adminRoutes: RouteObject[] = [
  {
    path: "settings",
    element: <Settings />
  },
  {
    path: "export",
    element: <Export />
  },
  {
    path: "names",
    element: (
      <NameMappingsContextProvider>
        <NameMappings />
      </NameMappingsContextProvider>
    )
  }
];

export const getRoutes = (userProfile?: UserProfileModel): RouteObject[] => [
  {
    path: "",
    element: <Layout />,
    errorElement: (
      <Layout>
        <NotFound />
      </Layout>
    ),
    children: [
      {
        errorElement: <NotFound />,
        children: [
          {
            index: true,
            path: "/",
            element: (
              <Protected checkAccount checkAgreement>
                <PluginsContextProvider>
                  <Plugins />
                </PluginsContextProvider>
              </Protected>
            )
          },
          {
            path: "/plugins/new",
            element: (
              <Protected checkAccount checkAgreement>
                <PluginContextProvider>
                  <CreatePlugin />
                </PluginContextProvider>
              </Protected>
            )
          },
          {
            path: "/plugins/:pluginId",
            errorElement: <NotFound />,
            element: (
              <Protected checkAccount checkAgreement>
                <PluginContextProvider />
              </Protected>
            ),
            children: [
              {
                errorElement: <NotFound />,
                children: [
                  {
                    path: "details",
                    element: <PluginDetails />
                  },
                  {
                    path: "draft",
                    element: <DraftPlugin />
                  },
                  {
                    path: "pending",
                    element: <PendingPlugin />
                  },
                  {
                    path: "comments",
                    element: <PluginComments />
                  },
                  {
                    path: "logs",
                    element: <PluginLogs />
                  },
                  {
                    path: "versions",
                    element: <PluginVersions />
                  },
                  {
                    path: "versions/new",
                    element: <CreateVersion />
                  },
                  {
                    path: "versions/:versionId",
                    element: <VersionContextProvider />,
                    errorElement: <NotFound />,
                    children: [
                      {
                        errorElement: <NotFound />,
                        children: [
                          {
                            path: "details",
                            element: <VersionDetails />
                          },
                          {
                            path: "pending",
                            element: <PendingVersion />
                          },
                          {
                            path: "draft",
                            element: <DraftVersion />
                          },
                          {
                            path: "comments",
                            element: <VersionComments />
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: "/settings/account",
            element: (
              <Protected checkAccount checkAgreement>
                <AccountLayout />
              </Protected>
            ),
            errorElement: <NotFound />,
            children: [
              {
                errorElement: <NotFound />,
                children: [
                  {
                    path: "",
                    element: <Account />
                  },
                  {
                    path: "users",
                    element: <Users />
                  },
                  ...(userProfile?.isAdmin ? adminRoutes : [])
                ]
              }
            ]
          },
          {
            path: "/profile",
            errorElement: <NotFound />,
            element: (
              <Protected checkAccount checkAgreement>
                <ProfileLayout />
              </Protected>
            ),
            children: [
              {
                errorElement: <NotFound />,
                children: [
                  {
                    path: "",
                    element: <UserProfile />
                  },
                  {
                    path: "accounts",
                    element: <ProfileAccounts />
                  },
                  {
                    path: "settings",
                    element: <ProfileSettings />
                  }
                ]
              }
            ]
          },
          {
            path: "/accounts",
            element: (
              <Protected>
                <Accounts />
              </Protected>
            )
          },
          {
            path: "/agreement",
            element: (
              <Protected checkAccount>
                <Agreement />
              </Protected>
            )
          }
        ]
      }
    ]
  }
];

export const systemAdminRoutes: RouteObject = {
  path: "administration",
  element: <Layout isAdminLayout />,
  errorElement: (
    <Layout>
      <NotFound />
    </Layout>
  ),
  children: [
    {
      path: "",
      element: <Administration />
    },
    {
      path: "settings",
      errorElement: <NotFound />,
      element: (
        <Protected checkAccount>
          <ApplicationSettingsLayout />
        </Protected>
      ),
      children: [
        {
          errorElement: <NotFound />,
          children: [
            {
              path: "categories",
              element: (
                <CategoriesContextProvider>
                  <Categories />
                </CategoriesContextProvider>
              )
            },
            {
              path: "products",
              element: <ProductsLayout />,
              errorElement: <NotFound />,
              children: [
                {
                  errorElement: <NotFound />,
                  children: [
                    {
                      path: "",
                      element: (
                        <ProductsContextProvider>
                          <Products />
                        </ProductsContextProvider>
                      )
                    },
                    {
                      path: "parents",
                      element: (
                        <ParentProductsContextProvider>
                          <ParentProducts />
                        </ParentProductsContextProvider>
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
