import { Col, Row } from "react-bootstrap";
import { DraftVersionButtonGroup } from "../../components/plugins/DraftVersionButtonGroup";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { useTheme } from "../../contexts/ThemeContext";
import { VersionDetailsForm } from "../../components/plugins/VersionDetailsForm";
import { VersionDetailsPartial } from "../../components/plugins/VersionDetailsPartial";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { Loader } from "../../components/common/progress/Loader";
import { useLocalization } from "../../contexts/LocalizationContext";

export const CreateVersion = () => {
  const { buttonStyle } = useTheme();
  const { pluginDetails: plugin, isLoading } = usePlugin();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  return (
    <VersionDetailsForm>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <ul className="nav nav-tabs position-relative mb-3">
            <li className="nav-item">
              <p className="nav-link cursor-pointer m-0" style={buttonStyle}>
                {translate("plugins.create")}
              </p>
            </li>
          </ul>
          <VersionDetailsPartial enableFileUpload />
          <div className="mb-2">
            <DraftVersionButtonGroup />
          </div>
        </Col>
      </Row>
    </VersionDetailsForm>
  );
};
