import React, { HTMLAttributes, forwardRef } from "react";
import { Form } from "react-bootstrap";
import { useTheme } from "../../../contexts/ThemeContext";
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";

type CheckboxProps = FormCheckInputProps & {
  label?: string;
  labelProps?: HTMLAttributes<HTMLLabelElement>;
};

const Component = ({ label, labelProps, name, checked, ...props }: CheckboxProps, ref: React.Ref<HTMLInputElement>) => {
  const { theme } = useTheme();

  const checkboxStyle: React.CSSProperties = {
    backgroundColor: checked ? theme?.backgroundColor : "white",
    borderColor: checked ? theme?.backgroundColor : "lightgray",
    color: theme?.foregroundColor
  };

  return (
    <Form.Check>
      <Form.Check.Input
        {...props}
        ref={ref}
        id={name}
        name={name}
        checked={checked}
        style={checkboxStyle}
        type="checkbox"
      />
      {label && (
        <Form.Check.Label {...labelProps} htmlFor={name}>
          {label}
        </Form.Check.Label>
      )}
    </Form.Check>
  );
};

export const Checkbox = forwardRef(Component);
