import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { useTheme } from "../../contexts/ThemeContext";
import { AdminNavigation } from "./AdminNavigation";
import { Navigation } from "./Navigation";
import { useLocalization } from "../../contexts/LocalizationContext";

type LayoutProps = {
  children?: ReactNode;
  isAdminLayout?: boolean;
};

export const Layout = ({ children, isAdminLayout }: LayoutProps) => {
  const { theme } = useTheme();
  const { translate } = useLocalization();

  const year = new Date().getFullYear();
  const headerStyle: React.CSSProperties = {
    backgroundColor: theme?.backgroundColor,
    color: theme?.foregroundColor,
    padding: "7px 0px",
    marginBottom: "10px"
  };

  return (
    <>
      <header style={headerStyle}>{isAdminLayout ? <AdminNavigation /> : <Navigation />}</header>
      <Container>{children ? children : <Outlet />}</Container>
      <footer className="mt-auto border-top text-muted d-flex justify-content-center p-3">
        <div>&copy; {translate("common.yearRWS", { year: year.toString() })}</div>
      </footer>
    </>
  );
};
