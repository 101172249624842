import { useAuth0 } from "@auth0/auth0-react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useTheme } from "../contexts/ThemeContext";
import AccessDenied from "../resources/access-denied.png";
import { useLocalization } from "../contexts/LocalizationContext";

export const Forbidden = () => {
  const { logout } = useAuth0();
  const { buttonStyle } = useTheme();
  const returnTo = window.location.origin + process.env.PUBLIC_URL;
  const options = { logoutParams: { returnTo } };
  const { translate } = useLocalization();

  return (
    <Row className="mt-5">
      <Col xs={12} lg={6}>
        <Image src={AccessDenied} style={{ width: "100%" }} />
      </Col>
      <Col>
        <h1>{translate("error.accessDenied")}</h1>
        <p className="m-0 mb-3">{translate("error.noAccess")}</p>
        <Button onClick={() => logout(options)} style={buttonStyle}>
          {translate("account.logout")}
        </Button>
      </Col>
    </Row>
  );
};
