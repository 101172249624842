import { Formik, Form, useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { AccountRegister } from "../identity/models/AccountRegister";
import { registerValidationSchema } from "../applicationsettings/schemas/RegisterValidationSchema";
import { useState } from "react";
import { RegisterModal } from "./RegisterModal";
import { useLocalization } from "../../contexts/LocalizationContext";

const initialValues: AccountRegister = {
  email: "",
  name: "",
  alias: "",
  publishToRWSAppStore: false,
  publishToPrivateAppStore: false,
  enablePrivateAppStore: false
};

export const RegisterForm = () => {
  const { translate } = useLocalization();

  return (
    <Formik<AccountRegister>
      initialValues={initialValues}
      validationSchema={registerValidationSchema(translate)}
      onSubmit={() => undefined}
    >
      <Form className="w-100">
        <RegisterButton />
      </Form>
    </Formik>
  );
};

const RegisterButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { buttonStyle } = useTheme();
  const { setTouched } = useFormikContext();
  const { translate } = useLocalization();

  const handleCloseModal = () => {
    setShowModal(false);
    setTouched({});
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Button variant="success" className="w-100" style={buttonStyle} onClick={() => setShowModal(true)}>
          {translate("account.createNewAccount")}
        </Button>
      </div>
      {showModal && <RegisterModal onClose={handleCloseModal} />}
    </>
  );
};
