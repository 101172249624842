import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { usePreferences } from "./hooks/usePreferences";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Preferences = () => {
  const preferencesContext = usePreferences();
  const { values, fonts } = preferencesContext;
  const { handleClick, handleScroll, handleFieldChange } = preferencesContext;
  const { translate } = useLocalization();

  return (
    <Row className="mt-3">
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <p className="m-0">{translate("preferences.defaultThemes")}</p>
        </Col>
        <Col xs={8} className="d-flex">
          <Form.Control
            type="color"
            name="accountPreferences.backgroundColor"
            value={values.accountPreferences.backgroundColor}
            className="me-3"
            aria-label={translate("preferences.backgroundColorPicker")}
            onChange={(e) => handleFieldChange("backgroundColor", e.target.value)}
          />
          <Form.Control
            type="color"
            name="accountPreferences.foregroundColor"
            value={values.accountPreferences.foregroundColor}
            aria-label={translate("preferences.foregroundColorPicker")}
            onChange={(e) => handleFieldChange("foregroundColor", e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="accountPreferences.fontSize" className="m-0">
            {translate("preferences.fontSize")}
          </Form.Label>
        </Col>
        <Col xs={8} className="d-flex">
          <Form.Control
            type="number"
            id="accountPreferences.fontSize"
            name="accountPreferences.fontSize"
            value={values.accountPreferences.fontSize}
            onChange={(e) => handleFieldChange("fontSize", e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <p className="m-0">{translate("preferences.fontFamily")}</p>
        </Col>
        <Col xs={8} className="d-flex">
          <Dropdown className="w-100">
            <Dropdown.Toggle style={dropdownToggleStyle}>
              {values.accountPreferences.fontFamily?.length
                ? values.accountPreferences.fontFamily
                : translate("plugins.select")}
            </Dropdown.Toggle>
            <Dropdown.Menu onScroll={handleScroll} style={dropdownMenuStyle}>
              {fonts.map((x, i) => (
                <Dropdown.Item key={x} onClick={handleClick} style={i < 20 ? { fontFamily: x } : {}}>
                  {x}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Form.Group>
      <Form.Group className="d-flex mb-3 align-items-center">
        <Col xs={4}>
          <Form.Label htmlFor="accountPreferences.iconUrl" className="m-0">
            {translate("preferences.icon")}
          </Form.Label>
        </Col>
        <Col xs={8} className="d-flex">
          <Form.Control
            type="text"
            id="accountPreferences.iconUrl"
            name="accountPreferences.iconUrl"
            placeholder={translate("plugins.imageSource")}
            value={values.accountPreferences.iconUrl}
            onChange={(e) => handleFieldChange("iconUrl", e.target.value)}
          />
        </Col>
      </Form.Group>
    </Row>
  );
};

const dropdownMenuStyle: React.CSSProperties = {
  width: "100%",
  maxHeight: 200,
  overflowY: "auto"
};

const dropdownToggleStyle: React.CSSProperties = {
  borderColor: "#dee2e6",
  textAlign: "start",
  color: "black",
  backgroundColor: "white",
  width: "100%"
};
