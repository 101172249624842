import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Button, Container, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { AdminNotificationsList } from "./AdminNotificationsList";
import { useAdminNotifications } from "./hooks/useAdminNotifications";
import { useState } from "react";
import { partial_ratio } from "fuzzball";

const fuzzyScore = 85;

export const AdminNotificationsBox = () => {
  const [queryString, setQueryString] = useState<string>();
  const { isLoading, notifications } = useAdminNotifications();
  const { translate } = useLocalization();
  const filteredNotifications = queryString
    ? notifications.filter((x) => partial_ratio(x.accountName, queryString) >= fuzzyScore)
    : notifications;

  return (
    <Container fluid className="p-0">
      <Row className="p-3">
        <InputGroup>
          <FormControl
            type="text"
            id="notificationsQuery"
            onChange={(e) => setQueryString(e.target.value)}
            value={queryString}
            placeholder={translate("notification.search")}
          />
          <InputGroup.Text>
            <Button variant="transparent" className="p-0" aria-label={translate("plugins.search")}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Button>
          </InputGroup.Text>
        </InputGroup>
      </Row>
      <hr className="m-0" aria-hidden />
      {isLoading ? (
        <Row className="justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Spinner className="text-success" />
        </Row>
      ) : (
        <AdminNotificationsList list={filteredNotifications} />
      )}
    </Container>
  );
};
