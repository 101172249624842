import { useParams } from "react-router-dom";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { usePlugin } from "../contexts/PluginContext";
import { useFetch } from "../../../hooks/useFetch";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";

export const useLogs = () => {
  const { handleLoadingChange, getPlugin } = usePlugin();
  const { pushNotification, showModal } = useFeedback();
  const params = useParams();
  const { request } = useFetch();
  const { translate } = useLocalization();

  const handleClearAll = () => {
    const pluginId = parseInt(params.pluginId as string);
    const handleContinue = async () => {
      showModal(null);
      handleLoadingChange(true);
      await request({
        route: apiRoutes.logs.clearAllPluginLogs(pluginId),
        method: "DELETE",
        onSuccess: async () => {
          await getPlugin();
          pushNotification({ message: translate("plugins.removedPluginLogs"), type: "success" });
        },
        onFailure: async (response: Response) => {
          const json = await response.json();
          pushNotification({ ...json, type: "danger" });
        },
        onAny: () => handleLoadingChange(false)
      });
    };

    showModal({
      isVisible: true,
      message: translate("plugins.removeAllPluginLogs"),
      handleClose: () => showModal(null),
      handleContinue
    });
  };

  return { handleClearAll };
};
