import { faDraftingCompass, faEye, faLock, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Status } from "./Status";
import { ReviewType } from "./ReviewType";

export const inactiveStatusMap = new Map<Status, boolean>([
  [Status.NotPublished, true],
  [Status.Private, true]
]);

export const iconStatusMap = new Map<Status, IconDefinition>([
  [Status.InComplete, faDraftingCompass],
  [Status.NotPublished, faLock],
  [Status.Private, faEye],
  [Status.InReview, faEye]
]);

export const routeStatusMap = new Map<Status, string>([
  [Status.InComplete, "draft"],
  [Status.InReview, "pending"],
  [Status.Private, "details"],
  [Status.NotPublished, "details"],
  [Status.Published, "details"]
]);

export const publishedStatusMap = new Map<Status, boolean>([
  [Status.NotPublished, true],
  [Status.Private, true],
  [Status.Published, true]
]);

export const publicStatusMap = new Map<Status, boolean>([
  [Status.NotPublished, true],
  [Status.Published, true]
]);

export const reversedStatusMap = new Map<Status, Status>([
  [Status.NotPublished, Status.Published],
  [Status.Published, Status.NotPublished]
]);

export const reviewTypeStatusMap = new Map<ReviewType, Status>([
  [ReviewType.Approved, Status.Published],
  [ReviewType.Rejected, Status.InComplete]
]);
