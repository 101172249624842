import { Col, Row } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { PluginDetailsForm } from "../../components/plugins/PluginDetailsForm";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { PluginDetailsTabs } from "../../components/plugins/PluginDetailsTabs";
import { DeleteAction } from "../../components/plugins/models/DeleteAction";
import { DeletePluginButtonGroup } from "../../components/plugins/DeletePluginButtonGroup";
import { PluginDetailsPartial } from "../../components/plugins/PluginDetailsPartial";
import { DraftPluginButtonGroup } from "../../components/plugins/DraftPluginButtonGroup";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { useEffect } from "react";
import { Status } from "../../components/plugins/models/Status";
import { useNavigate } from "react-router-dom";
import { NotFound } from "../NotFound";
import { routes } from "../../main/routes";

export const DraftPlugin = () => {
  const { isLoading, pluginDetails: plugin } = usePlugin();
  const navigate = useNavigate();
  const draftPlugin = plugin?.unpublishedPluginDetails ?? plugin;

  useEffect(() => {
    if (draftPlugin && draftPlugin.status !== Status.InComplete) {
      navigate(routes.plugins.update(plugin.id, plugin.status));
    }
  }, [draftPlugin]);

  if (isLoading) return <Loader />;
  if (!plugin) return <NotFound />;

  return (
    <PluginDetailsForm plugin={draftPlugin}>
      <Row>
        <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
          <SideNavbar plugin={plugin} />
        </Col>
        <Col xs={12} lg={8}>
          <PluginDetailsTabs />
          <PluginDetailsPartial />
          <div className="d-flex mb-2">
            <DraftPluginButtonGroup />
            <DeletePluginButtonGroup {...draftPlugin} futureInstructions={DeleteAction.One} />
          </div>
        </Col>
      </Row>
    </PluginDetailsForm>
  );
};
