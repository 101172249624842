import { ThemeContextProps } from "./models/ThemeContextProps";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { useUser } from "./UserContext";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { useFetch } from "../hooks/useFetch";
import { apiRoutes } from "../apiRoutes";
import { AccountPreferences } from "../components/identity/models/AccountPreferences";

const defaultTheme: AccountPreferences = {
  backgroundColor: "#28b059",
  foregroundColor: "#ffffff",
  fontFamily: undefined,
  fontSize: 16,
  iconUrl: ""
};

type ThemeProviderProps = { children: ReactElement };

export const ThemeContext = createContext<ThemeContextProps>({
  theme: {},
  buttonStyle: {},
  isLoadingTheme: false,
  setField: () => undefined,
  restoreTheme: () => undefined
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [isLoadingTheme, setIsLoadingTheme] = useState(false);
  const [theme, setTheme] = useState(defaultTheme);
  const { user } = useAuth0<ExtendedUser>();
  const { isLoading } = useUser();
  const { request } = useFetch();
  const selectedAccountId = user?.user_metadata?.selectedAccount?.id;

  useEffect(() => {
    const getAccountPreferences = async (accountId: string) => {
      setIsLoadingTheme(true);
      await request({
        route: apiRoutes.accounts.getAccountPreferencesById(accountId),
        method: "GET",
        onSuccess: async (response) => {
          const preferences: AccountPreferences = await response.json();
          setTheme({
            backgroundColor: preferences?.backgroundColor ?? "#28b059",
            foregroundColor: preferences?.foregroundColor ?? "#ffffff",
            fontSize: preferences?.fontSize ?? 16,
            fontFamily: preferences?.fontFamily,
            iconUrl: preferences?.iconUrl
          });
        },
        onAny: () => setIsLoadingTheme(false)
      });
    };

    if (!isLoading && selectedAccountId) getAccountPreferences(selectedAccountId);
  }, [user, isLoading]);

  useEffect(() => {
    const body = document.querySelector("body") as HTMLBodyElement;
    const defaultFont = "var(--bs-font-sans-serif)";

    if (body.style) {
      body.style.fontFamily = theme.fontFamily ?? defaultFont;
      body.style.fontSize = `${theme.fontSize}px`;
    }
  }, [theme]);

  const setField = useCallback(
    (name: string, value: string) => {
      setTheme({ ...theme, [name]: value });
    },
    [setTheme, theme]
  );

  const restoreTheme = useCallback(() => {
    setTheme(defaultTheme);
  }, [setTheme]);

  const buttonStyle: React.CSSProperties = {
    backgroundColor: theme?.backgroundColor,
    border: `1px solid ${theme?.backgroundColor}`,
    color: theme?.foregroundColor
  };

  return (
    <ThemeContext.Provider value={{ theme, buttonStyle, isLoadingTheme, setField, restoreTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
