export enum PluginLogsFilterActionType {
  None = 0,
  SetQuery,
  UnsetQuery,
  SetFromDate,
  UnsetFromDate,
  SetToDate,
  UnsetToDate,
  ClearAll
}
