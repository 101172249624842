import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Select } from "../common/inputs/Select";
import { NotificationsList } from "./NotificationsList";
import { useNotifications } from "./hooks/useNotifications";
import { useState } from "react";
import { Button, Col, Container, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import { TranslateFunction, useLocalization } from "../../contexts/LocalizationContext";
import { NotificationFilters } from "./models/NotificationFilters";
import { applyFilters } from "./actions/applyFilters";
import { NotificationsFilterActionType } from "./models/NotificationsFilterActionType";
import { updateFilters } from "./actions/updateFilters";
import { AppliedFilters } from "./AppliedFilters";

const statusFilters = (translate: TranslateFunction) => [
  { value: "Published", text: translate("notification.published") },
  { value: "InComplete", text: translate("notification.inComplete") },
  { value: "InReview", text: translate("notification.inReview") },
  { value: "", text: translate("plugins.all") }
];

export const NotificationsBox = () => {
  const [filters, setFilters] = useState<NotificationFilters>(new NotificationFilters());
  const { isLoading, notifications, deleteNotifications } = useNotifications();
  const { translate } = useLocalization();
  const filtered = applyFilters(filters, notifications);

  const handleFiltersChange = (action: NotificationsFilterActionType, value?: string) => {
    setFilters(updateFilters(filters, action, value));
  };

  return (
    <Container fluid className="p-0">
      <Row className="p-3">
        <Col xs={12} className="d-flex justify-content-between">
          <Select
            className="w-50"
            id="notificationsStatus"
            aria-label={translate("notification.notificationStatusFilter")}
            value={filters.Status}
            onChange={(e) => handleFiltersChange(NotificationsFilterActionType.SetStatus, e.target.value)}
            items={statusFilters(translate)}
          />
          <InputGroup className="w-50 ms-2">
            <FormControl
              type="text"
              id="notificationsQuery"
              onChange={(e) => handleFiltersChange(NotificationsFilterActionType.SetQuery, e.target.value)}
              value={filters.Query}
              placeholder={translate("notification.search")}
            />
            <InputGroup.Text>
              <Button variant="transparent" className="p-0" aria-label={translate("plugins.search")}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="px-3 pb-3">
        <AppliedFilters filters={filters} handleFiltersChange={handleFiltersChange} />
      </Row>
      <hr className="m-0" aria-hidden />
      {isLoading ? (
        <Row className="justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Spinner className="text-success" />
        </Row>
      ) : (
        <NotificationsList notifications={filtered} deleteNotifications={deleteNotifications} />
      )}
    </Container>
  );
};
