import { LocalizationContextProps } from "./models/LocalizationContextProps";
import { TranslationTerms } from "./models/TranslationTerms";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

type Props = { children: ReactNode };

const LocalizationContext = createContext<LocalizationContextProps>({
  translate: (key: string) => key
});

export const useLocalization = () => useContext(LocalizationContext);

export const LocalizationProvider = ({ children }: Props) => {
  const [translations, setTranslations] = useState({} as TranslationTerms);

  useEffect(() => {
    const loadTranslations = async () => {
      const translations = await import("../translations/en.json");
      setTranslations(translations?.default);
    };

    loadTranslations();
  }, []);

  const translate = useCallback<TranslateFunction>(
    (key, params) => {
      const getValue = (terms: TranslationTerms | string, key: string): any => {
        const subKey = key.split(".").shift();
        const nextKey = key.substring(key.indexOf(".") + 1, key.length);

        if (typeof terms === "string") return terms;
        if (typeof terms !== "object" || !subKey) return undefined;
        return getValue(terms[subKey], nextKey);
      };

      const value = getValue(translations, key);
      const translation = typeof value === "string" ? value : key;

      return params
        ? Object.keys(params).reduce((acc, param) => {
            const regex = new RegExp(`{{${param}}}`, "g");
            return acc.replace(regex, `${params[param]}`);
          }, translation)
        : translation;
    },
    [translations]
  );

  return <LocalizationContext.Provider value={{ translate }}>{children}</LocalizationContext.Provider>;
};

export type TranslateFunction = (key: string, params?: { [key: string]: string }) => string;
