import { useFormikContext } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { useUser } from "../../contexts/UserContext";
import { Checkbox } from "../common/inputs/Checkbox";
import { UserAccount } from "./models/UserAccount";
import { useLocalization } from "../../contexts/LocalizationContext";
import { AccountBase } from "./models/AccountBase";

type AccountsListProps = { accounts: AccountBase[] };

export const AccountsList = ({ accounts }: AccountsListProps) => {
  const { values, handleChange } = useFormikContext<UserAccount>();
  const { logout } = useUser();
  const { buttonStyle, theme } = useTheme();
  const { translate } = useLocalization();

  const checkedStyle: React.CSSProperties = {
    backgroundColor: theme.backgroundColor ?? "#28b059",
    color: theme.foregroundColor ?? "white"
  };

  return (
    <Col xs={11} md={8} lg={6} className="rounded p-5 bg-light">
      <h3 className="text-start">{translate("account.chooseAccount")}</h3>
      <hr aria-hidden />
      <div className="d-flex flex-column w-100">
        {accounts?.map((account) => (
          <Form.Label key={account.id} className="w-100 cursor-pointer">
            <Form.Control
              className="d-none"
              type={"radio"}
              name="selectedAccountId"
              checked={values.selectedAccountId === account.id}
              onChange={handleChange}
              value={account?.id}
            />
            <span className="w-100 p-2 d-block" style={account.id === values.selectedAccountId ? checkedStyle : {}}>
              {account?.name}
            </span>
          </Form.Label>
        ))}
      </div>
      <hr />
      <Container fluid className="p-0">
        <Row>
          <Col md>
            <Checkbox
              name="rememberAccount"
              label={translate("account.rememberMyChoice")}
              checked={values.rememberAccount}
              onChange={handleChange}
            />
          </Col>
          <Col md className="d-flex justify-content-end">
            <Button type="submit" style={buttonStyle} className="me-2">
              {translate("account.chooseAccount")}
            </Button>
            <Button onClick={() => logout()} variant="outline-secondary">
              {translate("account.logout")}
            </Button>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};
