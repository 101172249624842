export type UserRole = {
  id: string;
  name: string;
};

export const Roles = {
  SystemAdministrator: "System Administrator",
  Administrator: "Administrator",
  Developer: "Developer"
};
