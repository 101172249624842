import { faPenAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

type EditableFieldButtonProps = {
  isDisabled?: boolean;
  name: string;
  onClick: () => void;
};

const editableFieldButtonStyle = (isDisabled: boolean) => ({
  right: -50,
  color: isDisabled ? "gray" : "red"
});

export const EditableFieldButton = ({ isDisabled, name, onClick }: EditableFieldButtonProps) => {
  const { translate } = useLocalization();

  return (
    <Button
      aria-label={translate("plugins.editField", { field: name })}
      variant="transparent"
      className="position-absolute cursor-pointer p-0"
      style={editableFieldButtonStyle(!!isDisabled)}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={isDisabled ? faPenAlt : faTimesCircle} />
    </Button>
  );
};
