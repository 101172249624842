import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Datalist } from "../common/inputs/Datalist";
import { useTheme } from "../../contexts/ThemeContext";
import { NameMapping } from "./models/NameMapping";
import { useNameMappings } from "./contexts/NameMappingsContext";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type NameMappingsTableEditableRowProps = { index: number; handleSave: () => void };

export const NameMappingsTableEditableRow = ({ index, handleSave }: NameMappingsTableEditableRowProps) => {
  const formikContext = useFormikContext<NameMapping>();
  const { theme } = useTheme();
  const { pluginDetails, onEditModeIdChange } = useNameMappings();
  const { values, touched, errors, handleChange } = formikContext;
  const { safeExecute } = usePreserve();
  const items = pluginDetails.map((x) => ({ value: `${x.id}`, text: x.name }));
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row align-middle text-center text-xl-start">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border">
        <Datalist name="oldName" value={values.oldName} onChange={handleChange} items={items} />
        {touched.oldName && !!errors.oldName && <span className="invalid-feedback d-inline">{errors.oldName}</span>}
      </td>
      <td className="border">
        <FormControl name="newName" size="sm" value={values.newName} onChange={handleChange} />
        {touched.newName && !!errors.newName && <span className="invalid-feedback d-inline">{errors.newName}</span>}
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.save")}</Tooltip>}>
          <Button
            variant="transparent"
            className="p-0"
            type="button"
            onClick={handleSave}
            aria-label={translate("common.save")}
          >
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.backgroundColor }} />
          </Button>
        </OverlayTrigger>
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.discard")}</Tooltip>}>
          <Button
            className="p-0"
            variant="transparent"
            aria-label={translate("common.discard")}
            onClick={() => safeExecute(() => onEditModeIdChange(-1))}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
