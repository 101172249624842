import { Col, Row } from "react-bootstrap";
import { SideNavbar } from "../../components/plugins/SideNavbar";
import { usePlugin } from "../../components/plugins/contexts/PluginContext";
import { Logs } from "../../components/plugins/PluginLogs";
import { PluginDetailsTabs } from "../../components/plugins/PluginDetailsTabs";
import { Loader } from "../../components/common/progress/Loader";

export const PluginLogs = () => {
  const { pluginDetails: plugin, isLoading } = usePlugin();
  const logs = plugin?.pluginDetailsLogs ?? [];

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col xs={12} lg={4} style={{ borderRight: "1px solid lightgray" }}>
        <SideNavbar plugin={plugin} />
      </Col>
      <Col xs={12} lg={8}>
        <PluginDetailsTabs />
        <Logs logs={logs} />
      </Col>
    </Row>
  );
};
