import { AppConfig } from "./models/AppConfig";
import { createContext, useContext } from "react";
import { ConfigContextProps } from "./models/ConfigContextProps";

export const ConfigContext = createContext<ConfigContextProps>({
  configuration: {} as AppConfig
});

export const useConfig = () => useContext(ConfigContext);

export const getConfiguration = async (): Promise<AppConfig> => {
  const baseUrl = process.env.PUBLIC_URL;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const response = await import(`../config/env.${environment}.json`);
  const configuration = response?.default as AppConfig;

  configuration.authDetails.authorizationParams = {
    redirect_uri: window.location.origin + baseUrl
  };

  return configuration;
};
