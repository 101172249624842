import { Button, Col } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useTheme } from "../../contexts/ThemeContext";
import { PluginLogFilterTagsBuilder } from "./models/PluginLogFilterTagsBuilder";
import { PluginLogsFilterActionType } from "./models/PluginLogsFilterActionType";
import { PluginLogsFilters } from "./models/PluginLogsFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

type AppliedPluginLogFiltersProps = {
  filters: PluginLogsFilters;
  handleFiltersChange: (action: PluginLogsFilterActionType, value?: string) => void;
};

export const AppliedPluginLogFilters = ({ filters, handleFiltersChange }: AppliedPluginLogFiltersProps) => {
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();
  const tags = new PluginLogFilterTagsBuilder(filters).getAll(translate);

  return (
    <Col xs={12} className="d-flex">
      {tags.some((x) => x.value !== "") && (
        <Button
          style={buttonStyle}
          className="me-2 py-0 text-white"
          onClick={() => handleFiltersChange(PluginLogsFilterActionType.ClearAll)}
        >
          <FontAwesomeIcon className="me-2" icon={faTimesCircle} />
          {translate("common.clearAllFilters")}
        </Button>
      )}
      {tags
        .filter((x) => x.value !== "")
        .map((x, i) => (
          <Button
            key={i}
            style={buttonStyle}
            className="me-2 py-0 text-white"
            onClick={() => handleFiltersChange(x.action, x.value)}
          >
            <FontAwesomeIcon className="me-2" icon={faTimesCircle} />
            <span aria-label={translate("common.removeFilter")} />
            {`${x.type}: ${x.value}`}
          </Button>
        ))}
    </Col>
  );
};
