import { useState } from "react";
import { PluginsToolbar } from "../../components/plugins/PluginsToolbar";
import { PaginationWrapper } from "../../components/plugins/PaginationWrapper";
import emptyResult from "../../resources/empty-result.png";
import { Image, Row, Table } from "react-bootstrap";
import { Loader } from "../../components/common/progress/Loader";
import { PluginCard } from "../../components/plugins/PluginCard";
import { PluginsFilters } from "../../components/plugins/models/PluginsFilters";
import { PluginRow } from "../../components/plugins/PluginRow";
import { usePlugins } from "../../components/plugins/contexts/PluginsContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useSearchParams } from "react-router-dom";
import { applyPluginsFilters } from "../../components/plugins/actions/applyPluginsFilters";
import { PluginsFilterActionType } from "../../components/plugins/models/PluginsFilterActionType";
import { AppliedPluginFilters } from "../../components/plugins/AppliedPluginFilters";
import { updatePluginsFilters } from "../../components/plugins/actions/updatePluginsFilters";

export const Plugins = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTableView, setIsTableView] = useState(false);
  const { isLoading, pluginDetails, productDetails } = usePlugins();
  const { translate } = useLocalization();
  const filters = new PluginsFilters(searchParams);

  const handleFiltersChange = (action: PluginsFilterActionType, value?: string | number) => {
    setSearchParams(updatePluginsFilters(filters, action, value).toString());
  };

  if (isLoading) return <Loader />;

  const plugins = applyPluginsFilters(filters, productDetails, pluginDetails);
  const page = Math.ceil(plugins.length / filters.Capacity);
  const offset = filters.Page > page && page > 0 ? page : filters.Page;
  const newPlugins = plugins.slice((offset - 1) * filters.Capacity, (offset - 1) * filters.Capacity + filters.Capacity);

  return (
    <>
      <PluginsToolbar products={productDetails} filters={filters} handleFiltersChange={handleFiltersChange} />
      <div className="mt-2">
        <AppliedPluginFilters filters={filters} handleFiltersChange={handleFiltersChange} />
      </div>
      <Row className="align-items-center me-2 mb-3 px-3">
        <p className="m-0 p-0">{translate("plugins.resourcesAvailable", { resources: plugins.length.toString() })}</p>
      </Row>
      <PaginationWrapper
        handleClick={(value) => handleFiltersChange(PluginsFilterActionType.SetPage, value)}
        setCapacity={(value) => handleFiltersChange(PluginsFilterActionType.SetCapacity, value)}
        setTableView={(value) => setIsTableView(value)}
        isTableView={isTableView}
        capacity={filters.Capacity}
        page={offset}
        count={page}
      >
        <Row className="justify-content-center">
          <h2 className="p-0 screen-reader-element">{translate("plugins.pluginsList")}</h2>
          {newPlugins.length ? (
            isTableView ? (
              <div className="m-0 mt-3 px-3">
                <Table bordered striped>
                  <thead>
                    <tr>
                      <th className="text-center align-middle">#</th>
                      <th className="text-center align-middle">{translate("plugins.name")}</th>
                      <th className="text-center align-middle">{translate("plugins.image")}</th>
                      <th className="text-center align-middle">{translate("plugins.objects")}</th>
                      <th className="text-center align-middle">{translate("plugins.lastModified")}</th>
                      <th className="text-center align-middle">{translate("plugins.status")}</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {newPlugins.map((x) => (
                      <PluginRow key={x.id} plugin={x} />
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              newPlugins.map((x) => <PluginCard key={x.id} plugin={x} />)
            )
          ) : (
            <div className="d-flex flex-column align-items-center">
              <Image src={emptyResult} alt={translate("plugins.noPluginsFound")} />
              <h5 className="mt-2">{translate("plugins.noPluginsFound")}</h5>
            </div>
          )}
        </Row>
      </PaginationWrapper>
    </>
  );
};
