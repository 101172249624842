import { PluginLogsFilterActionType } from "../models/PluginLogsFilterActionType";
import { PluginLogsFilters } from "../models/PluginLogsFilters";

export const updatePluginLogsFilters = (
  filters: PluginLogsFilters,
  type: PluginLogsFilterActionType,
  value?: string
) => {
  switch (type) {
    case PluginLogsFilterActionType.SetQuery:
      return setQuery(filters, value as string | undefined);
    case PluginLogsFilterActionType.UnsetQuery:
      return unsetQuery(filters);
    case PluginLogsFilterActionType.SetFromDate:
      return setFromDate(filters, value as string | undefined);
    case PluginLogsFilterActionType.UnsetFromDate:
      return unsetFromDate(filters);
    case PluginLogsFilterActionType.SetToDate:
      return setToDate(filters, value as string | undefined);
    case PluginLogsFilterActionType.UnsetToDate:
      return unsetToDate(filters);
    case PluginLogsFilterActionType.ClearAll:
      return new PluginLogsFilters(new URLSearchParams());
    default:
      return filters;
  }
};

const setQuery = (filters: PluginLogsFilters, value?: string) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.Query = value ?? "";
  return updatedFilters;
};

const unsetQuery = (filters: PluginLogsFilters) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.unsetQuery();
  return updatedFilters;
};

const setFromDate = (filters: PluginLogsFilters, value?: string) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.FromDate = value ?? "";
  return updatedFilters;
};

const unsetFromDate = (filters: PluginLogsFilters) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.unsetFromDate();
  return updatedFilters;
};

const setToDate = (filters: PluginLogsFilters, value?: string) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.ToDate = value ?? "";
  return updatedFilters;
};

const unsetToDate = (filters: PluginLogsFilters) => {
  const updatedFilters = new PluginLogsFilters(filters.Params);
  updatedFilters.unsetToDate();
  return updatedFilters;
};
