import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormikContext } from "formik";
import { Button, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Multiselect } from "../common/inputs/Multiselect";
import { ParentProductDetails } from "./models/ParentProductDetails";
import { useTheme } from "../../contexts/ThemeContext";
import { useParentProducts } from "./contexts/ParentProductsContext";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type ParentProductsTableEditableRowProps = {
  index: number;
  handleSave: () => void;
};

export const ParentProductsTableEditableRow = ({ index, handleSave }: ParentProductsTableEditableRowProps) => {
  const formik = useFormikContext<ParentProductDetails>();
  const { theme } = useTheme();
  const { onEditModeIdChange, fileExtensions } = useParentProducts();
  const { safeExecute } = usePreserve();
  const { values, touched, errors } = formik;
  const { handleChange, setFieldValue } = formik;
  const { translate } = useLocalization();
  const items = fileExtensions.map((x) => ({ value: `${x.id}`, text: x.extension }));

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border">
        <FormControl type="text" name="productName" size="sm" value={values.productName} onChange={handleChange} />
        {touched.productName && !!errors.productName && (
          <span className="invalid-feedback d-inline">{errors.productName}</span>
        )}
      </td>
      <td className="border">
        <Multiselect
          name="supportedFileExtensionsIds"
          size="sm"
          values={values.supportedFileExtensionsIds.map((x) => `${x}`)}
          onChange={(values) => setFieldValue("supportedFileExtensionsIds", values)}
          items={items}
        />
        {touched.supportedFileExtensionsIds && !!errors.supportedFileExtensionsIds && (
          <span className="invalid-feedback d-inline">{errors.supportedFileExtensionsIds}</span>
        )}
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.save")}</Tooltip>}>
          <Button variant="transparent" className="p-0" aria-label={translate("common.save")} onClick={handleSave}>
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: theme.backgroundColor }} />
          </Button>
        </OverlayTrigger>
      </td>
      <td className="border text-center">
        <OverlayTrigger overlay={<Tooltip>{translate("common.discard")}</Tooltip>}>
          <Button
            variant="transparent"
            className="p-0"
            aria-label={translate("common.discard")}
            onClick={() => safeExecute(() => onEditModeIdChange(-1))}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};
