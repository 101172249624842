import { partial_ratio } from "fuzzball";
import { removeHTMLTags } from "../../../utils";
import { PluginLog } from "../models/PluginLog";
import { PluginLogsFilters } from "../models/PluginLogsFilters";

export const applyPluginLogsFilter = (filters: PluginLogsFilters, logs: PluginLog[]) => {
  let filteredLogs = [...logs];

  if (filters.Query) filteredLogs = filterByQuery(logs, filters.Query);
  if (filters.FromDate) filteredLogs = filterByFromDate(logs, filters.FromDate);
  if (filters.ToDate) filteredLogs = filterByToDate(logs, filters.ToDate);

  return filteredLogs;
};

const filterByQuery = (logs: PluginLog[], query: string) => {
  const score = 85;

  return logs.filter((x) => {
    const description = removeHTMLTags(x.content);
    if (partial_ratio(description, query) >= score) return true;
  });
};

const filterByFromDate = (logs: PluginLog[], fromDate: string) => {
  return logs.filter((x) => new Date(fromDate) <= new Date(x.createdAt));
};

const filterByToDate = (logs: PluginLog[], toDate: string) => {
  return logs.filter((x) => new Date(toDate) >= new Date(x.createdAt));
};
