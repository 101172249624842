import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const userGrantValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    id: Yup.string(),
    email: Yup.string(),
    name: Yup.string(),
    selectedRoleId: Yup.string().required(translate("validation.fieldRequired"))
  });
