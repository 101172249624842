import { Col, Row, Image, Button } from "react-bootstrap";
import { Layout } from "../components/layout/Layout";
import { useTheme } from "../contexts/ThemeContext";
import AccessDenied from "../resources/server-down.png";
import { useLocalization } from "../contexts/LocalizationContext";

export const ServerDown = () => {
  const { buttonStyle } = useTheme();
  const baseUrl = process.env.PUBLIC_URL;
  const { translate } = useLocalization();

  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12} lg={6}>
          <Image src={AccessDenied} style={{ width: "100%" }} />
        </Col>
        <Col>
          <h1>{translate("error.seviceUnavailable")}</h1>
          <p className="m-0 mb-3">{translate("error.noConnection")}</p>
          <Button href={`${baseUrl}/`} style={buttonStyle}>
            {translate("error.retry")}
          </Button>
        </Col>
      </Row>
    </Layout>
  );
};
