import { useAuth0 } from "@auth0/auth0-react";
import { useFormikContext } from "formik";
import { Button } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginDetails } from "./models/PluginDetails";
import { useDraftPluginButtonGroup } from "./hooks/useDraftPluginButtonGroup";

export const DraftPluginButtonGroup = () => {
  const { values } = useFormikContext<PluginDetails>();
  const { isLoading, handleSaveAsDraft, handleSubmit, handleSave } = useDraftPluginButtonGroup();
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();
  const isSystemAdmin = user?.user_metadata?.isSystemAdmin;

  if (isLoading) return <Loader />;

  return (
    <>
      <Button style={buttonStyle} type="button" onClick={isSystemAdmin ? handleSave : handleSubmit}>
        {values.publishToRWSAppStore
          ? isSystemAdmin
            ? translate("plugins.publish")
            : translate("plugins.submit")
          : translate("common.save")}
      </Button>
      <Button variant="outline-secondary" type="button" className="mx-2" onClick={handleSaveAsDraft}>
        {translate("plugins.saveAsDraft")}
      </Button>
    </>
  );
};
