export const apiRoutes = {
  authentication: {
    logout: () => "/authentication/logout"
  },
  userAccounts: {
    create: () => "/user-accounts",
    update: (accountId: string, userId: string) => `/accounts/${accountId}/users/${userId}`,
    setAsFavourite: (accountId: string, userId: string) => `/accounts/${accountId}/users/${userId}/set-as-favourite`,
    removeUserFromAccount: (accountId: string, userId: string) => `/accounts/${accountId}/users/${userId}`
  },
  userProfiles: {
    getUser: () => "/users/one",
    getUserSettings: () => "/users/one/settings",
    getMinimalUserAccounts: (userId: string) => `/users/${userId}/minimal-accounts`,
    getExtensiveUserAccounts: (userId: string) => `/users/${userId}/extensive-accounts`,
    changeSelectedAccount: (userId: string) => `/users/${userId}/select-account`,
    updateUserSettings: (userId: string) => `/users/${userId}`,
    invite: (userId: string) => `/users/${userId}/invite`
  },
  accounts: {
    createAccount: () => "/accounts",
    getAccountById: (accountId: string) => `/accounts/${accountId}`,
    updateAccount: (accountId: string) => `/accounts/${accountId}`,
    getAccountSettings: (accountId: string) => `/accounts/${accountId}/settings`,
    updateAccountSettings: (accountId: string) => `/accounts/${accountId}/settings`,
    generatePrivateAppStoreGuid: (accountId: string) => `/accounts/${accountId}/guid`,
    getUsersForAccountById: (accountId: string) => `/accounts/${accountId}/users`,
    getAccountPreferencesById: (accountId: string) => `/accounts/${accountId}/preferences`,
  },
  notifications: {
    getUserNotifications: (accountId: string, userId: string) => `/accounts/${accountId}/users/${userId}/notifications`,
    getUserExtensiveNotifications: (userId: string) => `/users/${userId}/extensive-notifications`,
    getUserNotificationsCount: (accountId: string, userId: string) => `/accounts/${accountId}/users/${userId}/notifications-count`,
    getUserExtensiveNotificationsCount: (userId: string) => `/users/${userId}/extensive-notifications-count`,
    deleteNotification: (notificationId: string) => `/notifications/${notificationId}`,
  },
  agreements: {
    createAgreement: () => "/agreements"
  },
  nameMappings: {
    getNameMappings: () => "/names",
    createNameMapping: () => "/names",
    updateNameMapping: (nameMappingId: number) => `/names/${nameMappingId}`,
    deleteNameMapping: (nameMappingId: number) => `/names/${nameMappingId}`,
  },
  categories: {
    getCategories: () => "/categories",
    createCategory: () => "/categories",
    updateCategory: (categoryId: number) => `/categories/${categoryId}`,
    deleteCategory: (categoryId: number) => `/categories/${categoryId}`,
  },
  parentProducts: {
    getParentProducts: () => "/parent-products",
    createParentProduct: () => "/parent-products",
    updateParentProduct: (parentProductId: number) => `/parent-products/${parentProductId}`,
    deleteParentProduct: (parentProductId: number) => `/parent-products/${parentProductId}`,
  },
  products: {
    getProducts: () => "/products",
    createProduct: () => "/products",
    updateProduct: (productId: number) => `/products/${productId}`,
    deleteProduct: (productId: number) => `/products/${productId}`,
  },
  exports: {
    exportAccountsRoute: (userId: string) => `/users/${userId}/export-accounts`,
    exportPluginsRoute: (accountId: string) => `/accounts/${accountId}/export-plugins`,
  },
  comments: {
    createPluginDetailsComment: (pluginId: number) => `/plugins/${pluginId}/comments`,
    createPluginVersionComment: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/comments`,
    updateComment: (commentId: string) => `/comments/${commentId}`,
    deleteComment: (commentId: string) => `/comments/${commentId}`,
  },
  logs: {
    clearAllPluginLogs: (plugindId: number) => `/plugins/${plugindId}/clear-logs`
  },
  resources: {
    getAdvancedMarkdownTemplate: () => "/resources/markdown-template-advanced",
    getBasicMarkdownTemplate: () => "/resources/markdown-template-basic",
    addPluginDetailsResources: (pluginId: number) => `/plugins/${pluginId}/resources`,
    addPluginVersionResources: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/resources`,
    deletePluginDetailsResource: (pluginId: number, resourceId: number) => `/plugins/${pluginId}/resources/${resourceId}`,
    deletePluginVersionResource: (pluginId: number, versionId: number, resourceId: number) => `/plugins/${pluginId}/versions/${versionId}/resources/${resourceId}`,
    deleteResource: (resourceId: number) => `/resources/${resourceId}`,
  },
  pluginDetails: {
    getPlugins: () => "/plugins",
    getPluginById: (pluginId: number) => `/plugins/${pluginId}`,
    createPlugin: () => "/plugins",
    createPluginDraft: () => "/plugins/create-draft",
    createPluginInReview: () => "/plugins/create-in-review",
    updatePlugin: (pluginId: number) => `/plugins/${pluginId}`,
    updatePluginDraft: (pluginId: number) => `/plugins/${pluginId}/update-draft`,
    updatePluginInReview: (pluginId: number) => `/plugins/${pluginId}/update-in-review`,
    reviewPluginRequest: (pluginId: number) => `/plugins/${pluginId}/plugin-request`,
    changePluginStatus: (pluginId: number) => `/plugins/${pluginId}/change-status`,
    requestPluginDeletion: (pluginId: number) => `/plugins/${pluginId}/deletion-request`,
    undoPluginDeletion:  (pluginId: number) => `/plugins/${pluginId}/deletion-undo`,
    reviewPluginDeletionRequest: (pluginId: number) => `/plugins/${pluginId}/deletion-request`,
    deletePlugin: (pluginId: number) => `/plugins/${pluginId}`,
    downloadPluginResource: (pluginId: number) => `/plugins/${pluginId}/download-resource`,
  },
  pluginVersion: {
    extractVersionFromManifest: (pluginId: number) => `/plugins/${pluginId}/versions/manifest-extract`,
    createVersion: (pluginId: number) => `/plugins/${pluginId}/versions`,
    createVersionDraft: (pluginId: number) => `/plugins/${pluginId}/versions/create-draft`,
    createVersionInReview: (pluginId: number) => `/plugins/${pluginId}/versions/create-in-review`,
    updateVersion: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}`,
    updateVersionDraft: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/update-draft`,
    updateVersionInReview: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/update-in-review`,
    reviewVersionRequest: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/version-request`,
    downloadVersion: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}`,
    requestVersionDeletion: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/deletion-request`,
    undoPluginDeletion:  (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/deletion-undo`,
    reveiewVersionDeletion: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/deletion-request`,
    deleteVersion: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}`,
    changeVersionStatus: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/change-status`,
    downloadVersionChangelog: (pluginId: number, versionId: number) => `/plugins/${pluginId}/versions/${versionId}/download-changelog`,
  }
};