import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Checkbox } from "../common/inputs/Checkbox";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Button, Form } from "react-bootstrap";

type FilterItemProps = {
  name: string;
  items: string[];
  applied: string[];
  borderTop?: boolean;
  borderBottom?: boolean;
  onChange: (list: string[]) => void;
};

export const FilterItem = ({ name, items, applied, borderTop, borderBottom, onChange }: FilterItemProps) => {
  const [isExpanded, setIsExpanded] = useState(applied.length > 0);
  const borderTopClass = borderTop ? "border-top" : "";
  const borderBottomClass = borderBottom ? "border-bottom" : "";
  const { translate } = useLocalization();

  const handleChange = (add: boolean, value: string) => {
    onChange(add ? [...applied, value] : applied.filter((x) => x !== value));
  };

  return (
    <div className={`${borderTopClass} ${borderBottomClass}`}>
      <Button
        onClick={() => setIsExpanded((prev) => !prev)}
        variant="transparent"
        className="d-flex align-items-center w-100"
        id={name}
        aria-expanded={isExpanded}
      >
        <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        <p className="m-0 ms-2">{name}</p>
      </Button>
      {isExpanded && (
        <div aria-labelledby={name}>
          {items.map((x) => (
            <Form.Group key={x} className="d-flex justify-content-between p-2">
              <Form.Check.Label htmlFor={`${name} - ${x}`}>{x}</Form.Check.Label>
              <Checkbox
                name={`${name} - ${x}`}
                checked={applied.includes(x)}
                aria-label={x}
                onChange={() => handleChange(!applied.includes(x), x)}
              />
            </Form.Group>
          ))}
        </div>
      )}
    </div>
  );
};
