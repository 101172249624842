import { useEffect, useState } from "react";
import { useTheme } from "../../../contexts/ThemeContext";
import { useFormikContext } from "formik";
import { AccountSettings } from "../../identity/models/AccountSettings";

export const usePreferences = () => {
  const [fonts, setFonts] = useState<string[]>([]);
  const { setField } = useTheme();
  const { values, setFieldValue } = useFormikContext<AccountSettings>();

  useEffect(() => {
    const getFonts = async () => {
      const response = await import("../../../config/fonts.json");
      setFonts(response?.default);
    };

    getFonts();
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    const position = target.scrollTop;

    Array.from(target.querySelectorAll("a"))
      .slice(Math.round((position + 200) / 33), Math.round((position + 400) / 33))
      .forEach((item: HTMLAnchorElement) => (item.style.fontFamily = item.innerText));
  };

  const handleFieldChange = (name: string, value: string) => {
    setField(name, value);
    setFieldValue(`accountPreferences.${name}`, value);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    handleFieldChange("fontFamily", target.innerText);
  };

  return { values, fonts, handleClick, handleScroll, handleFieldChange };
};
