import { PluginDetailsExtended } from "./models/PluginDetailsExtended";
import { Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { Status } from "./models/Status";
import { DeletePluginButtonGroup } from "./DeletePluginButtonGroup";
import { DeleteAction } from "./models/DeleteAction";
import { useNavigate } from "react-router-dom";
import { useLocalization } from "../../contexts/LocalizationContext";
import { dateFormat } from "../../utils";
import { routes } from "../../main/routes";

type PluginRowProps = { plugin: PluginDetailsExtended };

export const PluginRow = ({ plugin }: PluginRowProps) => {
  const baseUrl = process.env.PUBLIC_URL;
  const navigate = useNavigate();
  const { translate } = useLocalization();

  return (
    <tr>
      <td className="text-center align-middle">{plugin.id}</td>
      <td className="text-center align-middle">
        <a className="text-black fw-bold plugin-name" href={routes.plugins.update(plugin.id, plugin.status)}>
          {plugin.name}
        </a>
      </td>
      <td className="text-center align-middle">
        <Image alt={plugin.name} width={50} src={plugin.iconDetails?.mediaUrl ?? `${baseUrl}/plugin.png`} />
      </td>
      <td className="text-center align-middle">[{plugin.pluginVersions?.length}]</td>
      <td className="text-center align-middle">{dateFormat(translate, plugin.updatedAt)}</td>
      <td className="text-center align-middle">{Status[plugin.status]}</td>
      {plugin.lastModifiedVersion && (
        <td className="text-center align-middle">
          <OverlayTrigger
            overlay={
              <Tooltip>
                {translate("plugins.lastModifiedVersion", {
                  version: plugin.lastModifiedVersion?.versionNumber
                })}
              </Tooltip>
            }
          >
            <Button
              variant="transparent"
              className="p-0"
              aria-label={translate("plugins.viewLastModifiedVersion")}
              onClick={() =>
                navigate(
                  routes.versions.update(
                    plugin.id,
                    plugin.lastModifiedVersion.versionId,
                    plugin.lastModifiedVersion.status
                  )
                )
              }
            >
              <FontAwesomeIcon icon={faHistory} className="text-primary cursor-pointer" />
            </Button>
          </OverlayTrigger>
        </td>
      )}
      <td className="text-center align-middle" colSpan={plugin.lastModifiedVersion ? 1 : 2}>
        <DeletePluginButtonGroup
          hasIcon
          showOverlay
          id={plugin.id}
          deleteInstructions={plugin.deleteInstructions}
          futureInstructions={DeleteAction.All}
        />
      </td>
    </tr>
  );
};
