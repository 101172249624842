import { useState } from "react";

type UseUploadResourcesAreaProps = {
  onDelete: (resourceId: number) => Promise<void>;
  onUpload: (files: File[]) => void;
};

export const useUploadResourcesArea = ({ onDelete, onUpload }: UseUploadResourcesAreaProps) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOver(false);
    onUpload(Array.from(e.dataTransfer.files));
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.target.files) onUpload(Array.from(e.target.files));
    e.target.value = "";
  };

  const handleRemoveResource = async (resourceId: number) => onDelete(resourceId);

  return {
    dragOver,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    handleFileChange,
    handleRemoveResource
  };
};
