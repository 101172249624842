import { PluginVersionExtended } from "./PluginVersionExtended";
import { Status } from "./Status";
import { publicStatusMap } from "./StatusHelper";
import { PluginVersionBase } from "./PluginVersionBase";

export class PluginVersionMismatchMapper {
  static getMismatch(
    pluginVersionSiblings: PluginVersionExtended[],
    pluginStatus: Status,
    currentValues?: PluginVersionBase,
    isSystemAdmin?: boolean,
    currentVersionId?: number,
    definedFeatureStatus?: Status
  ) {
    const pluginVersion = pluginVersionSiblings.filter((x) => x.versionId !== currentVersionId);
    const statuses = pluginVersion.map((x) => x.status);
    const featureStatus = definedFeatureStatus ?? this.getFeatureStatus(isSystemAdmin, currentValues);
    const isFeatureReview = featureStatus === Status.InReview;
    const maximumStatus = Math.max(...statuses);

    if (publicStatusMap.get(pluginStatus) && isFeatureReview) return { status: featureStatus, isMismatch: false };
    return { status: featureStatus, isMismatch: pluginStatus !== maximumStatus };
  }

  static findUnpublishedMatchingVersions(pluginVersions: PluginVersionExtended[], versionId: number, status: Status) {
    if (status !== Status.NotPublished) return { unpublishedVersions: [], currentVersionNumber: "" };

    const targetVersion = pluginVersions.find((v) => v.versionId === versionId);
    if (!targetVersion) return { unpublishedVersions: [], currentVersionNumber: "" };

    const targetProducts = new Set(targetVersion.supportedProducts);

    const matchingVersions = pluginVersions.filter(
      (v) =>
        v.versionId !== versionId &&
        v.status === Status.NotPublished &&
        targetProducts.size === v.supportedProducts.length &&
        v.supportedProducts.every((product) => targetProducts.has(product))
    );

    return { unpublishedVersions: matchingVersions, currentVersionNumber: targetVersion.versionNumber };
  }

  private static getFeatureStatus(isSystemAdmin?: boolean, version?: PluginVersionBase) {
    if (version?.publishToRWSAppStore && isSystemAdmin) return Status.Published;
    if (version?.publishToRWSAppStore && !isSystemAdmin) return Status.InReview;
    if (version?.publishToPrivateAppStore) return Status.Private;
    return Status.InComplete;
  }
}
