import { Formik } from "formik";
import { Container } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { productValidationSchema } from "./schemas/ProductValidationSchema";
import { ProductsTable } from "./ProductsTable";
import { ProductDetails } from "./models/ProductDetails";
import { useProducts } from "./contexts/ProductsContext";
import { useLocalization } from "../../contexts/LocalizationContext";

export const Products = () => {
  const { productDetails, editModeId, isLoading } = useProducts();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const editableItem = productDetails.find((item) => item.id === editModeId);
  const initialValues: ProductDetails = {
    id: editableItem?.id ?? 0,
    productName: editableItem?.productName ?? "",
    minimumStudioVersion: editableItem?.minimumStudioVersion ?? "",
    parentProductID: editableItem?.parentProductID ?? 0,
    isLegacy: editableItem?.isLegacy ?? false
  };

  return (
    <Container className="p-0 mb-2">
      <Formik<ProductDetails>
        key={editModeId}
        initialValues={initialValues}
        validationSchema={productValidationSchema(translate)}
        onSubmit={() => undefined}
        enableReinitialize
      >
        <ProductsTable />
      </Formik>
    </Container>
  );
};
