import { Form, Formik } from "formik";
import { PluginDetailsExtended as PluginModel } from "./models/PluginDetailsExtended";
import { pluginValidationSchema } from "./schemas/PluginValidationSchema";
import { ReactNode } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { PluginDetails } from "./models/PluginDetails";
import { markdownStyle } from "../common/styles/markdownStyle";

type PluginDetailsProps = { plugin?: PluginModel; children: ReactNode };

export const PluginDetailsForm = ({ plugin, children }: PluginDetailsProps) => {
  const { translate } = useLocalization();

  const initialValues: PluginDetails = {
    id: plugin?.id ?? -1,
    name: plugin?.name ?? "",
    iconUpload: null,
    supportUrl: plugin?.supportUrl ?? "",
    supportEmail: plugin?.supportEmail ?? "",
    markdownDocumentation: plugin?.markdownDocumentation ?? "",
    markdownDescription: plugin?.markdownDescription ?? "",
    htmlDocumentation: plugin?.htmlDocumentation ?? "" + markdownStyle,
    htmlDescription: plugin?.htmlDescription ?? "" + markdownStyle,
    categories: plugin?.categories ?? [],
    parentProductDetailsId: plugin?.parentProductDetailsId ?? 0,
    sharedParentProductIds: plugin?.sharedParentProductIds ?? [],
    paidFor: plugin?.paidFor ?? false,
    publishToRWSAppStore: plugin?.publishToRWSAppStore ?? false,
    mediaUrl: plugin?.iconDetails?.mediaUrl,
    documentationResources: plugin?.documentationResources ?? [],
    descriptionResources: plugin?.descriptionResources ?? [],
    resourcesModified: false
  };

  return (
    <Formik<PluginDetails>
      validationSchema={pluginValidationSchema(translate)}
      initialValues={initialValues}
      onSubmit={() => undefined}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  );
};
