import { useState } from "react";
import { useFeedback } from "../../../contexts/FeedbackContext";
import { useFetch } from "../../../hooks/useFetch";
import { AccountRegister } from "../../identity/models/AccountRegister";
import { useLocalization } from "../../../contexts/LocalizationContext";
import { apiRoutes } from "../../../apiRoutes";
import { stringifyReplacer } from "../../../utils";

export const useRegister = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { translate } = useLocalization();
  const { pushNotification } = useFeedback();
  const { request } = useFetch();

  const handleSubmit = async (value: AccountRegister) => {
    setIsLoading(true);
    await request({
      route: apiRoutes.accounts.createAccount(),
      body: JSON.stringify(value, stringifyReplacer),
      contentType: "application/json",
      method: "POST",
      onSuccess: () =>
        pushNotification({
          message: translate("request.account.registerSuccess", {
            accountName: value.name,
            email: value.email
          }),
          type: "success"
        }),
      onFailure: () =>
        pushNotification({
          message: translate("request.account.registerFail", { accountName: value.name }),
          type: "danger"
        }),
      onAny: () => setIsLoading(false)
    });
  };

  return { isLoading, handleSubmit };
};
