import { useFetch } from "../../../hooks/useFetch";
import { PluginsContextProps } from "./models/PluginsContextProps";
import { PluginsResponse } from "../models/PluginsResponse";
import { createContext, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { apiRoutes } from "../../../apiRoutes";

const initialValues: PluginsResponse = {
  pluginDetails: [],
  productDetails: []
};

type PluginsContextProviderProps = { children: ReactElement };

export const PluginsContext = createContext<PluginsContextProps>({
  pluginDetails: [],
  productDetails: [],
  isLoading: true,
  getPlugins: async () => undefined
});

export const usePlugins = () => useContext(PluginsContext);

export const PluginsContextProvider = ({ children }: PluginsContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<PluginsResponse>(initialValues);
  const { request } = useFetch();

  const getPlugins = useCallback(async () => {
    setIsLoading(true);
    await request({
      route: apiRoutes.pluginDetails.getPlugins(),
      onSuccess: async (response) => setData(await response.json()),
      onAny: () => setIsLoading(false)
    });
  }, [request]);

  useEffect(() => {
    getPlugins();
  }, [getPlugins]);

  return <PluginsContext.Provider value={{ ...data, isLoading, getPlugins }}>{children}</PluginsContext.Provider>;
};
