import { Button, Modal, Table } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { usePlugin } from "./contexts/PluginContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type InformationMissingModalProps = {
  onClose: () => void;
};

export const InformationMissingModal = ({ onClose }: InformationMissingModalProps) => {
  const { pluginDetails: plugin } = usePlugin();
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();

  return (
    <Modal show size="lg" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{translate("plugins.pluginInformationMissing")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{translate("plugins.reviewPluginDetails")}</p>
        <Table striped bordered>
          <thead>
            <tr>
              <th>{translate("plugins.property")}</th>
              <th>{translate("plugins.value")}</th>
            </tr>
          </thead>
          <tbody>
            {!plugin.markdownDescription && (
              <tr>
                <td>{translate("plugins.description")}</td>
                <td>{translate("plugins.undefined")}</td>
              </tr>
            )}
            {plugin.categories.length === 0 && (
              <tr>
                <td>{translate("plugins.categories")}</td>
                <td>[{translate("plugins.empty")}]</td>
              </tr>
            )}
            {!plugin.iconDetails?.mediaUrl && (
              <tr>
                <td>{translate("plugins.icon")}</td>
                <td>{translate("plugins.undefined")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button style={buttonStyle} type="button" onClick={onClose}>
          {translate("common.ok")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
