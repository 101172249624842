import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const draftPluginValidationSchema = (translate: TranslateFunction) =>
  Yup.object().shape({
    name: Yup.string().required(translate("validation.fieldRequired")),
    parentProductDetailsId: Yup.number().min(
      1,
      translate("validation.fieldRequired")
    )
  });
