import { Button, Col, Image, Row } from "react-bootstrap";
import { useTheme } from "../contexts/ThemeContext";
import NotFoundImage from "../resources/not-found.png";
import { useLocalization } from "../contexts/LocalizationContext";

export const NotFound = () => {
  const { buttonStyle } = useTheme();
  const baseUrl = process.env.PUBLIC_URL;
  const { translate } = useLocalization();

  return (
    <Row className="mt-5">
      <Col xs={12} lg={6}>
        <Image src={NotFoundImage} style={{ width: "100%" }} />
      </Col>
      <Col>
        <h1>{translate("error.notFound")}</h1>
        <p className="m-0 mb-3">{translate("error.pageNotFound")}</p>
        <Button href={`${baseUrl}/`} style={buttonStyle}>
          {translate("error.home")}
        </Button>
      </Col>
    </Row>
  );
};
