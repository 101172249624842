import { ReactNode, createContext, useContext } from "react";
import { PluginVersionExtended } from "../models/PluginVersionExtended";
import { VersionContextProps } from "./models/VersionContextProps";
import { Outlet, useParams } from "react-router-dom";
import { usePlugin } from "./PluginContext";

type VersionContextProviderProps = {
  children?: ReactNode;
};

export const VersionContext = createContext<VersionContextProps>({
  version: {} as PluginVersionExtended
});

export const useVersion = () => useContext(VersionContext);

export const VersionContextProvider = ({ children }: VersionContextProviderProps) => {
  const { pluginDetails: plugin } = usePlugin();
  const { versionId } = useParams();
  const versions = plugin?.pluginVersions;
  const version = versions?.find((x) => `${x.versionId}` === versionId);

  return (
    <VersionContext.Provider value={{ version: version as PluginVersionExtended }}>
      {children ?? <Outlet />}
    </VersionContext.Provider>
  );
};
