import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "../../contexts/ThemeContext";
import { ExtendedUser } from "./models/ExtendedUser";
import { Button, Form, Image } from "react-bootstrap";
import { useLocalization } from "../../contexts/LocalizationContext";

export const UserProfile = () => {
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { translate } = useLocalization();

  return (
    <>
      <hr className="d-block d-xl-none" aria-hidden />
      <h3 className="mb-3">{translate("profile.profile")}</h3>
      <div className="d-flex flex-column flex-lg-row align-items-center mb-3">
        <Image
          alt={translate("profile.profilePicture")}
          className="mb-3 m-lg-0"
          src={user?.picture}
          thumbnail
          width={200}
        />
        <div className="w-100 m-0 ms-lg-3">
          <div className="mb-3">
            <Form.Label htmlFor="name">{translate("account.name")}</Form.Label>
            <Form.Control type="text" name="name" id="name" value={user?.user_metadata?.name} disabled />
          </div>
          <div className="mb-3">
            <Form.Label htmlFor="email">{translate("common.email")}</Form.Label>
            <Form.Control type="email" name="email" id="email" value={user?.email} disabled />
          </div>
        </div>
      </div>
      <Button className="mb-3" style={buttonStyle} disabled>
        {translate("common.save")}
      </Button>
    </>
  );
};
