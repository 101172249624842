import { TranslateFunction } from "../../../contexts/LocalizationContext";
import { FilterTag } from "./FilterTag";
import { NotificationFilters } from "./NotificationFilters";
import { NotificationsFilterActionType } from "./NotificationsFilterActionType";

export class FilterTagsBuilder {
  private filters: NotificationFilters;

  constructor(filters: NotificationFilters) {
    this.filters = filters;
  }

  public getAll(translate: TranslateFunction): FilterTag[] {
    return [
      {
        value: this.filters.Status,
        action: NotificationsFilterActionType.UnsetStatus,
        type: translate("plugins.status")
      },
      { value: this.filters.Query, action: NotificationsFilterActionType.UnsetQuery, type: translate("common.query") }
    ];
  }
}
