import * as Yup from "yup";
import { TranslateFunction } from "../../../contexts/LocalizationContext";

export const draftVersionValidationSchema = (translate : TranslateFunction) => Yup.object().shape({
  versionNumber: Yup.string()
    .required(translate("validation.fieldRequired"))
    .matches(/^(\d+\.)(\d+\.)(\d+\.)(\d+)$/, translate("validation.invalidVersionNumber")),
  supportedProducts: Yup.array().min(
    1,
    translate("validation.productVersion")
  )
});
