import { useFormikContext } from "formik";
import { useTheme } from "../../contexts/ThemeContext";
import { AccountRegister } from "../identity/models/AccountRegister";
import { useRegister } from "../applicationsettings/hooks/useRegister";
import { registerValidationSchema } from "../applicationsettings/schemas/RegisterValidationSchema";
import { Modal, Button, Spinner, FormControl, FormLabel } from "react-bootstrap";
import { Switch } from "../common/inputs/Switch";
import { useLocalization } from "../../contexts/LocalizationContext";

type RegisterModalProps = { onClose: () => void };

export const RegisterModal = ({ onClose }: RegisterModalProps) => {
  const { buttonStyle } = useTheme();
  const { isLoading, handleSubmit } = useRegister();
  const { values, touched, errors, handleChange, setTouched, setFieldValue } = useFormikContext<AccountRegister>();
  const { translate } = useLocalization();

  const handleClick = async () => {
    try {
      await registerValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      await handleSubmit(values);
      onClose();
    } catch (errors) {
      setTouched({ email: true, name: true, alias: true });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue("enablePrivateAppStore", e.target.checked);

    if (!e.target.checked) setFieldValue("publishToPrivateAppStore", e.target.checked);
  };

  const onFieldChange = (e: React.ChangeEvent<HTMLInputElement>, opositeField: keyof AccountRegister) => {
    const { checked, name } = e.target;
    setFieldValue(name, checked);
    setFieldValue(opositeField, !checked);
  };

  return (
    <Modal aria-label={translate("register.registerDialog")} size="lg" show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="fs-3 m-0">{translate("register.createAdministratorAccount")}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 className="fs-4">{translate("register.basicSettings")}</h2>
        <div className="mb-3">
          <FormLabel htmlFor="email">{translate("register.emailAddress")}</FormLabel>
          <FormControl type="email" id="email" name="email" value={values.email} onChange={handleChange} />
          {touched.email && !!errors.email && <span className="invalid-feedback d-inline">{errors.email}</span>}
        </div>
        <div className="mb-3">
          <FormLabel htmlFor="name">{translate("register.accountName")}</FormLabel>
          <FormControl type="text" id="name" name="name" value={values.name} onChange={handleChange} />
          {touched.name && !!errors.name && <span className="invalid-feedback d-inline">{errors.name}</span>}
        </div>
        <div className="mb-3">
          <FormLabel htmlFor="alias">{translate("register.developerAlias")}</FormLabel>
          <FormControl type="text" id="alias" name="alias" value={values.alias as string} onChange={handleChange} />
        </div>
        <div className="mb-3">
          <Switch
            name="enablePrivateAppStore"
            label={translate("settings.enablePrivateAppStore")}
            checked={values.enablePrivateAppStore}
            onChange={onChange}
          />
        </div>
        <hr aria-hidden />
        <h2 className="fs-4">{translate("register.defaultSettings")}</h2>
        <div className="mb-3">
          <Switch
            name="publishToRWSAppStore"
            label={translate("plugins.publishToRWSAppStore")}
            checked={values.publishToRWSAppStore}
            onChange={(e) => onFieldChange(e, "publishToPrivateAppStore")}
          />
        </div>
        <div className="mb-3">
          <Switch
            name="publishToPrivateAppStore"
            label={translate("plugins.publishToPrivateAppStore")}
            disabled={!values.enablePrivateAppStore}
            checked={values.publishToPrivateAppStore}
            onChange={(e) => onFieldChange(e, "publishToRWSAppStore")}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="success"
          type="button"
          onClick={handleClick}
          disabled={isLoading}
          style={{ ...buttonStyle, width: 100 }}
        >
          {isLoading ? <Spinner size="sm" /> : translate("register.register")}
        </Button>
        <Button variant="secondary" onClick={onClose}>
          {translate("common.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
