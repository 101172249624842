import { Status } from "../components/plugins/models/Status";

const baseUrl = process.env.PUBLIC_URL;

export const routes = {
  profile: {
    index: () => `${baseUrl}/profile`,
    accounts: () => `${baseUrl}/profile/accounts`,
    settings: () => `${baseUrl}/profile/settings`
  },
  administration: {
    categories: () => `${baseUrl}/administration/settings/categories`,
    products: () => `${baseUrl}/administration/settings/products`,
    parentProducts: () => `${baseUrl}/administration/settings/products/parents`,
  },
  accounts: {
    index: () => `${baseUrl}/settings/account`,
    users: () => `${baseUrl}/settings/account/users`,
    settings: () => `${baseUrl}/settings/account/settings`,
    export: () => `${baseUrl}/settings/account/export`,
    nameMappings: () => `${baseUrl}/settings/account/names`,
  },
  plugins: {
    index: () => `${baseUrl}/plugins`,
    create: () => `${baseUrl}/plugins/new`,
    update: (pluginId: number, status: Status) => {
      switch (status) {
        case Status.InComplete: return `${baseUrl}/plugins/${pluginId}/draft`;
        case Status.InReview: return `${baseUrl}/plugins/${pluginId}/pending`;
        default: return `${baseUrl}/plugins/${pluginId}/details`;
      }
    },
    comments: (pluginId: number) =>  `${baseUrl}/plugins/${pluginId}/comments`, 
    logs: (pluginId: number) =>  `${baseUrl}/plugins/${pluginId}/logs`, 
  },
  versions: {
    index: (pluginId: number) => `${baseUrl}/plugins/${pluginId}/versions`,
    create: (pluginId: number) => `${baseUrl}/plugins/${pluginId}/versions/new`,
    update: (pluginId: number, versionId: number, status: Status) => {
      switch (status) {
        case Status.InComplete: return `${baseUrl}/plugins/${pluginId}/versions/${versionId}/draft`;
        case Status.InReview: return `${baseUrl}/plugins/${pluginId}/versions/${versionId}/pending`;
        default: return `${baseUrl}/plugins/${pluginId}/versions/${versionId}/details`;
      }
    },
    comments: (pluginId: number, versionId: number) =>  `${baseUrl}/plugins/${pluginId}/versions/${versionId}/comments`
  }
};