import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, useFormikContext } from "formik";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { Loader } from "../common/progress/Loader";
import { useProducts } from "./contexts/ProductsContext";
import { useProductsActions } from "./hooks/useProductsActions";
import { ProductDetails } from "./models/ProductDetails";
import { ProductsTableEditableRow } from "./ProductsTableEditableRow";
import { ProductsTableReadonlyRow } from "./ProductsTableReadonlyRow";
import { productValidationSchema } from "./schemas/ProductValidationSchema";
import { usePreserve } from "../../contexts/PreserveContext";
import { useEffect } from "react";
import { useLocalization } from "../../contexts/LocalizationContext";
import { TableFooter } from "../common/TableFooter";

const defaultCapacity = 10;

export const ProductsTable = () => {
  const formikContext = useFormikContext<ProductDetails>();
  const productsActions = useProductsActions();
  const { theme, buttonStyle } = useTheme();
  const products = useProducts();
  const { editModeId, productDetails, capacity } = products;
  const { onEditModeIdChange, onCapacityChange } = products;
  const { isLoading, handleCreate, handleUpdate, handleDelete } = productsActions;
  const { values, initialValues, setTouched, handleSubmit } = formikContext;
  const { safeExecute, setCurrentState, setInitialState } = usePreserve();
  const { translate } = useLocalization();

  useEffect(() => {
    setCurrentState(values);
  }, [values]);

  useEffect(() => {
    setInitialState(initialValues);
  }, [initialValues]);

  const handleAdd = () => safeExecute(() => onEditModeIdChange(0));

  const handleClick = async () => {
    try {
      await productValidationSchema(translate).validate(values, { abortEarly: false });
      setTouched({});
      if (editModeId === 0) await handleCreate(values);
      else await handleUpdate(editModeId, values);
    } catch (errors) {
      handleSubmit();
    }
  };

  if (isLoading) return <Loader />;

  const newProducts = productDetails.slice(0, capacity);

  return (
    <Form>
      <Table striped bordered>
        <thead>
          <tr className="d-none d-xl-table-row">
            <th>{translate("plugins.id")}</th>
            <th>{translate("plugins.productName")}</th>
            <th>{translate("versions.minimumStudioVersion")}</th>
            <th>{translate("plugins.supportedParentProductId")}</th>
            <th>{translate("versions.isLegacy")}</th>
            <th colSpan={2} className="text-center">
              <OverlayTrigger overlay={<Tooltip>{translate("common.add")}</Tooltip>}>
                <Button variant="transparent" className="p-0" aria-label={translate("common.add")} onClick={handleAdd}>
                  <FontAwesomeIcon icon={faPlusCircle} style={{ color: theme.backgroundColor }} />
                </Button>
              </OverlayTrigger>
            </th>
          </tr>
        </thead>
        <tbody>
          {newProducts.map((x, i) =>
            x.id === editModeId ? (
              <ProductsTableEditableRow key={x.id} index={i + 1} handleSave={handleClick} />
            ) : (
              <ProductsTableReadonlyRow key={x.id} item={x} index={i + 1} onDelete={() => handleDelete(x.id)} />
            )
          )}
          {editModeId === 0 && <ProductsTableEditableRow index={productDetails.length + 1} handleSave={handleClick} />}
          <TableFooter
            expand={() => onCapacityChange(defaultCapacity)}
            expandAll={() => onCapacityChange(productDetails.length)}
            capacity={defaultCapacity}
            showMenu={!!newProducts.length && productDetails.length > defaultCapacity}
            showEmpty={!newProducts.length && editModeId === -1}
            colSpan={7}
            emptyMessage={translate("plugins.noProductsFound")}
          />
        </tbody>
      </Table>
      <Button variant="success" style={buttonStyle} onClick={handleAdd}>
        {translate("common.addNew")}
      </Button>
    </Form>
  );
};
