import React, { SyntheticEvent, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { SelectItem } from "./models/SelectItem";
import { MultiselectToggle } from "./MultiselectToggle";
import { Checkbox } from "./Checkbox";

type MultiselectProps = {
  name: string;
  size: "sm" | "lg";
  items: SelectItem[];
  values: string[];
  disabled?: boolean;
  onChange: (values: string[]) => void;
};

export const Multiselect = ({ name, items, values, size, disabled, onChange }: MultiselectProps) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleSelect = (value: string) => {
    const newArray = values.includes(value) ? values.filter((x) => x !== value) : values.concat(value);

    onChange(newArray);
  };

  const handleRemove = (e: SyntheticEvent, value: string) => {
    e.stopPropagation();

    const newArray = values.filter((x) => x !== value);
    onChange(newArray);
  };

  const handleScroll = (e: SyntheticEvent, offset: number) => {
    e.stopPropagation();

    const overflow = e.currentTarget?.parentElement?.querySelector(".summary");

    if (overflow) {
      overflow.scrollLeft += offset;
    }
  };

  return (
    <Dropdown autoClose="outside" onToggle={() => (disabled ? {} : setIsToggled((prev) => !prev))}>
      <Dropdown.Toggle
        as={MultiselectToggle}
        disabled={disabled}
        items={items}
        isToggled={isToggled}
        values={values}
        size={size}
        name={name}
        handleScroll={handleScroll}
        handleRemove={handleRemove}
      />
      {!disabled && (
        <Dropdown.Menu show={isToggled} as="ul" className="p-0 w-100" style={menuStyle} aria-labelledby={name}>
          {items.map((selectItem) => (
            <li
              key={selectItem.value}
              className="p-2 cursor-pointer"
              style={values.some((x) => x === selectItem.value) ? dropItemStyle : {}}
            >
              <Checkbox
                checked={values.some((x) => x === selectItem.value)}
                name={selectItem.value}
                label={selectItem.text}
                onChange={() => handleSelect(selectItem.value)}
                labelProps={{ className: "w-100 cursor-pointer" }}
              />
            </li>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

const dropItemStyle: React.CSSProperties = { backgroundColor: "#ADD8E6" };
const menuStyle: React.CSSProperties = { maxHeight: 200, overflowY: "auto" };
