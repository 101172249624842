import { useLocalization } from "../../contexts/LocalizationContext";
import { useTheme } from "../../contexts/ThemeContext";
import { useState } from "react";
import { ProductDetails } from "../applicationsettings/models/ProductDetails";
import { PluginsFilterActionType } from "./models/PluginsFilterActionType";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

type PluginsToolbarProps = {
  products: ProductDetails[];
  selectStyle: React.CSSProperties;
  handleFiltersChange: (action: PluginsFilterActionType, value: any) => void;
};

export const SelectProduct = ({ products, selectStyle, handleFiltersChange }: PluginsToolbarProps) => {
  const { theme, buttonStyle } = useTheme();
  const { translate } = useLocalization();
  const [menuOpen, setMenuOpen] = useState(false);

  const groupedProducts = products.reduce((acc, product) => {
    const [groupName, yearAndVersion] = product.productName.split(/ (?=\d{4})/);
    const [year, version] = yearAndVersion ? yearAndVersion.replace(")", "").split(" (") : [null, null];

    acc[groupName] = acc[groupName] || {};
    if (year) {
      acc[groupName][year] = acc[groupName][year] || [];
      if (version) {
        acc[groupName][year].push(version);
      }
    }
    return acc;
  }, {} as Record<string, Record<string, string[]>>);

  const handleSelection = (product: string, year?: string, version?: string) => {
    handleFiltersChange(
      PluginsFilterActionType.SetProduct,
      [product, year, version ? `(${version})` : ""].filter(Boolean).join(" ")
    );
    setMenuOpen(false);
  };

  const renderMenuItems = (data: any, parentKeys: string[] = []) => {
    return Object.keys(data)
      .sort((a, b) => (parentKeys.length === 0 ? a.localeCompare(b) : parseInt(b) - parseInt(a)))
      .map((key) => {
        const subData = data[key];
        const newKeys = [...parentKeys, key];
        const hasChildren = Object.keys(subData).length > 0;
        const joinedKeys = newKeys.join("-");

        return (
          <Dropdown as={ButtonGroup} key={joinedKeys} className="w-100 py-0" drop="end">
            <Button
              onClick={() => handleSelection(newKeys[0], newKeys[1], newKeys[2])}
              className="text-start border-0 rounded-0"
              style={buttonStyle}
            >
              {key}
            </Button>
            {hasChildren && (
              <>
                <Dropdown.Toggle
                  className="text-end border-0 rounded-0"
                  style={{ flex: "0 0 10%", ...buttonStyle }}
                  aria-label={translate("plugins.selectProductYear", { product: key })}
                />
                <Dropdown.Menu className="py-0" aria-label={translate("plugins.selectYearVersion", { year: key })}>
                  {Array.isArray(subData)
                    ? subData.map((item, index) => (
                        <Dropdown.Item
                          key={`${joinedKeys}-${index}`}
                          onClick={() => handleSelection(newKeys[0], newKeys[1], item)}
                          className="border-0 rounded-0"
                          style={{ backgroundColor: theme?.backgroundColor, color: theme?.foregroundColor }}
                        >
                          {item}
                        </Dropdown.Item>
                      ))
                    : renderMenuItems(subData, newKeys)}
                </Dropdown.Menu>
              </>
            )}
          </Dropdown>
        );
      });
  };

  return (
    <div style={{ width: "35%" }}>
      <Dropdown
        as={ButtonGroup}
        className="w-100 text-start"
        show={menuOpen}
        onToggle={(isOpen) => setMenuOpen(isOpen)}
      >
        <Dropdown.Toggle
          id="dropdown-basic"
          className="d-flex justify-content-between align-items-center"
          style={selectStyle}
        >
          {translate("plugins.products")}
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-0">{renderMenuItems(groupedProducts)}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
