import { Button, Col, Container, Row } from "react-bootstrap";
import { Formik, Form as FormikForm } from "formik";
import "../components/identity/agreementstyle.css";
import { useAgreement } from "../components/identity/hooks/useAgreement";
import { Loader } from "../components/common/progress/Loader";
import { useTheme } from "../contexts/ThemeContext";
import { Checkbox } from "../components/common/inputs/Checkbox";
import { accountAgreementValidationSchema } from "../components/identity/schemas/AccountAgreementValidationSchema";
import { useLocalization } from "../contexts/LocalizationContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../components/identity/models/ExtendedUser";
import { AgreementCreate } from "../components/identity/models/AgreementCreate";

export const Agreement = () => {
  const { isLoading, handleSubmit } = useAgreement();
  const { buttonStyle } = useTheme();
  const { user } = useAuth0<ExtendedUser>();
  const { translate } = useLocalization();

  const initialValues: AgreementCreate = {
    isAgreementAccepted: false,
    userProfileId: user?.user_metadata?.id as string,
    accountId: user?.user_metadata?.selectedAccount.id as string
  };

  if (isLoading) return <Loader />;

  return (
    <Container className="mb-3">
      <Row className="justify-content-center">
        <Col xs={11} md={8} lg={12} className="rounded p-5 bg-light agreement">
          <h2 className="my-3">RWS Developer Partner Terms and Conditions</h2>
          <ol>
            <li>
              <strong>Your Relationship with RWS</strong>
              <ol>
                <li>
                  These terms and conditions create a contract between you and SDL Limited of New Globe House, Vanwall
                  Business Park, Vanwall Road, Maidenhead, SL6 4UB, England (a part of the RWS Holdings PLC Group of
                  companies) (&ldquo;RWS&rdquo; or &ldquo;SDL&rdquo;). To confirm your understanding and acceptance of
                  the terms herein, click &ldquo;Agree&rdquo;. Where there is no option to click &ldquo;Agree&rdquo;,
                  you accept that the use of the RWS AppStore, the APIs provided by RWS, the Developer Community in the
                  RWS Community, constitute your agreement herein. Acceptance is also made by entering a software
                  encryption code or signing an Order Form referring to this Agreement, as applicable.
                </li>
                <li>
                  This documentation (&quot;Agreement&quot;) sets out the terms and conditions applicable to your access
                  to and use of the Services (as defined below) and are agreements between SDL on behalf of its
                  affiliates and subsidiaries and the legal entity you or you represent (&quot;Client&rdquo;). By
                  accepting this Agreement you agree to the terms of this Agreement.&nbsp;
                </li>
                <li>
                  IF YOU ARE ACCEPTING THESE TERMS ON BEHALF OF ANOTHER PERSON OR A COMPANY OR OTHER LEGAL ENTITY, YOU
                  REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT PERSON, COMPANY, OR LEGAL ENTITY TO
                  THESE TERMS. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT USE THE
                  SOFTWARE FOR DEVELOPMENT PURPOSES, DO NOT SIGN INTO THE RWS DEVELOPERS COMMUNITY, DO NOT USE THE APIs
                  MADE AVAILABLE FOR WORKING WITH RWS LICENSED SOFTWARE (&ldquo;Licensed Product&rdquo;).&nbsp;
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Definitions</strong>
              <ol>
                <li>
                  &ldquo;RWS websites&rdquo; means websites hosted and/or managed by RWS including but not limited to:
                  rws.com; appstore.rws.com, developers.rws.com, community.rws.com, languagecloud.sdl.com, trados.com.
                  RWS websites may also refer to certain third party provided websites that RWS utilizes, which
                  currently includes github.com/RWS. Please see Section 3 and Section 10 for your rights and obligations
                  regarding use of any third party websites and/or content.
                </li>
                <li>
                  &ldquo;Documentation&rdquo; means the manuals and other technical and functional documentation
                  available to Client through the RWS websites for use in support of development work in accordance with
                  these terms and conditions.&nbsp;
                </li>
                <li>
                  &ldquo;Services&rdquo; means the Developer Services, Documentation, the RWS websites, and Licensed
                  Product we make available to you under this Agreement.
                </li>
                <li>
                  &ldquo;Intellectual Property Rights&rdquo; means patent rights (including patent applications and
                  disclosures), copyrights, trademarks, trade secrets, know-how and any other intellectual property
                  rights recognized in any country which is a party to the Berne Convention.&nbsp;
                </li>
                <li>
                  &ldquo;Licensed Product&rdquo; means the software products made available to you as a verified
                  developer working with the RWS APIs to connect to, integrate with, or adapt/enhance with additional
                  functionality, made available either in object code form or on-line by RWS from time to time.
                </li>
                <li>
                  &ldquo;App&rdquo; or &ldquo;Application&rdquo; means an application developed by the Client designed
                  to connect to, integrate with, or adapt/enhance with additional functionality, the Licensed Product.
                </li>
                <li>
                  &ldquo;Community Support&rdquo; means the community support provided through the RWS Community
                  Developer forums.
                </li>
                <li>
                  &ldquo;Technical Support&rdquo; means the technical support service set forth in any separate
                  agreement with RWS as part of an additional partner agreement.
                </li>
                <li>
                  &ldquo;Support Level&rdquo; means the type of Support package purchased, or provided, by RWS as part
                  of any separate Technical Support agreement.&nbsp;
                </li>
                <li>
                  &ldquo;API&rdquo; means Application Programming Interface which is a source-code based software
                  interface or intermediary that allows applications or software components to communicate with each
                  other and defines the way in which an application will interact with other software and share data.
                </li>
                <li>
                  &ldquo;SDK&rdquo; means Software Development Kit and refers to the documentation, software (source
                  code and object code), applications, sample code, simulator, tools, libraries, APIs, data, files, and
                  materials provided by RWS for use to Client and includes any Updates that may be provided by RWS
                  pursuant to this Agreement. &nbsp;
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Restrictions</strong>
              <ol>
                <li>
                  Client shall use commercially reasonable efforts to prevent unauthorized access to, or use of, the
                  Licensed Product, and shall notify SDL promptly of any such unauthorized use. Client shall not
                  transmit any data that it has reason to believe is infringing, obscene, threatening, libellous, or
                  otherwise unlawful or tortious, including material harmful to children or violative of third party
                  privacy rights, and Client shall indemnify and defend SDL from and against any breach of the foregoing
                  restriction. In addition, Client shall not (a) copy, reproduce, distribute, republish, download
                  display, post or transmit in any form or means the Licensed Product or the Documentation, (b) rent,
                  transfer, lease, loan, resell for profit or otherwise, distribute, or otherwise grant any rights in
                  the Licensed Product in any form to any other party in whole or in part, including without limitation
                  to provide processing services to their parties for commercial timesharing or for rental or sharing
                  arrangements, (c) modify, adapt, decompile, disassemble, reverse engineer, create derivative works or
                  otherwise attempt to derive source code from the Licensed Product (or hosting environment, if
                  applicable) in whole or in part, (d) or remove, modify, obscure and/or otherwise deface any copyright,
                  trademark or other proprietary rights notices in the Licensed Product or Documentation.
                </li>
                <li>
                  Unless otherwise agreed in writing between you and RWS, this agreement includes, at a minimum, the
                  terms and conditions set out in this document and Terms and Conditions for the use of any RWS
                  websites, and where noticed via writing or as the context indicates, may include third party websites.
                </li>
                <li>
                  Where additional terms and conditions apply to a Service, these will be accessible for you to read
                  either within, or through your use of, that Service. For the avoidance of doubt, this may include
                  applicable third party terms and conditions for access and/or use of such third party product and/or
                  service.&nbsp;
                </li>
                <li>
                  Taken together, these Terms and Conditions for the Use of any of the RWS websites, the Terms and
                  Conditions, and any additional terms and conditions that apply to a Service are called the
                  &ldquo;Terms&rdquo;. In the event that there is a conflict between these, they will prevail in that
                  order.&nbsp;
                </li>
                <li>
                  Sometimes a member of the group of companies of which RWS is the parent (an &ldquo;RWS Company&rdquo;)
                  may provide Services to you on behalf of RWS, and you accept that they may do so under the same Terms.
                  You accept that each RWS Company shall be third party beneficiary to the Terms and that they shall be
                  entitled to directly enforce, and rely upon, any provision of the Terms which confers a benefit on
                  them. Other than this, no other person or company shall be third party beneficiaries to the Terms.
                </li>
                <li>
                  RWS may make changes to these Terms from time-to-time by mail or e-mail or by posting replacements or
                  changes to the site at which this document is located, and you accept that if you use the Services
                  after the date on which the Terms have been replaced or changed RWS will treat your use as acceptance
                  of the updated Terms. &nbsp;
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Accepting these Terms</strong>
              <ol>
                <li>
                  In order to use the Services, you must accept the Terms. You may not use the Services if you do not
                  accept the Terms.
                </li>
                <li>
                  By clicking to accept or to agree to the Terms (where this option is made available to you by RWS in
                  the interface for a Service) or by actually using the Services you accept the Terms, and you
                  understand and agree that RWS will treat your use of the Services as acceptance of the Terms from the
                  point of clicking or of use. &nbsp;
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Your Use of the Services</strong>
              <ol>
                <li>
                  You agree to use the Services only for purposes that are permitted by the Terms and as permitted by
                  applicable law, regulation and generally accepted practice or guideline in the relevant jurisdictions
                  (including any laws regarding the export of data and/or software).&nbsp;
                </li>
                <li>
                  You agree not to reproduce, duplicate, copy, sell, trade or resell the Services for any purpose or to
                  access (or to attempt to access) any of the Services by any means other than through the interface
                  that is provided by RWS, unless you have been specifically allowed to do so in a separate written
                  agreement with RWS. You specifically agree not to access (or to attempt to access) any of the Services
                  through any automated means, including use of scripts or web crawlers.&nbsp;
                </li>
                <li>
                  You agree that you are responsible for maintaining the confidentiality of any password associated with
                  any account that you use to access the Services and you agree that you will be solely responsible to
                  RWS for all activities that occur under your account.
                </li>
                <li>
                  Copyright and other intellectual property laws protect the Services provided to you, and you agree to
                  abide by and maintain all notices, license information, and restrictions contained therein.
                </li>
                <li>You shall only work within the bounds of the available APIs, and accompanying SDK.</li>
                <li>
                  You may not decompile, reverse engineer, dissemble, attempt to derive the source code of any software
                  or security components of the Services (except as and only to the extent any foregoing restriction is
                  prohibited by applicable law or to the extent as may be permitted by any licensing terms accompanying
                  the foregoing).
                </li>
                <li>
                  You may not use the Services to violate, tamper with, or circumvent the security of any computer
                  network, software, passwords, encryption codes, technological protection measures, or to otherwise
                  engage in any kind of illegal activity, or to enable others to do so.
                </li>
                <li>
                  You may not use the Services in any manner that could damage, disable, overburden, or impair any RWS
                  service, or the network(s) connected to any RWS service, or interfere with any other party&rsquo;s use
                  and enjoyment of any Services.
                </li>
                <li>
                  We may make changes to the Services from time to time, including, but not limited to: the availability
                  of features; how long, how much or how often any given feature may be used; and feature dependencies
                  upon other services or software.
                </li>
                <li>
                  We may make features available on a Beta basis in SDL&rsquo;s sole discretion. Betas are provided
                  &ldquo;AS-IS&rdquo; for evaluation and testing purposes only. Beta evaluations are conducted on a
                  restricted and limited basis and are not intended for public distribution and any production usage
                  (any non-evaluation and non-testing usage) is strictly prohibited without prior express written
                  authorization from SDL. Betas may be subject to reduced, different, separate and/or distinct security,
                  compliance, privacy, availability, reliability, and support commitments, as further explained in the
                  documentation provided at the time. We may change or discontinue Betas at any time without notice. SDL
                  has no obligation to release a Beta into &ldquo;Production&rdquo; and/or any commercial and/or other
                  distribution model. &nbsp;Any decisions regarding Betas, applicable product roadmaps and related Beta
                  matters are made by SDL and are at its sole discretion, and such decisions, Betas, roadmaps and
                  matters are confidential to SDL.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Grant of Developer Licences</strong>
              <ol>
                <li>
                  Registered developers are entitled to a developer licence to support working with a Licenced Product
                  to support development work using the SDK under the following conditions;
                  <ol>
                    <li>
                      Developers of free applications that are provided to RWS end users through RWS websites are
                      entitled to free, limited, revocable licences that will be renewed annually subject to continued
                      compliance with these terms and conditions and at the sole discretion of SDL.
                    </li>
                    <li>
                      Developers of paid applications that are provided to RWS end users through RWS websites may be
                      entitled to free, limited, revocable licences that will be renewed annually subject to continued
                      compliance with these terms and conditions and at the sole discretion of SDL.
                    </li>
                    <li>
                      Developers of applications that are not made available through the RWS websites may be entitled to
                      free, limited, revocable licences that will be renewed annually subject to continued compliance
                      with these terms and conditions and at the sole discretion of SDL.
                    </li>
                    <li>
                      Requests for a developer licence should be made by emailing{" "}
                      <a href="mailto:trados-developers@rws.com">trados-developers@rws.com</a>
                    </li>
                    <li>
                      For the avoidance of doubt, SDL may cancel, revoke and/or terminate any licenses provided by it
                      hereunder at any time, for any reason or none. &nbsp;
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Application Programming Interface and Software Development Kit</strong>
              <ol>
                <li>
                  As a registered developer working with RWS products, you may have access to a software development kit
                  (an &quot;SDK&quot;) designed to enable programmatic interface with one or more RWS software products
                  (&quot;RWS Software&quot;) and which may comprise, without limitation, sample code and documentation.
                  Subject to these terms and conditions, RWS grants you a temporary, nonexclusive, non-transferable,
                  non-sublicensable license to access and use the SDK for the limited purpose of developing, through the
                  use, as documented in the SDK, of the application programming interface (the &quot;API&quot;) supplied
                  with the RWS Software, one or more applications (each an &quot;App&quot;) whose functionality requires
                  its end-user to have access to and use of the RWS Software.
                </li>
                <li>
                  The App must not be designed to and shall under no circumstance allow any user of RWS Software to
                  bypass any license key protection of the RWS Software, allow use by non-licensees or use by more users
                  than are licensed to use the RWS Software or otherwise circumvent any limitations on the installation
                  or use of the RWS Software or support the breach of any limitations given in such software&apos;s
                  end-user licensing agreement (its &quot;EULA&quot;) and/or, if applicable, any third party terms and
                  conditions appliable to such software.
                </li>
                <li>
                  Furthermore the App must not be designed to and shall under no circumstance violate any third party
                  intellectual property rights and/or any applicable third party licensing restrictions. You agree to
                  indemnify and hold RWS harmless against any losses that RWS may suffer arising from any third party
                  claims related to such a violation by you.
                </li>
                <li>
                  You must be in possession of a valid license to use an instance of the RWS Software at all times
                  whilst developing a App.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Distribution and Publishing of Apps</strong>
              <ol>
                <li>
                  RWS may, in its sole discretion, test, validate and/or make Apps available for download by
                  third-parties from an RWS website (appstore.rws.com known as the &ldquo;RWS AppStore&rdquo;).
                </li>
                <li>
                  Subject to this Agreement, Apps may be provided through the RWS AppStore as apps made freely available
                  to end users, or as paid apps based on a commercial arrangement between you and the end user.
                </li>
                <li>
                  The process for submitting, reviewing and publishing apps to the RWS AppStore is published in the RWS
                  Developer Community &ldquo;Submitting, Reviewing and Publishing of Apps&rdquo; wiki.
                </li>
                <li>
                  For paid apps the mechanism for taking payments is not provided by RWS. Registered developers are
                  responsible for implementing their own payment mechanisms and RWS accept no responsibility for payment
                  disputes between the registered developer and the end user.
                </li>
                <li>
                  Apps that interfere with, modify, disrupt or disable features or functionality of RWS websites,
                  including without limitation any such mechanism used to restrict or control the functionality, or
                  defeat, avoid, bypass, remove, deactivate or otherwise circumvent any software protection or
                  monitoring mechanisms of our Services are prohibited and will be disabled.
                </li>
                <li>
                  Apps that are no longer fit for purpose and are not updated in a timely fashion will be disabled.
                </li>
                <li>
                  RWS reserve the right, in its sole discretion, to remove any app from the RWS AppStore without
                  discussion.
                </li>
                <li>
                  While you may distribute Apps to licensed users of RWS Software in accordance with this Agreement, you
                  may not, without the prior written permission of RWS, (1) represent that any RWS company endorses the
                  App, (2) make any representations or warranties regarding SDL products or services (unless authorized
                  by SDL via separate agreement or terms and conditions), (3) attempt to or commence actions to legally
                  or equitably bind any RWS company, or (4) offer (or allow another to offer) Apps for distribution
                  through any site which competes or purports to compete with the RWS AppStore or which RWS instructs
                  you not to use for such distribution, which instruction RWS shall not make unreasonably.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>External Links and Third-Party Content</strong>
              <ol>
                <li>
                  Sometimes through your use of the Services you may use a service or purchase goods or access
                  information (such as data files, written text, computer software or audio files) which are provided by
                  another person or company (&quot;Third-Party Content&quot;), and the Services may include hyperlinks
                  to such Third-Party Content.
                </li>
                <li>
                  You accept that Third-Party Content and any third party website is the sole responsibility of the
                  company or person from whom it originated, that RWS has no actual control over such Third-Party
                  Content and/or website(s) and does not endorse such Third-Party Content and/or websites. You
                  acknowledge and agree that RWS is not liable for any loss or damage which may be incurred by you as a
                  result of the availability or non-availability of Third-Party Content and/or website(s), or as a
                  result of any reliance placed by you on the completeness, accuracy or existence of Third-party Content
                  and/or website(s). You understand that by using the Services you may be exposed to Third-Party Content
                  and/or websites that you may find offensive, indecent or objectionable and you accept to use the
                  Services at your own risk. &nbsp;
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>RWS Community Support and Technical Support</strong>
              <ol>
                <li>
                  As a registered developer, you have access to the RWS developer community, which may offer
                  discretionary support from both developers and RWS depending on application.
                </li>
                <li>
                  As a registered developer you may also be granted access to Technical Support for the Licensed
                  Products covered by additional terms and conditions from the Technical Support teams.
                </li>
                <li>
                  Certain applications you access from the RWS websites may have been developed by RWS. RWS may, but is
                  under no obligation to, provide discretionary support for such applications, available from time to
                  time, either free-of-charge or for a separate fee. You agree that all use of such services will be in
                  accordance with RWS&rsquo;s usage policies for such services, which are subject to change from time to
                  time, with or without prior notice to you.
                </li>
                <li>
                  You agree that RWS shall not be liable to you or any third party for any medication or cessation of
                  such services.
                </li>
                <li>
                  You agree that when requesting and receiving Community support and technical support you will not
                  provide RWS with any information, including that incorporated into your applications, that is
                  confidential to you or any third party.
                </li>
                <li>
                  You agree that any notice, legend, or label to the contrary contained in any such materials provided
                  by you to RWS shall be without effect. RWS shall be free to use all information it receives from you
                  in any manner it deems appropriate, subject to any applicable patents or copyrights.
                </li>
                <li>
                  RWS reserves the right to reject a request for community support and technical support at any time and
                  for any reason.
                </li>
                <li>
                  You shall be solely responsible for any restoration of lost or altered files, data, programs or other
                  materials provided.
                </li>
                <li>
                  Third Party Developers: Certain applications you access from the RWS websites, or the website itself,
                  may have been developed by third party developers. RWS is not responsible for any content, software or
                  functionality of such third party applications and/or websites. Any access and/or use of such third
                  party applications and/or websites shall be subject to the applicable third party terms and conditions
                  for such applications and/or websites. SDL shall have no responsibility to provide services and/or
                  support for such third party applications and/or websites. Such support is the sole responsibility of
                  the applicable Developer, and RWS disclaims any and all liability related to your use of such
                  applications and support.
                </li>
                <li>
                  As a registered developer, you further agree that any support or other services related to your
                  applications that you provide as a result of or in conjunction with the Services shall be entirely
                  your responsibility, and RWS disclaims any and all liability related to such support and services.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Independent Development</strong>
              <ol>
                <li>
                  Nothing in this Agreement will impair RWS&apos;s right to develop, acquire, license, market, promote
                  or distribute products, software or technologies that perform the same or similar functions as, or
                  otherwise compete with, any other products, software or technologies that you may develop, produce,
                  market, or distribute.&nbsp;
                </li>
                <li>
                  In the absence of a separate written agreement to the contrary between you and RWS, RWS will be free
                  to use any information, suggestions or recommendations you provide to RWS pursuant to this Agreement
                  for any purpose, subject to any applicable existing patents or copyrights, and shall own all right and
                  title to any such information, suggestions and/or recommendations and to any improvements upon the
                  same.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Confidentiality</strong>
              <ol>
                <li>
                  &ldquo;Confidential Information&rdquo; means any information, in whatever form or medium, of RWS or
                  its affiliates furnished or otherwise made available to you in connection with your registration with
                  the RWS websites (including, without limitation, any the SDK and any information disclosed in relation
                  to the SDK, as well as all processes, procedures, formulas, techniques, software programs and
                  documentations, research, services, data files, technical data or specifications) together with all
                  analyses, compilations, reports, memoranda, notes and other written or electronic materials which
                  contain, reflect or are based, in whole or in part, upon such information either directly or
                  indirectly in written, oral, or electronic form. For purposes of this Agreement, with respect to RWS,
                  &ldquo;affiliate&rdquo; means any entity that is directly or indirectly controlled by RWS.&nbsp;
                </li>
                <li>
                  During and after the term of this Agreement, you agree that you will: (a) hold in strict confidence
                  and not disclose any Confidential Information to any third party except as expressly authorized by RWS
                  in writing, (b) use any Confidential Information only to the extent necessary in connection with the
                  authorized purposes for which they were disclosed to you and not otherwise for your own or any third
                  party&rsquo;s gain or benefit, (c) restrict access to Confidential Information to those employees,
                  representatives or agents of you or your employer who have a &quot;need to know&quot; in connection
                  with the authorized purpose for which it was disclosed to you; and (d) not reverse engineer, decompile
                  or disassemble any software disclosed to you by RWS, except as expressly permitted by and for the
                  purposes of this Agreement.&nbsp;
                </li>
                <li>
                  If you are required to disclose Confidential Information pursuant to any applicable law, regulation,
                  court order or document discovery request, then you agree to give RWS prompt written notice of such
                  requirement.&nbsp;
                </li>
                <li>
                  You shall not make any copies of the Confidential Information unless it is deemed necessary for the
                  authorized purpose for which it was disclosed to you. All copies shall be designated as
                  &ldquo;proprietary&rdquo; or &ldquo;confidential&rdquo;. You shall reproduce RWS&apos;s proprietary
                  rights notices on any such approved copies, in the same manner in which such notices were set forth in
                  or on the original.&nbsp;
                </li>
                <li>
                  You acknowledge and agree that any unauthorized disclosure or other violation, or threatened violation
                  of this Agreement by you may cause irreparable damage to RWS. Accordingly, RWS will be entitled to
                  seek an injunction prohibiting you from any such disclosure, attempted disclosure, violation or
                  threatened violation without the necessity of proving damages or furnishing a bond or other security,
                  in addition to any other available remedies.
                </li>
                <li>
                  In agreeing to these terms and conditions you are also acknowledging agreement to the security,
                  privacy, and customer data conditions at https://www.rws.com/legal/privacy/
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Exclusion of Warranties and Limitation of Liabilities</strong>
              <ol>
                <li>
                  THE SOFTWARE AND/OR SERVICES BUILT USING THE SDK AND PROVIDED UNDER THE RWS WEBSITES IS PROVIDED ON AN
                  AS IS BASIS. RWS DOES NOT ENDORSE THE SOFTWARE AND/OR SERVICES AVAILABLE ON THESE SITES. ALL
                  WARRANTIES, CONDITIONS OR OTHER TERMS CONCERNING THE SOFTWARE AND/OR SERVICES, WHETHER EXPRESS OR
                  IMPLIED BY STATUTE, COMMON LAW OR OTHERWISE (INCLUDING THOSE RELATING TO SATISFACTORY QUALITY,
                  NON-INFRINGEMENT AND FITNESS FOR PURPOSES) ARE EXCLUDED.
                </li>
                <li>
                  IN NO EVENT WILL RWS (OR ANY OF ITS AFFILIATES) BE LIABLE FOR ANY DAMAGES, IN CONTRACT, TORT
                  (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), OR OTHERWISE FOR LOSS OF DATA, LOST PROFITS, COSTS
                  OF COVER OR OTHER SPECIAL, INCIDENTAL, CONSEQUENTIAL, ACTUAL, GENERAL, OR INDIRECT DAMAGES ARISING
                  FROM THE USE OF THE SOFTWARE AND/OR SERVICES, HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY THIS
                  LIMITATION WILL APPLY EVEN IF RWS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE PARTIES
                  ACKNOWLEDGE THAT THIS IS A REASONABLE ALLOCATION OF RISK. LICENSOR&apos;S TOTAL AGGREGATE LIABILITY
                  UNDER THIS AGREEMENT SHALL IN NO EVENT EXCEED THE AMOUNT PAID DURING THE PRECEDING TWELVE (12) MONTHS
                  FOR THE APPLICABLE SOFTWARE OR SERVICE.
                </li>
                <li>
                  NOTHING IN THIS AGREEMENT SHALL EXCLUDE OR LIMIT EITHER PARTY&apos;S LIABILITY TO THE OTHER FOR (I)
                  DEATH OR PERSONAL INJURY CAUSED BY ITS NEGLIGENCE OR (II) ANY OTHER LIABILITY TO THE EXTENT THAT IT
                  CANNOT BE EXCLUDED OR LIMITED AS A MATIER OF LAW.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Termination</strong>
              <ol>
                <li>
                  RWS may terminate its agreement with you at any time for any reason including, without limitation:
                  <ol className="decimal_type">
                    <li>
                      you have breached any provision of the Terms (or have acted in manner which shows that you are
                      unable to or do not intend to comply with the provisions of the Terms);&nbsp;
                    </li>
                    <li>
                      RWS is required to do so by law (for example, where the provision of the Services to you is, or
                      becomes, unlawful); or&nbsp;
                    </li>
                    <li>
                      RWS intends to withdraw provision of the Services from users of a profile to which you belong, or
                      for no reason.
                    </li>
                    <li>
                      Sections 13 (Exclusion of Warranties and Limitation of Liabilities) and 16 (Law and Jurisdiction)
                      and any other provisions which are expressly stated to or by their nature must survive termination
                      of the agreement between you and SDL shall survive. &nbsp;
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Amendment; Communication.</strong>
              <ol>
                <li>
                  RWS reserves the right, at its sole discretion, to modify the Terms, including any rules and policies
                  at any time. You will be responsible for reviewing and becoming familiar with any such modifications
                  (including new terms, updates, revisions, supplements, modifications, and additional rules, policies,
                  terms and conditions) (&quot;Additional Terms&quot;) communicated to you by RWS, which communication
                  may include but is not limited to updates to the applicable RWS Website(s) terms and conditions.
                </li>
                <li>
                  All Additional Terms are hereby incorporated into this agreement by this reference and your continued
                  use of the RWS websites will indicate your acceptance of any Additional Terms. In addition, RWS may
                  send communications to you from time to time including, but not be limited to, marketing materials,
                  technical information, and updates and/or changes regarding your participation as a registered
                  developer. By agreeing to these Terms, you consent that RWS may provide you with such communications.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Law and Jurisdiction</strong>
              <ol>
                <li>
                  Except as detailed below, this Agreement will be governed by and construed in accordance with the laws
                  of England and Wales, excluding that body of laws known as conflicts of law. Any legal action or
                  proceeding arising under this Agreement will be brought exclusively in the English Courts and the
                  parties hereby irrevocably consent to the personal jurisdiction and venue therein.
                </li>
                <li>
                  If your principal place of business is within the European Union, this Agreement will be governed by
                  and construed in accordance with the laws of the Netherlands.
                </li>
                <li>
                  If your principal place of business is the United States, then this Agreement will be governed by and
                  construed in accordance to the laws of the Commonwealth of Massachusetts.
                </li>
                <li>
                  If your principal place of business is within China, this Agreement will be governed by and construed
                  in accordance with the laws of the Republic of China.
                </li>
                <li>
                  If your principal place of business is within Japan, then this Agreement will be governed by and
                  construed in accordance to the laws of Japan.&nbsp;
                </li>
                <li>
                  The parties expressly agree that the United Nations Convention on Contracts for the International Sale
                  of Goods will not apply.
                </li>
              </ol>
            </li>

            {/* -------------------------------------------------------- */}

            <li>
              <strong>Miscellaneous</strong>
              <ol>
                <li>
                  <u>Entire Agreement.</u> This Agreement constitutes the entire and exclusive understanding and
                  agreement between us with respect to the subject matter hereof and supersedes all prior and/or
                  contemporaneous agreements and understandings, written or oral, between us with respect to the subject
                  matter hereof.&nbsp;
                </li>
                <li>
                  <u>Severability.</u> If for any reason a court of competent jurisdiction finds any provision of these
                  terms invalid or unenforceable, that provision of the terms will be enforced to the maximum extent
                  permissible and the other provisions of these terms will remain in full force and effect.
                </li>
                <li>
                  <u>Waiver.</u> The failure by either party to enforce any provision of these terms will not constitute
                  a waiver of future enforcement of that or any other provision.
                </li>
                <li>
                  <u>Third Party Beneficiaries.</u> Except where expressly provided to the contrary, these terms are not
                  intended to be for the benefit of, and shall not be enforceable by any person who is not named at the
                  date of these terms as a party to it or any person who claims rights under the Contracts (Rights of
                  Third Parties) Act 1999 or otherwise, and neither party can declare itself a trustee for the benefit
                  of a third party.
                </li>
                <li>
                  <u>Force Majeure.</u> Except for any payment obligations hereunder, neither party will be responsible
                  for delays or failures of performance under these terms due to circumstances beyond its reasonable
                  control, including without limitation, as caused by acts of God, war, epidemic, pandemic, terrorism,
                  civil disturbance, court order, fire, flood, extreme weather conditions, strikes, or other labour
                  disturbances (&ldquo;Force Majeure&rdquo;).
                </li>
                <li>
                  <u>Language.</u> If this Agreement is translated into a language other than English, the English
                  language version shall control.
                </li>
              </ol>
            </li>
          </ol>

          {/* -------------------------------------------------------- */}

          <p>
            <strong>December 2022</strong>
          </p>
          <p>&copy; 2022 RWS Holdings Plc. All Rights Reserved.&nbsp;</p>
          <p>Europa House, Chiltern Park, Chiltern Hill, Chalfont St Peter, Gerrards Cross SL9 9FG</p>
          <p>
            All intellectual property rights contained herein are the sole and exclusive rights of RWS. All references
            to RWS shall mean RWS Holdings Plc and its subsidiaries and affiliates details of which can be obtained upon
            written request.
          </p>
          <p>
            All rights reserved. Unless explicitly stated otherwise, all intellectual property rights including those in
            copyright in the content of this website and documentation are owned by or controlled for these purposes by
            RWS. Except as otherwise expressly permitted hereunder or in accordance with copyright legislation, the
            content of this site, and/or the documentation may not be copied, reproduced, republished, downloaded,
            posted, broadcast or transmitted in any way without the express written permission of RWS.
          </p>
          <hr />
          <Formik
            initialValues={initialValues}
            onSubmit={(value) => handleSubmit(value)}
            validationSchema={accountAgreementValidationSchema(translate)}
            enableReinitialize
          >
            {({ values, touched, errors, handleChange }) => (
              <FormikForm>
                <div className="d-flex flex-column">
                  <Checkbox
                    name="isAgreementAccepted"
                    label="Accept RWS Developer Partner Terms and Conditions"
                    checked={values.isAgreementAccepted}
                    onChange={handleChange}
                  />
                  {touched.isAgreementAccepted && !!errors.isAgreementAccepted && (
                    <span className="invalid-feedback d-inline">{errors.isAgreementAccepted}</span>
                  )}
                  <Button style={buttonStyle} type="submit" className="mt-2">
                    Accept agreement
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};
