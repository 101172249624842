import { Button, Spinner } from "react-bootstrap";
import { useTheme } from "../../contexts/ThemeContext";
import { ExtendedAccount } from "../identity/models/ExtendedAccount";
import { useDownload } from "../../hooks/useDownload";
import { useLocalization } from "../../contexts/LocalizationContext";
import { apiRoutes } from "../../apiRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { ExtendedUser } from "../identity/models/ExtendedUser";

type ExportButtonProps = { accounts: ExtendedAccount[] };

export const ExportButton = ({ accounts }: ExportButtonProps) => {
  const { buttonStyle } = useTheme();
  const { isLoading, handleDownload } = useDownload();
  const data = JSON.stringify({ accountIds: accounts.map((x) => x.id) });
  const { translate } = useLocalization();
  const { user } = useAuth0<ExtendedUser>();
  const userId = user?.user_metadata?.id as string;

  const onExport = () => {
    handleDownload(apiRoutes.exports.exportAccountsRoute(userId), data, "POST", "application/json");
  };

  return (
    <Button disabled={isLoading} onClick={onExport} style={{ ...buttonStyle, width: 150 }}>
      {isLoading ? <Spinner size="sm" /> : translate("settings.exportToExcel")}
    </Button>
  );
};
