export class DisabledFields {
  name?: boolean;
  developerName?: boolean;
  categories?: boolean;
  parentProductDetailsId?: boolean;
  sharedParentProductIds?: boolean;
  versionNumber?: boolean;
  minimumStudioVersion?: boolean;
  maximumStudioVersion?: boolean;
  supportedProducts?: boolean;
  publishedAt?: boolean;

  constructor(hasLockedFields: boolean) {
    this.name = hasLockedFields;
    this.developerName = hasLockedFields;
    this.categories = hasLockedFields;
    this.parentProductDetailsId = hasLockedFields;
    this.sharedParentProductIds = hasLockedFields;
    this.versionNumber = hasLockedFields;
    this.minimumStudioVersion = hasLockedFields;
    this.maximumStudioVersion = hasLockedFields;
    this.supportedProducts = hasLockedFields;
    this.publishedAt = hasLockedFields;
  }
}
