import { useAuth0 } from "@auth0/auth0-react";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { ExtendedUser } from "../identity/models/ExtendedUser";
import { Comment } from "./models/Comment";
import { EditComment } from "./EditComment";
import { commentValidationSchema } from "./schemas/CommentValidationSchema";
import { useComments } from "./hooks/useComments";
import { Loader } from "../common/progress/Loader";
import { useTheme } from "../../contexts/ThemeContext";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";
import { dateFormat } from "../../utils";
import { CommentUpdate } from "./models/CommentUpdate";

type CommentsProps = { comments: Comment[] };

export const Comments = ({ comments }: CommentsProps) => {
  const { isLoading, editModeId, setEditModeId, handleDelete } = useComments(comments);
  const { user } = useAuth0<ExtendedUser>();
  const { buttonStyle } = useTheme();
  const { safeExecute } = usePreserve();
  const editableItem = comments?.find((x) => x.id === editModeId);
  const userId = user?.user_metadata?.id;
  const { translate } = useLocalization();

  const initialValues: CommentUpdate = {
    id: editModeId,
    description: editableItem?.description ?? "<p><br></p>"
  };

  if (isLoading) return <Loader />;

  return (
    <Formik<CommentUpdate>
      initialValues={initialValues}
      validationSchema={commentValidationSchema(translate)}
      onSubmit={() => undefined}
      enableReinitialize
    >
      <Form>
        {!comments.length && <div className="py-3">{translate("plugins.hasNoComments")}</div>}
        {comments?.map((x) =>
          x.id === editModeId ? (
            <EditComment key={x.id} handleDiscard={() => safeExecute(() => setEditModeId(null))} comments={comments} />
          ) : (
            <div className="p-3 border-bottom border-top" key={x.id}>
              <div className="d-flex">
                <FontAwesomeIcon icon={faUserAlt} className="me-2 align-self-center" />
                <p className="m-0">{`${x.author} - ${dateFormat(translate, x.createdAt)}`}</p>
              </div>
              <div className="py-3" dangerouslySetInnerHTML={{ __html: x.description }} />
              {userId === x.userProfileId && (
                <div className="d-flex">
                  <Button
                    variant="transparent"
                    className="m-0 me-2 text-secondary p-0"
                    onClick={() => safeExecute(() => setEditModeId(x.id))}
                  >
                    {translate("common.edit")}
                  </Button>
                  <Button
                    variant="transparent"
                    className="m-0 me-2 text-secondary p-0"
                    onClick={() => handleDelete(x.id)}
                  >
                    {translate("common.delete")}
                  </Button>
                </div>
              )}
            </div>
          )
        )}
        {editModeId === undefined && (
          <EditComment handleDiscard={() => safeExecute(() => setEditModeId(null))} comments={comments} />
        )}
        {editModeId === null && (
          <Button
            variant="success"
            className="mt-2"
            style={buttonStyle}
            onClick={() => safeExecute(() => setEditModeId(undefined))}
          >
            {translate("plugins.addComment")}
          </Button>
        )}
      </Form>
    </Formik>
  );
};
