import { faPenAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNameMappings } from "./contexts/NameMappingsContext";
import { NameMapping } from "./models/NameMapping";
import { useResize } from "../../hooks/useResize";
import { usePreserve } from "../../contexts/PreserveContext";
import { useLocalization } from "../../contexts/LocalizationContext";

type NameMappingsTableReadyonlyRowProps = {
  item: NameMapping;
  index: number;
  onDelete: () => void;
};

export const NameMappingsTableReadyonlyRow = ({ item, index, onDelete }: NameMappingsTableReadyonlyRowProps) => {
  const { onEditModeIdChange } = useNameMappings();
  const { isExpanded, onToggle } = useResize();
  const { safeExecute } = usePreserve();
  const { translate } = useLocalization();

  return (
    <tr className="d-flex flex-column d-xl-table-row text-center text-xl-start align-middle">
      <td className="d-none d-xl-table-cell">{index}</td>
      <td className="border fw-bold fw-xl-normal" onClick={onToggle}>
        {item.oldName}
      </td>
      {isExpanded && (
        <>
          <td className="border">{item.newName}</td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.edit")}</Tooltip>}>
              <Button
                variant="transparent"
                className="p-0"
                aria-label={translate("common.edit")}
                onClick={() => safeExecute(() => onEditModeIdChange(item.id))}
              >
                <FontAwesomeIcon icon={faPenAlt} className="text-secondary" />
              </Button>
            </OverlayTrigger>
          </td>
          <td className="border text-center">
            <OverlayTrigger overlay={<Tooltip>{translate("common.delete")}</Tooltip>}>
              <Button variant="transparent" className="p-0" aria-label={translate("common.delete")} onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
              </Button>
            </OverlayTrigger>
          </td>
        </>
      )}
    </tr>
  );
};
