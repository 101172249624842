import { Formik } from "formik";
import { Container } from "react-bootstrap";
import { Loader } from "../common/progress/Loader";
import { parentProductValidationSchema } from "./schemas/ParentProductValidationSchema";
import { ParentProductsTable } from "./ParentProductsTable";
import { ParentProductDetails } from "./models/ParentProductDetails";
import { useParentProducts } from "./contexts/ParentProductsContext";
import { useLocalization } from "../../contexts/LocalizationContext";

export const ParentProducts = () => {
  const { parentProducts, editModeId, isLoading } = useParentProducts();
  const { translate } = useLocalization();

  if (isLoading) return <Loader />;

  const editableItem = parentProducts.find((item) => item.id === editModeId);
  const initialValues = {
    id: editableItem?.id ?? 0,
    productName: editableItem?.productName ?? "",
    supportedFileExtensionsIds: editableItem?.supportedFileExtensionsIds ?? []
  };

  return (
    <Container className="p-0 mb-2">
      <Formik<ParentProductDetails>
        key={editModeId}
        initialValues={initialValues}
        validationSchema={parentProductValidationSchema(translate)}
        onSubmit={() => undefined}
        enableReinitialize
      >
        <ParentProductsTable />
      </Formik>
    </Container>
  );
};
